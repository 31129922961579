import React from "react";
import AuthFeeds from "./AuthFeeds";
import AuthMainFeeds from "./post-comp/main-posts/AuthMainFeeds";
import RightSidePosts from "./post-comp/side-posts/RightSidePosts";
import SidePosts from "./post-comp/side-posts/SidePosts";

function NewAuthFeeds() {
  return (
    <div className="new__post__feeds__container">
      <SidePosts />
      <AuthMainFeeds />
      <RightSidePosts />
    </div>
  );
}

export default NewAuthFeeds;
