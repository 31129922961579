// src/ResetPassword.js
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Api } from "../../axios";
import Alerts from "../../components/alerts/Alerts";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import LoadingComponent from "../../components/loading/LoadingComponent";
import "./ForgetPassword.css";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState("Influencer");

  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState(null);

  const influencerEndpoint = "/influencer/reset-password";
  const followerEndpoint = "/follower/reset-password";
  const businessEndpoint = "/business/reset-password";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");

  const handleRoleChange = (role) => {
    setSelectedRole(role);
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    const payload = {
      email: email,
      password: password,
      password_confirmation: password,
      token: token,
    };
    let res;
    try {
      if (selectedRole == "Influencer") {
        res = await Api.post(influencerEndpoint, payload);
      }

      if (selectedRole == "Business") {
        res = await Api.post(businessEndpoint, payload);
        //console.log(res.data);
      }

      if (selectedRole == "Follower") {
        res = await Api.post(followerEndpoint, payload);
        //console.log(res.data);
      }

      if (res.data) {
        setLoading(false);
        setAlerts({ message: "pssword changed successfully", type: "success" });
        navigate("/login");
      }
    } catch (error) {
      //console.log(error);
      setLoading(false);
      setAlerts({
        message: error?.message || "error",
        type: "error",
      });
    }
  };

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="auth container">
          {alerts && <Alerts message={alerts.message} type={alerts?.type} />}

          <div className="forms-container">
            <div className="signin-signup">
              <form action="#" className="sign-in-form auth__form reset">
                <h2 className="title">Reset your password</h2>
                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <input
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <input
                    type="text"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <input type="text" placeholder="Confirm password" />
                </div>

                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <input
                    type="text"
                    placeholder="Enter token"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                  />
                </div>

                <button
                  type="button"
                  className="btn solid"
                  onClick={() => setShowModal(true)}
                >
                  Choose Role
                </button>

                <h3>Selected Role: {selectedRole}</h3>

                <input
                  type="submit"
                  value="Submit"
                  className="btn solid"
                  onClick={handleSubmit}
                />
              </form>
            </div>
          </div>

          <div className="panels-container">
            <div className="panel left-panel">
              <div className="content">
                <h3>Reset your password ?</h3>
                <p>Request for a password reset.</p>
              </div>
              {/* <img src="img/log.svg" className="image" alt="" /> */}
            </div>
          </div>

          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <button onClick={() => handleRoleChange("Influencer")}>
                  Influencer
                </button>
                <button onClick={() => handleRoleChange("Follower")}>
                  Follower
                </button>
                <button onClick={() => handleRoleChange("Business")}>
                  Business
                </button>
                <button onClick={() => setShowModal(false)}>Close</button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ResetPassword;
