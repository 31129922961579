import React from "react";
import "./Loading.css";

const LoadingComponent = ({ progress }) => {
  return (
    <div className="loading-container">
      <div className="cube-container">
        <div className="cube">
          <div className="face face1"></div>
          <div className="face face2"></div>
          <div className="face face3"></div>
          <div className="face face4"></div>
          <div className="face face5"></div>
          <div className="face face6"></div>
        </div>
      </div>
      <p>Topnotch Influencers...</p>
      {progress !== undefined && (
        <p className="progress-text">Uploading: {progress}%</p>
      )}
    </div>
  );
};

export default LoadingComponent;
