// src/ResetPassword.js
import React, { useState } from "react";
import Alerts from "../../components/alerts/Alerts";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import "./ForgetPassword.css";
import axiosInstance from "../../axiosInstance";
import { Api } from "../../axios";
import { Navigate, useNavigate } from "react-router";
import LoadingComponent from "../../components/loading/LoadingComponent";

const ForgetPassword = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState("Influencer");

  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState(null);

  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const influencerEndpoint = "/influencer/forgot-password";
  const followerEndpoint = "/follower/forgot-password";
  const businessEndpoint = "/business/forgot-password";

  const handleRoleChange = (role) => {
    setSelectedRole(role);
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    const payload = { email: email };
    let res;
    try {
      if (selectedRole == "Influencer") {
        res = await Api.post(influencerEndpoint, payload);
      }

      if (selectedRole == "Business") {
        res = await Api.post(businessEndpoint, payload);
        //console.log(res.data);
      }

      if (selectedRole == "Follower") {
        res = await Api.post(followerEndpoint, payload);
        //console.log(res.data);
      }

      if (res.data) {
        setLoading(false);
        setAlerts({
          message: "Reset token sent to provided email",
          type: "success",
        });

        setTimeout(() => {
          navigate("/reset-password");
        }, 3000); // 5000 milliseconds = 5 seconds
      }
    } catch (error) {
      //console.log(error);
      setLoading(false);
      setAlerts({
        message: error?.message || "error",
        type: "error",
      });
    }
  };

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="auth container">
          {alerts && <Alerts message={alerts.message} type={alerts?.type} />}

          <div className="forms-container">
            <div className="signin-signup">
              <form action="#" className="sign-in-form auth__form reset">
                <h2 className="title">Enter your email</h2>
                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <input
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <button
                  type="button"
                  className="btn solid"
                  onClick={() => setShowModal(true)}
                >
                  Choose Role
                </button>

                <h3>Selected Role: {selectedRole}</h3>

                <input
                  type="submit"
                  value="Submit"
                  className="btn solid"
                  onClick={handleSubmit}
                />
              </form>
            </div>
          </div>

          <div className="panels-container">
            <div className="panel left-panel">
              <div className="content">
                <h3>Forgot your password?</h3>
                <p>
                  No worries! It happens to the best of us. Enter your email
                  address associated with your account, and we'll send you
                  instructions to reset your password.
                </p>
              </div>
              {/* <img src="img/log.svg" className="image" alt="" /> */}
            </div>
          </div>

          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <button onClick={() => handleRoleChange("Influencer")}>
                  Influencer
                </button>
                <button onClick={() => handleRoleChange("Follower")}>
                  Follower
                </button>
                <button onClick={() => handleRoleChange("Business")}>
                  Business
                </button>
                <button onClick={() => setShowModal(false)}>Close</button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ForgetPassword;
