import React from "react";
import CongratulationPopup from "../../components/modal/CongratulationPopup";
import RightSidePosts from "./post-comp/side-posts/RightSidePosts";
import SidePosts from "./post-comp/side-posts/SidePosts";
import SponsoredFeeds from "./post-comp/main-posts/SponsoredFeeds";

function NewSidePostFeeds() {
  return (
    <div className="new__post__feeds__container">
      <SidePosts />
      <CongratulationPopup />
      <SponsoredFeeds />
      <RightSidePosts />
    </div>
  );
}

export default NewSidePostFeeds;
