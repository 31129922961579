import React from "react";
import { motion } from "framer-motion";
import "./UserModal.css";
import { publicUrl } from "../../axios";
import { Avatar } from "../../assets/images/Images";

const UserModal = ({ isOpen, onClose, users, onSelectUser }) => {
  if (!isOpen) return null;

  //console.log("users", users);
  return (
    <motion.div
      className="modal-overlay"
      onClick={onClose}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <motion.div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        initial={{ y: "-100vh" }}
        animate={{ y: 0 }}
      >
        <button className="modal-close-button" onClick={onClose}>
          X
        </button>
        <h2 className="modal-title">Select a User</h2>
        <ul className="modal-user-list">
          {users?.map((user) => (
            <li
              key={user.id}
              className="modal-user-list-item"
              onClick={() => onSelectUser(user)}
            >
              <img
                src={user.image ? publicUrl + user.image : Avatar}
                alt={user.name}
                className="modal-user-avatar"
              />
              <div className="modal-user-info">
                <p className="modal-user-name">{user.name}</p>
                <p className="modal-user-posts">{user?.address || "N/A"}</p>
              </div>
            </li>
          ))}
        </ul>
      </motion.div>
    </motion.div>
  );
};

export default UserModal;
