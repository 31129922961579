import React, { useEffect, useRef, useState } from "react";
import { useZoomInAnimation } from "../../components/animations/useCustomAnimation";
import { FaEdit, FaSave, FaTrash } from "react-icons/fa";
import "./SinglePost.css";
import { useParams } from "react-router";
import axiosInstance from "../../axiosInstance";
import { publicUrl } from "../../axios";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";

const SinglePost = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const zoomInRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [post, setPost] = useState({});
  const [isPoll, setIsPoll] = useState(false);
  const [pollOptions, setPollOptions] = useState([]);
  const [owner, setOwner] = useState(false);

  const user = useSelector((state) => state?.user?.user);
  const userRole = useSelector((state) => state?.user?.role);

  useZoomInAnimation(zoomInRef, 1.5, 1);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    // Here you would also make an API call to save the changes
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnail(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddOption = () => {
    setPollOptions([...pollOptions, ""]);
  };

  const handleRemoveOption = (index) => {
    const newOptions = [...pollOptions];
    newOptions.splice(index, 1);
    setPollOptions(newOptions);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...pollOptions];
    newOptions[index] = value;
    setPollOptions(newOptions);
  };

  const influencerEndpoint = `/influencer/posts/${id}`;
  const businessEndpoint = `/business/posts/${id}`;
  const followerEndpoint = `/follower/posts/${id}`;

  const deletePost = async (id) => {
    try {
      setLoading(true);
      const res = await axiosInstance.delete(`/influencer/posts/${id}`);
      //console.log(res.data?.data);
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const getPost = async () => {
    try {
      const res = await axiosInstance.get(
        userRole == "influencer"
          ? influencerEndpoint
          : userRole == "business"
          ? businessEndpoint
          : followerEndpoint
      );
      const singlePost = res.data?.singlePost;
      setPost(singlePost);
      setTitle(singlePost.content);
      setContent(singlePost.content);
      setAuthor(singlePost.user.name);
      setThumbnail(singlePost.media_path); // Assuming thumbnail is user image for now
      setLoading(false);

      if (singlePost.type === "poll") {
        setIsPoll(true);
        setPollOptions(singlePost.pollOptions || []);
      }
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPost();
  }, [id]);

  useEffect(() => {
    if (post.user?.id && user?.id) {
      setOwner(user.id === post.user.id);
    }
  }, [post, user]);

  if (isPoll) {
    return (
      <div className="poll-page" ref={zoomInRef}>
        <div className="poll-page__header">
          <div>
            {isEditing ? (
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="poll-page__title-input"
              />
            ) : (
              <h1 className="poll-page__title">{title}</h1>
            )}
            <p className="poll-page__meta">
              Posted on {new Date(post.created_at).toLocaleDateString()} by{" "}
              {author}
            </p>
          </div>
          <div>
            <button className="poll-page__comment-button delete">
              <FaTrash /> Delete Post
            </button>
            {isEditing ? (
              <button
                className="poll-page__comment-button save"
                onClick={handleSaveClick}
              >
                <FaSave /> Save Post
              </button>
            ) : (
              <button
                className="poll-page__comment-button edit"
                onClick={handleEditClick}
              >
                <FaEdit /> Edit Post
              </button>
            )}
          </div>
        </div>
        <div className="poll-page__content">
          {isEditing ? (
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="poll-page__content-input"
            />
          ) : (
            <p>{content}</p>
          )}
          <div className="poll-page__options">
            {pollOptions.map((option, index) => (
              <div key={index} className="poll-page__option">
                {isEditing ? (
                  <>
                    <input
                      type="text"
                      value={option}
                      onChange={(e) =>
                        handleOptionChange(index, e.target.value)
                      }
                      className="poll-page__option-input"
                    />
                    <button
                      onClick={() => handleRemoveOption(index)}
                      className="poll-page__option-remove"
                    >
                      Remove
                    </button>
                  </>
                ) : (
                  <p>{option}</p>
                )}
              </div>
            ))}
            {isEditing && (
              <button
                onClick={handleAddOption}
                className="poll-page__option-add"
              >
                Add Option
              </button>
            )}
          </div>
        </div>
        <aside className="poll-page__sidebar">
          <h2 className="poll-page__sidebar-title">About the Author</h2>
          <div className="" style={{ display: "flex", alignItems: "center" }}>
            <img
              src={publicUrl + post.user?.image}
              alt={post.user?.name}
              className="poll-page__sidebar-image"
              style={{
                width: 40,
                height: 40,
                borderRadius: 20,
                marginRight: 10,
              }}
            />
            <p className="poll-page__sidebar-content">{author}</p>
          </div>
          <p className="poll-page__sidebar-bio">
            {post.user?.email} <br />
            Followers: {post.user?.follower_count}
          </p>
        </aside>
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="blog-page" ref={zoomInRef}>
          <div className="blog-page__header">
            <div>
              {isEditing ? (
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="blog-page__title-input"
                />
              ) : (
                <h1 className="blog-page__title">{title}</h1>
              )}
              <p className="blog-page__meta">
                Posted on {new Date(post.created_at).toLocaleDateString()} by{" "}
                {author}
              </p>
            </div>
            <div>
              {owner && (
                <button
                  className="blog-page__comment-button delete"
                  onClick={() => deletePost(post?.id)}
                >
                  <FaTrash /> Delete Post
                </button>
              )}
              {/* {isEditing ? (
                <button
                  className="blog-page__comment-button save"
                  onClick={handleSaveClick}
                >
                  <FaSave /> Save Post
                </button>
              ) : (
                owner && (
                  <button
                    className="blog-page__comment-button edit"
                    onClick={handleEditClick}
                  >
                    <FaEdit /> Edit Post
                  </button>
                )
              )} */}
            </div>
          </div>
          <div className="blog-page__thumbnail">
            {isEditing ? (
              <>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="blog-page__thumbnail-input"
                />
                <img
                  src={thumbnail}
                  alt="Blog Thumbnail"
                  className="blog-page__thumbnail-preview"
                />
              </>
            ) : (
              <img src={publicUrl + thumbnail} alt="Blog Thumbnail" />
            )}
          </div>
          <article className="blog-page__content">
            {isEditing ? (
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="blog-page__content-input"
              />
            ) : (
              <p>{content}</p>
            )}
          </article>
          <aside className="blog-page__sidebar">
            <h2 className="blog-page__sidebar-title">About the Author</h2>
            <div className="" style={{ display: "flex", alignItems: "center" }}>
              <img
                src={publicUrl + post.user?.image}
                alt={post.user?.name}
                className="blog-page__sidebar-image"
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 20,
                  marginRight: 10,
                }}
              />
              <p className="blog-page__sidebar-content">{author}</p>
            </div>
            <p className="blog-page__sidebar-bio">
              {post.user?.email} <br />
              Followers: {post.user?.follower_count}
            </p>
          </aside>
        </div>
      )}
    </>
  );
};

export default SinglePost;
