import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Avatar } from "../../assets/images/Images";
import { publicUrl } from "../../axios";
import axiosInstance from "../../axiosInstance";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FollowersList from "../../components/followers_list/FollowersList";
import { logout } from "../../redux/store/userSlice";
import "./Profile.css";
import CongratulationPopup from "../../components/modal/CongratulationPopup";
import ProfileWarn from "../../components/modal/ProfileWarn";
import LoadingComponent from "../../components/loading/LoadingComponent";
import ScrollingText from "../../components/animations/ScrollingText";

const ProfileCard = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [ref, setRef] = useState({});
  const [copied, setCopied] = useState(false);

  const influencerEndpoint = "/influencer/get-profile";

  const getRefs = async () => {
    try {
      const res = await axiosInstance.get("/influencer/referral-link");
      setRef(res.data);
    } catch (error) {
      //console.log(error);
    }
  };

  const handleCopy = (text) => {
    setCopied(true);
    navigator.clipboard.writeText(text).then(
      () => {
        //console.log("Text copied to clipboard");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const profileDetails = async () => {
    try {
      const res = await axiosInstance.get(influencerEndpoint);
      setUser(res.data?.user_profile);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    profileDetails();
    getRefs();
  }, []);

  const handleLogout = () => {
    setLoading(true);
    dispatch(logout());

    setTimeout(() => {
      setLoading(false);
      localStorage.setItem("verified", "false");
      window.location.href = "/login";
    }, 2000);
  };

  const [incompleteProfile, setIncompleteProfile] = useState(false);

  useEffect(() => {
    if (
      user?.address === null ||
      user?.phone === null ||
      user?.email === null ||
      user?.website === null ||
      user?.birthday === null ||
      user?.gender === null
    ) {
      setIncompleteProfile(true);
    }
  });

  return (
    <>
      {incompleteProfile && <ProfileWarn />}

      <div className="profile-card profile">
        <ToastContainer />
        <div className="left-section">
          <div className="profile-picture">
            <img
              src={user?.image ? publicUrl + user?.image : Avatar}
              alt="Profile"
            />
          </div>
          <div className="work-info">
            <h3>Info</h3>
            <p>
              <strong>Wallet</strong>{" "}
              <span className="work-tag">${user?.wallet_amount}</span>
            </p>
            <p>
              <br /> Address: {user?.address || "N/A"} <br /> Phone:{" "}
              {user?.phone || "N/A"}
              <br />
            </p>
          </div>

          <div className="social-icons">
            <h5>Share your referral link</h5>
            <>
              <FacebookShareButton
                url={ref?.referral_link}
                style={{ marginRight: 4 }}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={ref?.referral_link}
                style={{ marginRight: 4 }}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton
                url={ref?.referral_link}
                style={{ marginRight: 4 }}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </>
          </div>
        </div>
        <ScrollingText />
        <div className="right-section">
          <div className="profile-details">
            <h2>{user?.name}</h2>
            <p className="job-title">Influencer</p>
            <p className="location">{user?.address}</p>
            <div className="rating">
              <span className="ff">{user?.follower_count}</span>
              <span className="stars">
                {user?.follower_count > 1 ? "followers" : "follower"}
              </span>
            </div>
            <div className="actions">
              <button
                className="primary-btn"
                onClick={() => (window.location.href = "/posts")}
              >
                <a
                  href="/auth-posts"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  See Posts
                </a>
              </button>
              <button className="secondary-btn">
                <a
                  href="/followers"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Followers
                </a>
              </button>

              <button className="report-btn" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
          <div className="profile-info">
            <div className="section">
              <h3>Contact Information</h3>
              <p>
                <strong>Phone:</strong> {user?.phone || "N/A"}
              </p>
              <p>
                <strong>Address:</strong>
                {user?.address || "N/A"}
              </p>
              <p>
                <strong>Email:</strong> {user?.email || "N/A"}
              </p>
              <p>
                <strong>Site:</strong> {user?.website || "N/A"}
              </p>
            </div>
            <div className="section">
              <h3>Basic Information</h3>
              <p>
                <strong>Birthday:</strong> {user?.birthday || "N/A"}
              </p>
              <p>
                <strong>Gender:</strong> {user?.gender || "N/A"}
              </p>

              <p>
                <strong>Ref code:</strong> {ref?.referral_code}
              </p>

              <p>
                <button
                  onClick={() => handleCopy(ref?.referral_link)}
                  className="copy-to-clipboard-btn"
                >
                  {copied ? "Copied" : "Copy ref link"}{" "}
                </button>
              </p>
            </div>
          </div>
        </div>

        <div className="followers__sections">
          <FollowersList />
        </div>
      </div>
    </>
  );
};

export default ProfileCard;
