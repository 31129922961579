import React, { useState } from "react";
import { AddIcon } from "../../assets/images/icons";
import "./Faq.css";

const Faq = () => {
  const faqData = [
    {
      question: "What is Top Notch Influencer?",
      answer:
        "Top Notch Influencer is a platform that connects brands with top-tier influencers to enhance digital marketing strategies and drive real results.",
    },
    {
      question: "How does the platform work?",
      answer:
        "Our platform allows influencers to collaborate with brands, manage campaigns, and track performance metrics seamlessly.",
    },
    {
      question: "Who can use Top Notch Influencer?",
      answer:
        "Top Notch Influencer is designed for both brands looking to expand their reach and influencers seeking collaboration opportunities.",
    },
    {
      question: "How do I get started?",
      answer:
        "You can get started by signing up on our platform. Once registered, you can begin exploring and engaging with potential brand or influencer partners.",
    },
    {
      question: "What are the benefits of using the platform?",
      answer:
        "Benefits include access to top-tier influencers, seamless campaign management, and detailed performance tracking.",
    },
    {
      question: "Is there a cost to join?",
      answer:
        "Our platform offers both free and premium membership options to suit different needs.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id="faq">
      <section className="faq">
        <h1 className="half-border-header">Frequently Asked Questions</h1>
        <p>Your questions answered, all in one place.</p>
        <div className="faq-container">
          {faqData.map((faq, index) => (
            <div className="faq-column">
              <div key={index} className="faq-item">
                <button
                  className="faq-question"
                  onClick={() => toggleFAQ(index)}
                >
                  {faq.question}
                  <AddIcon />
                </button>
                <div
                  className="faq-answer"
                  style={{
                    maxHeight: activeIndex === index ? "1000px" : "0",
                    overflow: "hidden",
                    transition: "max-height 0.3s ease",
                  }}
                >
                  <p>{faq.answer}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Faq;
