import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Chats.css";
import axiosInstance from "../../axiosInstance";
import { publicUrl } from "../../axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

const Message = ({ text, time, sender, senderImage }) => {
  return (
    <div
      className={`chat__message ${
        sender !== "You" ? "chat__message--sent" : "chat__message--received"
      }`}
    >
      {/* <img
        src={senderImage}
        alt={`${sender}'s profile`}
        className="chat__profile-pic"
      /> */}
      <div className="chat__message-content">
        <div className="chat__message-text">{text}</div>
        <div className="chat__message-time">{time}</div>
      </div>
    </div>
  );
};

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const user = useSelector((state) => state.user?.user);
  const { id } = useParams();

  const getType = async () => {
    try {
      const res = await axiosInstance.get("/profile");
      //console.log("type: ", res.data);
    } catch (error) {
      //console.log("error", error);
    }
  };

  useEffect(() => {
    getType();
  }, []);

  useEffect(() => {
    // Function to fetch messages
    const fetchMessages = async () => {
      try {
        const response = await axiosInstance.get(`/messages/user/${id}`);
        setMessages(response.data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages(); // Fetch messages initially

    // Set up interval to fetch messages every 10 seconds
    const intervalId = setInterval(fetchMessages, 10000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [id]);

  const userRole = useSelector((state) => state.user?.role);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      // Create a temporary message object
      const tempMessage = {
        user_id: id,
        business_id: user?.id,
        message: newMessage,
        sender_type: userRole,
        id: Date.now(), // Temporary ID for local state
        created_at: new Date().toISOString(), // Temporary timestamp
      };

      // Optimistically update the UI with the new message
      setMessages((prevMessages) => [...prevMessages, tempMessage]);

      // Clear the input field
      setNewMessage("");

      // Send the actual request to the server
      axiosInstance
        .post("/messages", tempMessage)
        .then((response) => {
          // Replace the temporary message with the actual response data if needed
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === tempMessage.id ? response.data : msg
            )
          );
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          // Optionally, remove the optimistic message if the request fails
          setMessages((prevMessages) =>
            prevMessages.filter((msg) => msg.id !== tempMessage.id)
          );
        });
    }
  };

  return (
    <div className="chat">
      <form className="chat__message-form" onSubmit={handleSendMessage}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
          className="chat__input"
        />
        <button type="submit" className="chat__send-button">
          Send
        </button>
      </form>
      <div className="chat__messages">
        {messages.map((msg) => (
          <Message
            key={msg.id}
            text={msg.message}
            time={new Date(msg.created_at).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
            sender={
              msg.sender_type === "business" ? msg?.business?.name : "You"
            }
            // senderImage={
            //   msg.sender_type === "business"
            //     ? publicUrl + msg.business.image
            //     : publicUrl + user.image
            // }
          />
        ))}
      </div>
    </div>
  );
};

export default Chat;
