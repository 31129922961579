import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { format } from "date-fns"; // Import date-fns

const BusinessMessageDetail = ({ message }) => {
  const formatDate = (dateString) => {
    return format(new Date(dateString), "PPPppp");
  };
  if (!message) {
    return (
      <Box sx={{ padding: "20px" }}>
        <Typography variant="h6">
          No message to display, please click on a message
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: "20px", height: "100vh", overflowY: "auto" }}>
      <Typography variant="h6">{message?.user?.name}</Typography>
      <Typography variant="body2" color="textSecondary">
        {formatDate(message.updated_at)}
      </Typography>
      <Box sx={{ marginTop: "20px" }}>
        <Typography variant="body1" sx={{ marginBottom: "10px" }}>
          {message?.message}
        </Typography>
      </Box>

      <Button>
        <a
          href={`/chats/${message?.user?.id}`}
          style={{ color: "#00308F", textDecoration: "none" }}
        >
          Send Message
        </a>
      </Button>
    </Box>
  );
};

export default BusinessMessageDetail;
