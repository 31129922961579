import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { EmailIcon } from "../../assets/images/icons";
import { Avatar } from "../../assets/images/Images";
import { publicUrl } from "../../axios";
import axiosInstance from "../../axiosInstance";
import {
  useRotate360Animation,
  useZoomInAnimation,
} from "../../components/animations/useCustomAnimation";
import FollowersList from "../../components/followers_list/FollowersList";
import ProfileWarn from "../../components/modal/ProfileWarn";
import { logout } from "../../redux/store/userSlice";

const FollowerProfileCard = () => {
  const rotate360Ref = useRef(null);
  useRotate360Animation(rotate360Ref, 2, 5);

  const zoomInRef = useRef(null);
  useZoomInAnimation(zoomInRef, 1.5, 1);
  const { id } = useParams();
  const userID = useSelector((state) => state.user.user?.id);

  const followerEndpoint = `/follower/user/${id || userID}`;
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileDetails = async () => {
    try {
      // setLoading(true);
      const res = await axiosInstance.get(followerEndpoint);
      setUser(res.data?.user_profile);
      setLoading(false);
      localStorage.setItem("infID", res.data?.user_profile?.user_id);
      localStorage.setItem("folID", res.data?.user_profile?.id);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    profileDetails();
  }, []);

  const handleLogout = () => {
    setLoading(true);
    dispatch(logout()); // Call the logout action

    setTimeout(() => {
      setLoading(false);
      localStorage.setItem("verified", "false");
      window.location.href = "/login";
    }, 2000);
  };

  const [incompleteProfile, setIncompleteProfile] = useState(false);

  useEffect(() => {
    if (
      user?.address === null ||
      user?.phone === null ||
      user?.email === null ||
      user?.website === null ||
      user?.birthday === null ||
      user?.gender === null
    ) {
      setIncompleteProfile(true);
    }
  });

  return (
    <>
      {incompleteProfile && <ProfileWarn />}

      <div className="profile-card profile">
        <div className="left-section">
          <div className="profile-picture">
            <img
              ref={rotate360Ref}
              src={user?.image ? publicUrl + user?.image : Avatar}
              alt="Profile"
            />
          </div>
          <div className="work-info">
            <h3>{user?.name}</h3>
            <p>
              <strong>{user?.address || "N/A"}</strong>{" "}
              <span className="work-tag">Primary</span>
            </p>

            <p></p>
            <p>
              Address: {user?.address || "N/A"} <br />
              Phone: {user?.phone || "N/A"}
              {/* <a
              href="/chats"
              style={{ color: "#3e7ca8", textDecoration: "none" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <b>Send Message</b>
                <EmailIcon fontSize="14px" />
              </div>
            </a> */}
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="profile-details">
            <h2>{user?.name}</h2>
            <p className="job-title">Follower Profile</p>
            <p className="location">{user?.address || "N/A"}</p>

            <div className="actions">
              <button
                className="primary-btn"
                onClick={() => (window.location.href = "/posts")}
              >
                <a
                  href="/posts"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  See Posts
                </a>
              </button>
              <button className="primary-btn">
                <a
                  href="/register/influencer"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Become an influencer
                </a>
              </button>
              <button className="report-btn" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
          <div className="profile-info">
            <div className="section">
              <h3>Contact Information</h3>
              <p>
                <strong>Phone:</strong> {user?.phone || "N/A"}
              </p>
              <p>
                <strong>Address:</strong> {user?.address || "N/A"}
              </p>
              <p>
                <strong>Email:</strong> {user?.email || "N/A"}
              </p>
              <p>
                <strong>Site:</strong> {user?.website || "N/A"}
              </p>
            </div>
            <div className="section">
              <h3>Basic Information</h3>
              <p>
                <strong>Birthday:</strong> {user?.birthday || "N/A"}
              </p>
              <p>
                <strong>Gender:</strong> {user?.gender || "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FollowerProfileCard;
