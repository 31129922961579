import React from "react";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Container,
} from "@mui/material";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const WelcomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state?.user?.user);

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        bgcolor: "background.default",
        backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        padding: 0, // Ensure padding doesn't offset centering
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
        style={{ width: isMobile ? "95%" : "60%" }} // Adjusted to ensure centering
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            boxShadow: 5,
            borderRadius: 3,
            p: 5,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ mb: 3 }}>
            <motion.img
              src="https://img.freepik.com/free-vector/concept-building-rocket_52683-7671.jpg?ga=GA1.1.1780381790.1721057597&semt=sph"
              alt="Rocket Icon"
              style={{ width: 100, height: 100 }}
              initial={{ rotate: -10 }}
              animate={{ rotate: 10 }}
              transition={{
                yoyo: Infinity,
                duration: 1,
                ease: "easeInOut",
              }}
            />
          </Box>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "text.primary",
            }}
          >
            Hey {user && user?.name} 👋
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{
              fontWeight: 500,
              color: "text.secondary",
              mb: 2,
            }}
          >
            Welcome to TopNotch Influencers
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 4,
              color: "text.secondary",
              maxWidth: "80%",
              margin: "auto",
            }}
          >
            A verification link has been sent to your email. Please click on the
            link to get verified and enjoy an awesome experience.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: isMobile ? "1rem" : "1.2rem",
              padding: isMobile ? "0.5rem 1rem" : "0.75rem 2rem",
              borderRadius: 3,
            }}
          >
            Check your email
          </Button>
        </Box>
      </motion.div>
    </Container>
  );
};

export default WelcomePage;
