import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../axiosInstance";
import HeaderText from "../../components/headerText/HeaderText";
import LoadingComponent from "../../components/loading/LoadingComponent";
import DashboardTabs from "../../components/tabs/DashboardTabs";
import MainTab from "../../components/tabs/MainTab";
import SidePosts from "../posts/post-comp/side-posts/SidePosts";
import ProposalCard from "./ProposalCard";
import "./Proposals.css";

const Proposals = () => {
  const userDetails = useSelector((state) => state.user.user);
  const userRole = useSelector((state) => state.user.role);

  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);

  const influencerAllProposal = `/influencer/hires/pending/user/${userDetails?.id}`;
  const businessAllProposals = `/business/hires/pending/business/${userDetails?.id}`;

  const allProposals = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        userRole === "influencer" ? influencerAllProposal : businessAllProposals
      );
      setStats(res.data?.hires);
      setLoading(false);
    } catch (error) {
      //console.log(error);
      // setLoading(false);
    }
  };

  useEffect(() => {
    allProposals();
  }, [userRole, userDetails?.id]);

  // Filter the proposals to show only those with status "pending"
  const pendingProposals = stats.filter(
    (proposal) => proposal.status === "pending"
  );

  return (
    <>
      {/* <MainTab activeTab={"proposals"} /> */}
      <div className="proposal__heading">Proposals</div>

      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="proposal__container">
          <SidePosts />
          <div className="proposal-page">
            {pendingProposals.length > 0 ? (
              pendingProposals.map((proposal, index) => (
                <ProposalCard key={index} proposal={proposal} />
              ))
            ) : (
              <b>No pending proposals</b>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Proposals;
