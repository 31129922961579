import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import axiosInstance from "../../axiosInstance";
import { toast } from "react-toastify";
import BusinessMessageDetail from "./BusinessMessageDetails";
import BusinessMessageList from "./BusinessMessageList";

const BusinessAllMessages = () => {
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const handleSelectMessage = (message) => {
    setSelectedMessage(message);
  };

  const fetchMessages = async () => {
    try {
      const res = await axiosInstance.get("/messages/business-messages/all");
      const sortedMessages = res.data.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
      setMessages(sortedMessages);
    } catch (error) {
      //console.log(error);
      toast.error("Unable to fetch messages");
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <BusinessMessageList
        messages={messages}
        onSelectMessage={handleSelectMessage}
      />
      <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
        <BusinessMessageDetail message={selectedMessage} />
      </Box>
    </Box>
  );
};

export default BusinessAllMessages;
