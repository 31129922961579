import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// Import pages and components
import Home from "./pages/home/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Login from "./pages/auth/Login";
import Profile from "./pages/profile/Profile";
import Settings from "./pages/settings/Settings";
import Withdraw from "./pages/withdraw/Withdraw";
import Users from "./pages/users/Users";
// import MainFeeds from "./pages/posts/MainFeeds";
import Proposals from "./pages/proposals/Proposals";
import Accepted from "./pages/proposals/Accepted";
import Rejected from "./pages/proposals/Rejected";
import BusinessProfile from "./pages/profile/BusinessProfile";
import Hire from "./pages/hire/Hire";
// import CreatePost from "./pages/posts/CreatePost";
import AddFunds from "./pages/funds/AddFunds";
import ForgetPassword from "./pages/auth/ForgetPassword";
import SinglePost from "./pages/posts/SinglePost";
import ResetPassword from "./pages/auth/ResetPassword";
import AllHires from "./pages/hire/AllHires";
import AOS from "aos";
import "aos/dist/aos.css";
import FollowerProfile from "./pages/profile/FollowerProfile";
import Test from "./Test";
import Analytics from "./pages/analytics/Analytics";
import ProposalAnalytics from "./pages/analytics/ProposalAnalytics";
import FeedPost from "./pages/posts/FeedPost";
import SinglePoll from "./pages/posts/SinglePoll";
import ChatContainer from "./pages/messages/ChatContainer";
import BusinessChat from "./pages/messages/businessChatContainer";
import OtherProfile from "./pages/profile/OtherProfile";
// import AuthPosts from "./pages/posts/AuthPosts";
// import MainAuthFeeds from "./pages/posts/MainAuthFeeds";
import Followers from "./pages/users/Followers";
import OtherBusinessProfile from "./pages/profile/OtherBusiness";
import OtherFollowerProfile from "./pages/profile/OtherFollower";
import TeamSection from "./pages/OurTeam/TeamSection";
import ContactUs from "./pages/contact/Contact";
// import SidePosts from "./pages/posts/post-comp/side-posts/SidePosts";
import NewPostFeeds from "./pages/posts/NewPostFeeds";
import AllMessages from "./pages/messages/AllMessages";
import WelcomePage from "./pages/auth/WelcomePage";
import EmailVerify from "./pages/auth/EmailVerify";
// import AuthFeeds from "./pages/posts/AuthFeeds";
import NewAuthFeeds from "./pages/posts/NewAuthFeeds";
import TermsOfService from "./pages/policies/Terms";
import Privacy from "./pages/policies/Privacy";
import Contracts from "./pages/policies/Contracts";
import BusinessAllMessages from "./pages/messages/BusinessAllMessages";
import LoadingComponent from "./components/loading/LoadingComponent";
import Reviews from "./pages/reviews/Reviews";
import RegInfluencer from "./pages/auth/RegInfluencer";
import SingleReviewPage from "./pages/reviews/SingleReview";
import SingleFeed from "./pages/posts/post-comp/main-posts/SingleFeed";
import FollowerLogin from "./pages/auth/FollowerLogin";
import BusinessLogin from "./pages/auth/BusinessLogin";
import SponsoredFeeds from "./pages/posts/post-comp/main-posts/SponsoredFeeds";
import NewSidePostFeeds from "./pages/posts/NewSidePostFeed";
import Transactions from "./pages/withdraw/Transactions";
import FollowerReg from "./pages/auth/FollowerReg";
import InfluencerReg from "./pages/auth/InfluencerReg";
import BusinessReg from "./pages/auth/BusinessReg";
import SpecificHire from "./pages/hire/SpecificHire";
import WithdrawalHistory from "./pages/withdraw/WithdrawHistory";
import NotFoundPage from "./pages/fof/Error";

// Initialize Stripe with your publishable key
const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

export const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const button = document.getElementById("scrollToTopBtn");
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      button.style.display = "block";
    } else {
      button.style.display = "none";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      id="scrollToTopBtn"
      onClick={scrollToTop}
      style={{ display: "none" }}
    >
      ↑
    </button>
  );
};

const AppContent = () => {
  const userRole = useSelector((state) => state.user?.role);
  const user = useSelector((state) => state.user?.user);
  const [loading, setLoading] = useState(true);

  //console.log(user);

  const location = useLocation();
  const navigate = useNavigate();

  const authPages = [
    "/login",
    "/follower-login",
    "/business-login",
    "/register",
    "/forgot-password",
    "/reset-password",
    "/contracts",
    "/privacy",
    "/terms",
    "/error-page",
    "/",
  ];

  const isApiRoute = (path) => /^\/api/.test(path);
  const isReviewRoute = (path) => /^\/review/.test(path);
  const isRegRoute = (path) => /^\/register/.test(path);

  // console.log("isApiRoute", !isRegRoute);

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const checkVerification = async () => {
      const isVerified = user && localStorage.getItem("verified") === "true";

      setTimeout(() => {
        if (
          !isVerified &&
          !authPages.includes(location.pathname) &&
          !isApiRoute(location.pathname) &&
          !isReviewRoute(location.pathname) &&
          isRegRoute
        ) {
          navigate("/welcome");
        }
        setLoading(false); // Set loading to false after the delay
      }, 3000);
    };

    checkVerification();
  }, [location.pathname, navigate, user]);

  useEffect(() => {
    setTimeout(() => {
      if (
        !user &&
        !authPages.includes(location.pathname) &&
        !isApiRoute(location.pathname) &&
        !isReviewRoute(location.pathname) &&
        !isRegRoute(location.pathname)
      ) {
        navigate("/error-page");
      }
      setLoading(false);
    }, 3000);
  }, []);

  const hideFooter = location.pathname.startsWith("/chats");

  if (loading) {
    // Display a loading spinner or message until the verification is complete
    return (
      <div>
        <LoadingComponent />
      </div>
    );
  }

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/follower-login" element={<FollowerLogin />} />
        <Route path="/business-login" element={<BusinessLogin />} />
        <Route path="/follower-reg" element={<FollowerReg />} />
        <Route path="/influencer-reg" element={<InfluencerReg />} />
        <Route path="/business-reg" element={<BusinessReg />} />

        <Route path="/register" element={<Login />} />
        <Route path="/register/influencer" element={<RegInfluencer />} />

        <Route path="/forgot-password" element={<ForgetPassword />} />

        <Route path="/profile/:id" element={<OtherProfile />} />
        <Route
          path="/profile"
          element={
            userRole && userRole === "influencer" ? (
              <Profile />
            ) : userRole === "business" ? (
              <BusinessProfile />
            ) : (
              <FollowerProfile />
            )
          }
        />
        <Route path="/settings" element={<Settings />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/withdraw-history" element={<WithdrawalHistory />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/influencers" element={<Users />} />
        {/* <Route path="/posts" element={<MainFeeds />} /> */}
        <Route path="/auth-posts" element={<NewAuthFeeds />} />
        <Route path="/followers" element={<Followers />} />
        <Route path="/proposals" element={<Proposals />} />
        <Route path="/accepted-proposals" element={<Accepted />} />
        <Route path="/rejected-proposals" element={<Rejected />} />
        <Route path="/create-post" element={<FeedPost />} />
        <Route path="/single-post/:id" element={<SinglePost />} />
        <Route path="/single-poll/:id" element={<SinglePoll />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/all-hires" element={<AllHires />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/proposal-analytics" element={<ProposalAnalytics />} />
        <Route path="/chats/:id" element={<ChatContainer />} />
        <Route path="/chats/business/:id" element={<BusinessChat />} />
        <Route path="/profile-business" element={<BusinessProfile />} />
        <Route path="/reviews/:id" element={<SingleReviewPage />} />

        <Route
          path="/profile-business/:id"
          element={<OtherBusinessProfile />}
        />
        <Route path="/hire" element={<Hire />} />
        <Route path="/hire/:id" element={<SpecificHire />} />

        <Route path="/add-funds" element={<AddFunds />} />
        <Route path="/feedpost" element={<FeedPost />} />
        <Route path="/test" element={<Test />} />
        <Route path="/profile-follower" element={<FollowerProfile />} />
        <Route path="/our-team" element={<TeamSection />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contracts" element={<Contracts />} />

        <Route path="/about" element={<TeamSection />} />
        <Route path="/posts" element={<NewPostFeeds />} />
        <Route path="/sponsored-posts" element={<NewSidePostFeeds />} />

        <Route path="/posts/:id" element={<SingleFeed />} />
        <Route
          path="/all-messages"
          element={
            userRole == "business" ? <BusinessAllMessages /> : <AllMessages />
          }
        />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/reviews" element={<Reviews />} />

        <Route
          path={`/api/${userRole}/email/verify/:userId/:token`}
          element={<EmailVerify />}
        />

        <Route
          path={`/api/email/verify/:userId/:token`}
          element={<EmailVerify />}
        />

        <Route
          path="/profile-follower/:id"
          element={<OtherFollowerProfile />}
        />
        <Route path="/error-page" element={<NotFoundPage />} />
      </Routes>
      {!hideFooter && <Footer />}
    </>
  );
};

function App() {
  return (
    <Router>
      <Elements stripe={stripePromise}>
        <AppContent />
      </Elements>
      <ToastContainer />

      {/* <ScrollToTopButton /> */}
    </Router>
  );
}

export default App;
