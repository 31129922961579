import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../axiosInstance";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import {
  useRotate360Animation,
  useZoomInAnimation,
} from "../../components/animations/useCustomAnimation";
import MainTab from "../../components/tabs/MainTab";
import "./Settings.css";
import { publicUrl } from "../../axios";
import { useSelector } from "react-redux";
import Alerts from "../../components/alerts/Alerts";
import SidePosts from "../posts/post-comp/side-posts/SidePosts";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { Avatar } from "../../assets/images/Images";

export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const Settings = () => {
  const rotate360Ref = useRef(null);
  useRotate360Animation(rotate360Ref, 2, 5);

  const zoomInRef = useRef(null);
  useZoomInAnimation(zoomInRef, 1.5, 1);
  const [emailError, setEmailError] = useState("");

  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [birthday, setBirthday] = useState("");
  const [gender, setGender] = useState("");
  const [website, setWebsite] = useState("");
  const [userName, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [alerts, setAlerts] = useState(null);

  const userRole = useSelector((state) => state.user?.role);

  const fileInputRef = useRef(null);

  const businessEndpoint = "/business/get-profile";
  const followerEndpoint = "/follower/get-profile";
  const influencerEndpoint = "/influencer/get-profile";

  const businessUpdateEndpoint = "/business/profile";
  const followerUpdateEndpoint = "/follower/profile";
  const influencerUpdateEndpoint = "/influencer/profile";

  const getProfile = async () => {
    try {
      const res = await axiosInstance.get(
        userRole == "influencer"
          ? influencerEndpoint
          : userRole == "business"
          ? businessEndpoint
          : followerEndpoint
      );
      const profileData = res.data?.user_profile;
      //console.log(profileData);
      setProfile(profileData);
      setName(profileData.name || "");
      setEmail(profileData.email || "");
      setAddress(profileData.address || "");
      setBirthday(profileData.birthday || "");
      setGender(profileData.gender || "");
      setWebsite(profileData.website || "");
      // setUsername(profileData.username || "");
      setPhone(profileData.phone || "");
      setLoading(false);
    } catch (error) {
      //console.log("error: ", error);
      setLoading(false);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      setLoading(false);
      return;
    }

    setEmailError("");

    const formData = new FormData();
    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    formData.append("_method", "PUT");
    formData.append("name", name);
    formData.append("email", email);

    formData.append("website", website);
    formData.append("address", address);
    formData.append("birthday", birthday);
    formData.append("gender", gender);
    formData.append("username", userName);
    formData.append("phone", phone);

    // Log all input values
    //console.log("Form Data:");
    //console.log("Name:", name);
    //console.log("Email:", email);
    //console.log("Address:", address);
    //console.log("Birthday:", birthday);
    //console.log("Gender:", gender);
    //console.log("Website:", website);
    //console.log("Username:", userName);
    //console.log("phone:", phone);
    //console.log("Selected Image:", selectedImage);

    try {
      const res = await axiosInstance.post(
        userRole == "influencer"
          ? influencerUpdateEndpoint
          : userRole == "business"
          ? businessUpdateEndpoint
          : followerUpdateEndpoint,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log(res.data);
      setAlerts({ message: "Updated successfully", type: "success" });

      setLoading(false);
    } catch (error) {
      //console.log("error", error);
      let message = error.message; // Default to the general error message

      // Extract specific error messages if available
      const errorMessages = error.response?.data?.errors;
      if (errorMessages) {
        if (Array.isArray(errorMessages)) {
          // Handle array of error messages
          message = errorMessages
            .map((err) => `${err.field}: ${err.message}`)
            .join(" | ");
        } else if (typeof errorMessages === "object") {
          // Handle object of error messages
          message = Object.keys(errorMessages)
            .map((key) => `${key}: ${errorMessages[key].join(", ")}`)
            .join(" | ");
        }
      }

      setAlerts({ message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="settings-page settings" ref={zoomInRef}>
          {alerts && <Alerts message={alerts.message} type={alerts?.type} />}
          <div className="settings-page__side-posts">
            <SidePosts />
          </div>
          <div className="settings-page__profile-settings">
            {/* <MainTab activeTab={"settings"} /> */}

            <div className="settings-page__profile-content">
              <div className="settings-page__profile-picture-section">
                <img
                  ref={rotate360Ref}
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : profile?.image
                      ? publicUrl + profile?.image
                      : Avatar
                  }
                  alt="Profile"
                  className="settings-page__profile-picture"
                />
                <div className="settings-page__profile-picture-buttons">
                  <button
                    className="settings-page__upload-btn"
                    onClick={handleUploadButtonClick}
                  >
                    Upload New
                  </button>
                  {/* <button className="settings-page__remove-btn">
                    Remove Profile Picture
                  </button> */}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                </div>
              </div>
              <form
                className="settings-page__profile-form"
                onSubmit={handleUpdate}
              >
                <div className="settings-page__form-group settings-page__form-group--half">
                  <label className="settings-page__label">Name</label>
                  <input
                    className="settings-page__input"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="settings-page__form-group settings-page__form-group--half">
                  <label className="settings-page__label">Email address</label>
                  <div className="settings-page__input-wrapper">
                    <input
                      className="settings-page__input"
                      type="email"
                      value={email}
                      readOnly
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  {emailError && (
                    <p className="settings-page__error">{emailError}</p>
                  )}
                </div>
                {/* <div className="settings-page__form-group settings-page__form-group--half">
                  <label className="settings-page__label">Username</label>
                  <input
                    className="settings-page__input"
                    type="text"
                    value={userName}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div> */}
                <div className="settings-page__form-group settings-page__form-group--half">
                  <label className="settings-page__label">Phone number</label>
                  <div className="settings-page__input-wrapper">
                    <input
                      className="settings-page__input"
                      type="text"
                      placeholder="e.g, (123) 456-7890"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div className="settings-page__form-group">
                  <label className="settings-page__label">Bio</label>
                  <textarea
                    className="settings-page__textarea"
                    value={profile.bio || ""}
                    readOnly
                  />
                </div> */}
                <div className="settings-page__form-group">
                  <label className="settings-page__label">Address</label>
                  <input
                    className="settings-page__input"
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="settings-page__form-group">
                  <label className="settings-page__label">Birthday</label>
                  <input
                    className="settings-page__input"
                    type="date"
                    value={birthday}
                    onChange={(e) => setBirthday(e.target.value)}
                  />
                </div>
                <div className="settings-page__form-group">
                  <label className="settings-page__label">Gender</label>
                  {userRole === "business" ? (
                    <input
                      className="settings-page__input"
                      value="N/A"
                      disabled
                      readOnly
                    />
                  ) : (
                    <select
                      className="settings-page__input"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="" disabled>
                        Select gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  )}
                </div>

                <div className="settings-page__form-group">
                  <label className="settings-page__label">Website</label>
                  <input
                    className="settings-page__input"
                    type="url"
                    value={website}
                    placeholder="https://example.com"
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </div>
                <div className="update__btn">
                  <button type="submit" className="settings-page__update-btn">
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Settings;
