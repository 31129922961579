import React, { useRef, useState } from "react";
import "./Proposals.css";

import {
  useFadeInAnimation,
  useRotate360Animation,
  useRotateOnceAnimation,
  useScaleAnimation,
  useSlideInFromBottomAnimation,
  useSlideInFromLeftAnimation,
  useSlideInFromRightAnimation,
  useZoomInAnimation,
} from "../../components/animations/useCustomAnimation";
import { publicUrl } from "../../axios";
import axiosInstance from "../../axiosInstance";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";

const ProposalCard = ({ proposal }) => {
  const {
    title,
    description,
    budget,
    post_type,
    image,
    video,
    business,
    id,
    influencer,
    status,
  } = proposal;

  //console.log(image);

  const [loading, setLoading] = useState(false);

  const handleReject = async () => {
    setLoading(true);
    const payload = {
      status: "rejected",
    };
    try {
      const res = await axiosInstance.put(
        `influencer/hires/update-status/${id}`,
        payload
      );

      //console.log(res?.data);
      window.location.reload();

      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const handleAccept = async () => {
    setLoading(true);
    const payload = {
      status: "accepted",
    };
    try {
      const res = await axiosInstance.put(
        `influencer/hires/update-status/${id}`,
        payload
      );

      //console.log(res?.data);
      window.location.reload();
    } catch (error) {
      //console.log(error);
    }
  };

  const fadeInRef = useRef(null);
  const slideInFromLeftRef = useRef(null);
  const slideInFromRightRef = useRef(null);
  const zoomInRef = useRef(null);
  const slideInFromBottomRef = useRef(null);
  const rotate360Ref = useRef(null);
  const rotateOnceRef = useRef(null);

  const scaleRef = useRef(null);

  // Apply animations using custom hooks
  useFadeInAnimation(fadeInRef, 1, 0);
  useSlideInFromLeftAnimation(slideInFromLeftRef, 1, 0.5);
  useZoomInAnimation(zoomInRef, 1.5, 1);
  useSlideInFromBottomAnimation(slideInFromBottomRef, 1, 0);
  useRotate360Animation(rotate360Ref, 2, 5);
  useRotateOnceAnimation(rotateOnceRef, 2, 0);
  useSlideInFromRightAnimation(slideInFromRightRef, 1, 0);
  useScaleAnimation(scaleRef, 3);

  const userRole = useSelector((state) => state.user.role);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div ref={slideInFromLeftRef} className="proposal-card">
          <a href={`/profile-business/${business?.id}`}>
            <img
              src={
                business?.image
                  ? publicUrl + business?.image
                  : image
                  ? publicUrl + image
                  : "https://img.freepik.com/free-vector/images-concept-illustration_114360-218.jpg?ga=GA1.1.1780381790.1721057597&semt=ais_hybrid"
              }
              alt=""
            />
          </a>
          <div className="proposal-details">
            <h3>{title}</h3>
            <p>{description}</p>

            <div className="extra-info">
              <span>Budget: {budget}</span>
              <span>Post type: {post_type}</span>
              {userRole == "business" && (
                <>
                  <span>Influencer: {influencer?.name}</span>
                  <span>status: {status}</span>
                </>
              )}
              {image && (
                <a
                  href={`${publicUrl + image}`}
                  download
                  style={{ textDecoration: "none" }}
                >
                  Download attachment
                </a>
              )}

              {video && (
                <a
                  href={`${publicUrl + video}`}
                  download
                  style={{ textDecoration: "none" }}
                >
                  Download attachment
                </a>
              )}
            </div>
          </div>
          {userRole == "influencer" && (
            <div className="proposal-actions">
              <button className="accept-btn" onClick={handleAccept}>
                Accept
              </button>
              <button className="reject-btn" onClick={handleReject}>
                Reject
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProposalCard;
