import React, { useState, useEffect } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { motion } from "framer-motion";
import axiosInstance from "../../../../axiosInstance";
import { useSelector } from "react-redux";

const Poll = ({ question, options, id }) => {
  const userId = useSelector((state) => state.user.user?.id);
  const userRole = useSelector((state) => state.user.role);

  const [votes, setVotes] = useState(
    options?.reduce(
      (acc, option) => ({ ...acc, [option.text]: option.votes }),
      {}
    )
  );

  const [hasVoted, setHasVoted] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Check if the user has already voted
    options.forEach((option) => {
      // console.log("new", option);
      const userHasVoted = option?.votesArray?.some(
        (vote) => vote.follower_id === userId
      );

      const userHasVoted2 = option?.vote?.some(
        (vote) => vote.follower_id === userId
      );
      if (userHasVoted || userHasVoted2) {
        setHasVoted(true);
        setSelectedOption(option.text);
      }
    });
  }, [options, userId]);

  const handleVote = async (option) => {
    if (hasVoted) return;

    const optionText = option.text;
    setVotes((prevVotes) => ({
      ...prevVotes,
      [optionText]: prevVotes[optionText] + 1,
    }));
    setSelectedOption(optionText);
    setHasVoted(true);

    try {
      const response = await axiosInstance.post(
        `follower/poll-options/${option.id}/votes`,
        {
          follower_id: userId,
        }
      );

      if (response.status !== 201) {
        // Revert the state if the request fails
        throw new Error("Failed to submit vote");
      }
    } catch (error) {
      console.error("Error voting on poll", error);
      // Revert the state if the request fails
      setVotes((prevVotes) => ({
        ...prevVotes,
        [optionText]: prevVotes[optionText] - 1,
      }));
      setSelectedOption("");
      setHasVoted(false);
      setError(
        error?.response?.data?.message ||
          "Failed to submit vote. Please try again."
      );
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography
        variant="h6"
        fontWeight="bold"
        sx={{ mb: 2 }}
        onClick={() => id && (window.location.href = `/posts/${id}`)}
      >
        {question}
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {options.map((option, idx) => (
          <motion.div
            key={idx}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                cursor: "pointer",
                backgroundColor:
                  selectedOption === option.text ? "#cfe8fc" : "white",
                transition: "background-color 0.3s",
              }}
              onClick={() => userRole === "follower" && handleVote(option)}
            >
              <Typography variant="body1">{option.text}</Typography>
              <Typography variant="body2" color="textSecondary">
                {votes[option.text]} votes
              </Typography>
            </Paper>
          </motion.div>
        ))}
      </Box>
    </Box>
  );
};

export default Poll;
