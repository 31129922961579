import React from "react";
import BusinessTabs from "../../components/tabs/BusinessTabs";
import SidePosts from "../posts/post-comp/side-posts/SidePosts";
import BusinessProfileCard from "./BusinessProfileCard";

function BusinessProfile() {
  return (
    <div className="profile__main">
      {/* <BusinessTabs activeTab={"profile"} />
       */}

      <SidePosts />
      <BusinessProfileCard />
    </div>
  );
}

export default BusinessProfile;
