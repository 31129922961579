import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../axiosInstance";
import StripeCheckout from "react-stripe-checkout";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  Container,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import SidePosts from "../posts/post-comp/side-posts/SidePosts";
import "./AddFunds.css";
import { toast } from "react-toastify";

const currencyAPI = "https://api.exchangerate-api.com/v4/latest/USD";

const handleToken = async (token, amountUSD, navigate, setLoading) => {
  setLoading(true);
  try {
    // Make the amount integer for Stripe (cents format)
    const amountInCents = Math.round(amountUSD * 100);
    const res = await axiosInstance.post("/business/wallet/add-balance", {
      amount: amountUSD,
      stripeToken: "tok_visa",
    });
    if (res?.data) {
      toast.success("Funds added successfully");
      navigate("/analytics");
    }
  } catch (error) {
    // console.error("Error processing payment:", error);
    toast.error(
      error.response?.data?.error || "An error occurred. Please try again."
    );
  } finally {
    setLoading(false);
  }
};

export default function AddFunds() {
  const [amount, setAmount] = useState(50);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState("USD");
  const [convertedAmount, setConvertedAmount] = useState(amount);
  const [amountUSD, setAmountUSD] = useState(amount);
  const [rates, setRates] = useState({});
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await axios.get(currencyAPI);
        setRates(response.data.rates);
      } catch (error) {
        // console.error("Failed to fetch currency rates:", error);
      }
    };

    fetchRates();
  }, []);

  useEffect(() => {
    if (rates[currency]) {
      const newConvertedAmount = parseFloat(
        (amount * rates[currency]).toFixed(2)
      );
      setConvertedAmount(newConvertedAmount);

      const newAmountUSD = parseFloat((amount / rates[currency]).toFixed(2));
      setAmountUSD(newAmountUSD);
    }
  }, [amount, currency, rates]);

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  return (
    <div className="add__funds">
      <SidePosts />
      <Container maxWidth="sm" style={{ marginTop: "2rem" }}>
        <Paper elevation={3} style={{ padding: "2rem" }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Fund Your Account
          </Typography>
          <TextField
            fullWidth
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            inputProps={{ min: "1", step: "0.01" }}
            label={`Enter amount in ${currency}`}
            variant="outlined"
            margin="normal"
          />
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Select Currency</InputLabel>
            <Select
              value={currency}
              onChange={handleCurrencyChange}
              label="Select Currency"
            >
              {Object.keys(rates).length > 0 ? (
                Object.keys(rates).map((curr) => (
                  <MenuItem key={curr} value={curr}>
                    {curr}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  Loading currencies...
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Amount in {currency}: {convertedAmount}
          </Typography>
          <Box mt={2}>
            <StripeCheckout
              stripeKey="pk_test_51IsmhdEmpzbCkJ9LRnQHMGEdRQO7rLBzsuZBW0TJrerjcH0LTLn9FCjnLZlBiMoNS7d79rKTh1BmaVDfDvrmjXhF007dPYlebG"
              token={(token) =>
                handleToken(token, amountUSD, navigate, setLoading)
              }
              billingAddress
              shippingAddress
              amount={Math.round(amountUSD * 100)}
              name={user?.name}
              currency="USD"
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={loading}
                fullWidth
              >
                {loading ? <CircularProgress size={24} /> : "Add Funds"}
              </Button>
            </StripeCheckout>
          </Box>
          {loading && (
            <Box mt={2} display="flex" justifyContent="center">
              <CircularProgress />
              <Typography variant="body2" style={{ marginLeft: "1rem" }}>
                Processing...
              </Typography>
            </Box>
          )}
        </Paper>
      </Container>
    </div>
  );
}
