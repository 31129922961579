import React, { useEffect, useState } from "react";
import "./Analytics.css";
import { motion } from "framer-motion";
import {
  slideInFromBottom,
  slideInFromLeft,
  slideInFromRight,
} from "../../components/animations/useAnimations";
import MainTab from "../../components/tabs/MainTab";
import axiosInstance from "../../axiosInstance";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { publicUrl } from "../../axios";
import SidePosts from "../posts/post-comp/side-posts/SidePosts";
import LoadingComponent from "../../components/loading/LoadingComponent";

const Analytics = () => {
  const campaigns = [
    {
      title: "10 Simple steps to revolutionise workflows with our product",
      details: [{ label: "Start", date: "Not Started" }],
      budget: 400,
      likes: 4,
    },
    {
      title: "Boost your performance: start using our amazing product",
      details: [{ label: "Start", date: "Jun 1, 2023" }],
      budget: 200,
      likes: 4,
    },
    {
      title: "The power of our product: A new era in SaaS",
      details: [{ label: "Ended", date: "Jun 11, 2023" }],
      budget: 300,
      likes: 4,
    },
    {
      title: "Skyrocket your productivity: our product is revealed",
      details: [{ label: "Start", date: "Jul 1, 2023" }],
      budget: 100,
      likes: 4,
    },
  ];
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState({});
  const [stats, setStats] = useState({});
  const [pending, setPending] = useState({});
  const [rejected, setRejected] = useState({});
  const [accepted, setAccepted] = useState({});

  const userDetails = useSelector((state) => state.user.user);
  const userRole = useSelector((state) => state.user.role);

  // //console.log("userDetails", userDetails);

  const businessProfile = "/business/get-profile";
  const influencerProfile = "/influencer/get-profile";
  const followerProfile = "/follower/get-profile";

  const influencerAllProposals = `/influencer/hires/user/${userDetails?.id}`;
  const businessAllProposals = `/business/hires/business/${userDetails?.id}`;

  const influencerPendingProposal = `/inflencer/hires/pending/user/${userDetails?.id}`;
  const businessPendingProposals = `/business/hires/pending/business/${userDetails?.id}`;

  const influencerAcceptedProposal = `/influencer/hires/accepted/user/${userDetails?.id}`;
  const businessAcceptedProposals = `/business/hires/accepted/business/${userDetails?.id}`;

  const influencerRejectedProposal = `/influencer/hires/rejected/user/${userDetails?.id}`;
  const businessRejectedProposals = `/business/hires/rejected/business/${userDetails?.id}`;

  const profileDetails = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        userRole == "influencer"
          ? influencerProfile
          : userRole == "business"
          ? businessProfile
          : followerProfile
      );
      setUser(res.data?.user_profile);
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const allProposals = async () => {
    try {
      // setLoading(true);
      const res = await axiosInstance.get(
        userRole == "influencer" ? influencerAllProposals : businessAllProposals
      );
      setStats(res.data);
      // setLoading(false);
    } catch (error) {
      //console.log(error);
      // setLoading(false);
    }
  };

  const pendingProposals = async () => {
    try {
      // setLoading(true);
      const res = await axiosInstance.get(
        userRole == "influencer"
          ? influencerPendingProposal
          : businessPendingProposals
      );
      setPending(res.data);
      // setLoading(false);
    } catch (error) {
      //console.log(error);
      // setLoading(false);
    }
  };

  const acceptedProposals = async () => {
    try {
      // setLoading(true);
      const res = await axiosInstance.get(
        userRole == "influencer"
          ? influencerAcceptedProposal
          : businessAcceptedProposals
      );
      setAccepted(res.data);
      // setLoading(false);
    } catch (error) {
      //console.log(error);
      // setLoading(false);
    }
  };

  const rejectedProposals = async () => {
    try {
      // setLoading(true);
      const res = await axiosInstance.get(
        userRole == "influencer"
          ? influencerRejectedProposal
          : businessRejectedProposals
      );
      setRejected(res.data);
      // setLoading(false);
    } catch (error) {
      //console.log(error);
      // setLoading(false);
    }
  };

  useEffect(() => {
    profileDetails();
    allProposals();
    pendingProposals();
    acceptedProposals();
    rejectedProposals();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="analytics__container">
          <SidePosts />
          <div className="analytics">
            {/* <MainTab activeTab={"analytics"} /> */}
            <motion.h1
              className="analytics__title"
              variants={slideInFromLeft(0)}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.5, delay: 2 }}
            >
              Your total revenue
            </motion.h1>
            <motion.div
              className="analytics__revenue"
              variants={slideInFromLeft(0.5)}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.5, delay: 2 }}
            >
              ${user?.wallet_amount}
            </motion.div>
            {userRole !== "follower" && (
              <>
                <motion.div
                  className="analytics__metrics"
                  variants={slideInFromBottom(1)}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.5, delay: 2 }}
                >
                  <div className="analytics__metric">
                    <div className="analytics__metric-value">
                      {stats?.count || 0}
                    </div>
                    <div className="analytics__metric-label">All proposals</div>
                  </div>
                  <div className="analytics__metric">
                    <div className="analytics__metric-value">
                      {accepted?.count || 0}
                    </div>
                    <div className="analytics__metric-label">
                      Accepted proposals
                    </div>
                  </div>

                  <div className="analytics__metric">
                    <div className="analytics__metric-value">
                      {rejected?.count || 0}
                    </div>
                    <div className="analytics__metric-label">
                      Rejected proposals
                    </div>
                  </div>
                  <div className="analytics__metric">
                    <div className="analytics__metric-value">
                      {pending?.count || 0}
                    </div>
                    <div className="analytics__metric-label">
                      Pending proposals
                    </div>
                  </div>
                </motion.div>
                <motion.h1
                  className="analytics__title"
                  variants={slideInFromLeft(1.5)}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.5, delay: 2 }}
                >
                  Recent Proposals
                </motion.h1>
                <div className="analytics__campaigns">
                  {stats?.hires?.length > 0 ? (
                    stats.hires.map((campaign, index) => (
                      <motion.div
                        className="analytics__campaign-card"
                        key={index}
                        variants={slideInFromBottom(index + 1 * 2)}
                        initial="hidden"
                        animate="visible"
                        transition={{ duration: 0.5, delay: 2 }}
                        // onClick={() => (window.location.href = "/posts")}
                      >
                        {!campaign?.video && (
                          <img
                            src={
                              campaign?.image
                                ? publicUrl + campaign.image
                                : "https://img.freepik.com/free-vector/images-concept-illustration_114360-218.jpg?ga=GA1.1.1780381790.1721057597&semt=ais_hybrid"
                            }
                            style={{
                              height: "50%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        )}

                        {campaign?.video && (
                          <video
                            src={publicUrl + campaign.video}
                            style={{
                              height: "50%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            controls
                          />
                        )}
                        <div className="analytics__campaign-title">
                          {campaign?.title}
                        </div>
                        <div className="analytics__campaign-details">
                          {/* <div className="analytics__campaign-detail">
          <strong>Proposal date:</strong>{" "}
          {format(campaign?.created_at)} <br />
        </div> */}
                          <strong>Budget:</strong> ${campaign?.budget} <br />
                          <strong>Description:</strong> {campaign.description}{" "}
                          <br />
                          <strong>Post type:</strong> {campaign.post_type}{" "}
                          <br />
                          <strong>Status:</strong> {campaign.status}
                        </div>
                      </motion.div>
                    ))
                  ) : (
                    <b>No results found</b>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Analytics;
