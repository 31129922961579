import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Avatar } from "../../assets/images/Images";
import { publicUrl } from "../../axios";
import axiosInstance from "../../axiosInstance";
import { useScaleAnimation } from "../animations/useCustomAnimation";
import "./FollowersList.css";

const FollowersList = () => {
  const scaleRef = useRef(null);
  useScaleAnimation(scaleRef, 3);

  const [users, setUsers] = useState([]);
  const user = useSelector((state) => state.user?.user);

  const getFollowers = async () => {
    try {
      const res = await axiosInstance.get(`/influencer/user/${user?.id}`);
      //console.log(res.data?.user_profile?.followers);
      setUsers(res?.data?.user_profile?.followers || []);
    } catch (error) {
      //console.log("error", error);
    }
  };

  useEffect(() => {
    if (user?.id) {
      getFollowers();
    }
  }, [user]);

  return (
    <div className="followers-list">
      <h4>Recent Followers</h4>
      {users.length > 0 ? (
        users.slice(0, 5).map((follower, index) => (
          <a
            href={`/profile-follower/${follower?.id}`}
            className="followers__link"
            key={index}
          >
            <div className="followers-list__item">
              <img
                src={follower.image ? publicUrl + follower.image : Avatar}
                alt="follower avatar"
                className="followers-list__image"
              />
              <div className="followers-list__info">
                <h3 className="followers-list__name">{follower.name}</h3>
              </div>
            </div>
          </a>
        ))
      ) : (
        <p>No followers yet</p>
      )}
    </div>
  );
};

export default FollowersList;
