import React, { useState } from "react";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const sponsoredAds = [
  {
    title: "Nature Menu",
    description:
      "Perfect for those who love nutritious and organic food options.",
    image: require("../../../../assets/images/l1.webp"),
    link: "https://naturemenu.net/",
  },
  {
    title: "Nature Checkout",
    description: "Stay stylish while supporting sustainable brands.",
    image: require("../../../../assets/images/l2.webp"),
    link: "https://www.naturecheckout.com/",
  },
];

const RightSidePosts = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 900px)");

  // const [sponsoredAds, setSponsoredAds] = useState([]);

  if (isMobile) {
    return null; // Hide the component on mobile
  }

  return (
    <Box sx={{ width: "30%", p: 2, borderLeft: 0.1 }}>
      <>
        <Typography variant="body2" fontWeight="bold" mb={2}>
          Sponsored Ads
        </Typography>
        {sponsoredAds.length > 0 ? (
          <List>
            {sponsoredAds.map((ad, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={() => window.open(ad.link, "_blank")}
              >
                <ListItemAvatar>
                  <Avatar alt={ad.title} src={ad.image} />
                </ListItemAvatar>
                <ListItemText primary={ad.title} secondary={ad.description} />
                {/* <Button
                  variant="text"
                  size="small"
                  onClick={() => window.open(ad.link, "_blank")}
                >
                  Learn More
                </Button> */}
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>No ads found</Typography>
        )}
      </>
    </Box>
  );
};

export default RightSidePosts;
