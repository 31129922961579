import React from "react";
import {
  Modal,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios"; // Ensure axios is installed and imported
import axiosInstance from "../../axiosInstance";

const PostMoreModal = ({ open, handleClose, userId, postId, post }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.user.user?.id);
  const userRole = useSelector((state) => state.user.role);

  const isOwner = user === userId;

  const handleDelete = async () => {
    try {
      const res = await axiosInstance.delete(`/influencer/posts/${postId}`);
      //console.log("Post deleted successfully");

      if (res.data) {
        handleClose();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const options = [
    userRole === "business" && "Report",
    userRole === "business" && "Unfollow",
    // "Copy link",
    !isOwner && "About this account",
    isOwner && "Delete",
  ].filter(Boolean);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const handleRedirect = () => {
    window.location.href = `/profile/${post?.user?.id}`;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="more-options-modal"
      aria-describedby="modal-with-more-options"
    >
      <Box sx={style}>
        <List>
          {options.map((option, index) => (
            <React.Fragment key={index}>
              <ListItem
                button
                onClick={
                  option === "Delete"
                    ? handleDelete
                    : option === "About this account"
                    ? handleRedirect
                    : handleClose
                }
              >
                <ListItemText primary={option} />
              </ListItem>
              {index < options.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Modal>
  );
};

export default PostMoreModal;
