import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { format } from "date-fns"; // Optional for better date formatting
import { useSelector } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SidePosts from "../posts/post-comp/side-posts/SidePosts";

const TransactionTable = ({ transactions }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ maxWidth: "100%", mt: 3, height: "100vh" }}
    >
      <Typography variant="h5" align="center" sx={{ mb: 2, color: "#3e7ca8" }}>
        Transaction History
      </Typography>
      <Table sx={{ minWidth: 650 }} aria-label="transaction table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", color: "#3e7ca8" }}>
              ID
            </TableCell>
            {/* <TableCell sx={{ fontWeight: "bold", color: "#3e7ca8" }}>
              User ID
            </TableCell> */}
            <TableCell sx={{ fontWeight: "bold", color: "#3e7ca8" }}>
              User Type
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#3e7ca8" }}>
              Amount
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#3e7ca8" }}>
              Type
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#3e7ca8" }}>
              Description
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#3e7ca8" }}>
              Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": {
                  backgroundColor: "#f9f9f9",
                },
              }}
            >
              <TableCell>{row.id}</TableCell>
              {/* <TableCell>{row.user_id}</TableCell> */}
              <TableCell>{row.user_type}</TableCell>
              <TableCell>${row.amount}</TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>
                {format(new Date(row.created_at), "yyyy-MM-dd HH:mm")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default function Transactions() {
  const userRole = useSelector((state) => state.user.role);
  const [transactionList, setTransactionList] = useState([]);
  const endpoint = `/${userRole}/transactions`;

  const getTransactions = async () => {
    try {
      const response = await axiosInstance.get(endpoint);
      //   console.log("Transactions", response.data);
      setTransactionList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);
  return (
    <div style={{ display: "flex" }}>
      <SidePosts />
      <TransactionTable transactions={transactionList} />
    </div>
  );
}
