import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { EmailIcon } from "../../assets/images/icons";
import { Avatar } from "../../assets/images/Images";
import { publicUrl } from "../../axios";
import axiosInstance from "../../axiosInstance";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import {
  useRotate360Animation,
  useZoomInAnimation,
} from "../../components/animations/useCustomAnimation";
import FollowersList from "../../components/followers_list/FollowersList";
import LoadingComponent from "../../components/loading/LoadingComponent";
import ReviewModal from "../../components/modal/ReviewModal";
import { logout } from "../../redux/store/userSlice";
import SidePosts from "../posts/post-comp/side-posts/SidePosts";

const OtherBusinessProfile = () => {
  const rotate360Ref = useRef(null);
  useRotate360Animation(rotate360Ref, 2, 5);
  const myAccount = useSelector((state) => state.user.user);

  const zoomInRef = useRef(null);
  useZoomInAnimation(zoomInRef, 1.5, 1);

  const { id } = useParams();

  const businessEndpoint = `/business/user/${id}`;
  const influencerEndpoint = `/influencer/user/${myAccount?.id}`;

  const [user, setUser] = useState({});
  const [influencer, setInfluencer] = useState({});

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.user?.role);

  const profileDetails = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(businessEndpoint);
      setUser(res.data?.user_profile);
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const influencerProfileDetails = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(influencerEndpoint);
      setInfluencer(res.data?.user_profile);
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    profileDetails();
    influencerProfileDetails();
  }, []);

  const handleLogout = () => {
    setLoading(true);
    dispatch(logout());

    setTimeout(() => {
      setLoading(false);
      navigate("/login");
    }, 2000);
  };

  const maskDetails = (detail, visibleLength = 2) => {
    if (detail) {
      return (
        detail.slice(0, visibleLength) +
        "*".repeat(detail.length - visibleLength)
      );
    }
    return "";
  };

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  const handleReviewSubmit = async (reviewContent) => {
    setIsReviewModalOpen(false);
    setLoading(true);

    try {
      const payload = {
        business_id: id,
        rating: reviewContent.rating,
        comment: reviewContent.reviewContent,
      };

      //console.log(payload);

      const res = await axiosInstance.post(
        "/influencer/reviews/create",
        payload
      );

      //console.log(res.data?.review);

      if (res.status == 201) {
        toast.success(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
      toast.error(error.response?.data?.message);
    }
  };

  //console.log(influencer);

  // if (loading) {
  //   return <LoadingComponent />;
  // }

  return (
    <div style={{ display: "flex" }}>
      <div className="profile__main-sidebar">
        <SidePosts />
      </div>
      <div className="profile-card profile">
        <div className="left-section">
          <div className="profile-picture">
            <img
              ref={rotate360Ref}
              src={user?.image ? publicUrl + user?.image : Avatar}
              alt="Profile"
            />{" "}
          </div>
          <div className="work-info">
            <h3>{user?.name}</h3>
            <p>
              <strong>{maskDetails(user?.address) || "N/A"}</strong>{" "}
              <span className="work-tag">Primary</span>
            </p>

            <p></p>
            <p>
              Address: {maskDetails(user?.address) || "N/A"} <br /> Phone:{" "}
              {maskDetails(user?.phone, 3) || "N/A"}
              <br />
              {userRole == "influencer" &&
                influencer?.follower_count >= 5000 && (
                  <a
                    href={`/chats/business/${id}`}
                    style={{ color: "#3e7ca8", textDecoration: "none" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <b>Send Message</b>
                      <EmailIcon fontSize="14px" />
                    </div>
                  </a>
                )}
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="profile-details">
            <h2>{user?.name}</h2>
            <p className="job-title">Business Profile</p>
            <p className="location">{maskDetails(user?.address) || "N/A"}</p>

            {/* <div className="actions">
              <button
                className="primary-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsReviewModalOpen(!isReviewModalOpen);
                }}
              >
                Drop a review
              </button>
            </div> */}
          </div>
          <div className="profile-info">
            <div className="section">
              <h3>Contact Information</h3>
              <p>
                <strong>Phone:</strong> {maskDetails(user?.phone, 3) || "N/A"}
              </p>
              <p>
                <strong>Address:</strong> {maskDetails(user?.address) || "N/A"}
              </p>
              <p>
                <strong>Email:</strong> {maskDetails(user?.email, 3) || "N/A"}
              </p>
              <p>
                <strong>Site:</strong> {user?.website || "N/A"}
              </p>
            </div>
            <div className="section">
              <h3>Basic Information</h3>
              <p>
                <strong>Birthday:</strong>{" "}
                {maskDetails(user?.birthday) || "N/A"}
              </p>
              <p>
                <strong>Gender:</strong> {user?.gender || "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>

      <ReviewModal
        open={isReviewModalOpen}
        onClose={() => setIsReviewModalOpen(false)}
        onSubmit={handleReviewSubmit}
      />
    </div>
  );
};

export default OtherBusinessProfile;
