import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { FaEdit, FaSave, FaTrash } from "react-icons/fa";
import axiosInstance from "../../axiosInstance";
import { publicUrl } from "../../axios";
import "./SinglePoll.css";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import { useSelector } from "react-redux";
import Alerts from "../../components/alerts/Alerts";
import LoadingComponent from "../../components/loading/LoadingComponent";

const SinglePoll = () => {
  const { id } = useParams();
  const zoomInRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [pollQuestion, setPollQuestion] = useState("");
  const [pollOptions, setPollOptions] = useState([]);
  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(true);
  const [owner, setOwner] = useState(false);
  const [votes, setVotes] = useState({});
  const [votedOption, setVotedOption] = useState(null);
  const [alerts, setAlerts] = useState(null);
  const userRole = useSelector((state) => state?.user?.role);

  const user = useSelector((state) => state?.user?.user);

  //console.log("first", user);

  const deletePost = async (id) => {
    try {
      setLoading(true);
      await axiosInstance.delete(`/influencer/posts/${id}`);
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    try {
      //console.log("edited", pollQuestion);
      // Save logic here
    } catch (error) {
      //console.log(error);
    }
  };

  const handleDeleteOption = (index) => {
    setPollOptions(pollOptions.filter((_, i) => i !== index));
  };

  const handleOptionChange = (index, value) => {
    setPollOptions(
      pollOptions.map((option, i) =>
        i === index ? { ...option, option: value } : option
      )
    );
  };

  const handleVote = async (optionId) => {
    setLoading(true);
    try {
      const payload = {
        follower_id: user?.id,
      };

      await axiosInstance.post(
        `/follower/poll-options/${optionId}/votes`,
        payload
      );
      setVotes((prevVotes) => ({
        ...prevVotes,
        [optionId]: (prevVotes[optionId] || 0) + 1,
      }));
      setVotedOption(optionId);
      setLoading(false);
      setAlerts({ message: "Voted successfully", type: "success" });
    } catch (error) {
      //console.log(error?.response?.data?.message);
      setLoading(false);
      setAlerts({ message: error?.response?.data?.message, type: "error" });
    }
  };

  const businessEndpoint = `/business/posts/${id}`;
  const influencerEndpoint = `/influencer/posts/${id}`;
  const followerEndpoint = `/follower/posts/${id}`;

  //console.log(user);

  const getPost = async () => {
    try {
      const res = await axiosInstance.get(
        userRole == "influencer"
          ? influencerEndpoint
          : userRole == "business"
          ? businessEndpoint
          : followerEndpoint
      );
      const singlePost = res.data?.singlePost;
      setPost(singlePost);
      setPollQuestion(singlePost.poll.question);
      setPollOptions(singlePost.poll.options);
      const initialVotes = singlePost?.poll?.options?.reduce((acc, option) => {
        acc[option.id] = option?.votes?.length;
        return acc;
      }, {});
      setOwner(user?.id == post?.user?.id);
      //console.log(owner);
      setVotes(initialVotes);
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPost();
  }, [id]);

  useEffect(() => {
    if (post.user?.id && user?.id) {
      setOwner(user.id === post.user.id);
    }
  }, [post, user]);

  return (
    <>
      {alerts && <Alerts message={alerts.message} type={alerts?.type} />}

      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="poll-page" ref={zoomInRef}>
          <div className="poll-page__header">
            <div>
              {isEditing ? (
                <input
                  type="text"
                  value={pollQuestion}
                  onChange={(e) => setPollQuestion(e.target.value)}
                  className="poll-page__question-input"
                />
              ) : (
                <h1 className="poll-page__question">{pollQuestion}</h1>
              )}
              <p className="poll-page__meta">
                Posted on {new Date(post.created_at).toLocaleDateString()} by{" "}
                {post.user?.name}
              </p>
            </div>

            {owner && (
              <div>
                <button
                  className="poll-page__button delete"
                  onClick={() => deletePost(post?.id)}
                >
                  <FaTrash /> Delete Post
                </button>
                {/* {isEditing ? (
                  <button
                    className="poll-page__button save"
                    onClick={handleSaveClick}
                  >
                    <FaSave /> Save Poll
                  </button>
                ) : (
                  <button
                    className="poll-page__button edit"
                    onClick={handleEditClick}
                  >
                    <FaEdit /> Edit Poll
                  </button>
                )} */}
              </div>
            )}
          </div>
          <div className="poll-page__options">
            {pollOptions.map((option, index) => (
              <div key={option.id} className="poll-page__option">
                {isEditing ? (
                  <>
                    <input
                      type="text"
                      value={option.option}
                      onChange={(e) =>
                        handleOptionChange(index, e.target.value)
                      }
                      className="poll-page__option-input"
                    />
                    <button
                      className="poll-page__button delete-option"
                      onClick={() => handleDeleteOption(index)}
                    >
                      <FaTrash /> Delete Option
                    </button>
                  </>
                ) : (
                  <>
                    <p className="poll-page__option-text">{option?.option}</p>
                    <button
                      className={`poll-page__button vote ${
                        votedOption === option?.id ? "voted" : ""
                      }`}
                      onClick={() => handleVote(option?.id)}
                      disabled={votedOption !== null}
                    >
                      Vote
                    </button>
                    <span
                      className={`poll-page__votes ${
                        votedOption === option?.id ? "vote-count-animation" : ""
                      }`}
                    >
                      {votes[option?.id] || 0} votes
                    </span>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SinglePoll;
