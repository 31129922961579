import React from "react";
import {
  Box,
  Modal,
  Typography,
  IconButton,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SinglePostModal = ({ open, handleClose, post }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "95%" : "60%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: 400,
    overflow: "scroll",
    outline: "none",
  };

  if (!post) return null;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {post.postImage && (
          <Box
            component="img"
            sx={{
              height: "auto",
              width: "100%",
              marginBottom: 2,
            }}
            alt="Post image"
            src={post.postImage}
          />
        )}
        {post.postVideo && (
          <Box
            component="video"
            sx={{
              height: "auto",
              width: "100%",
              marginBottom: 2,
            }}
            alt="Post video"
            src={post.postVideo}
            controls
          />
        )}
        <Typography variant="h6" component="h2">
          {post.username}
        </Typography>
        <Typography sx={{ mt: 2 }}>{post.caption}</Typography>
        <List>
          {post.comments?.map((comment, index) => (
            <ListItem key={index} alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt={comment.user} src={comment.userAvatar} />
              </ListItemAvatar>
              <ListItemText primary={comment.user} secondary={comment.text} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Modal>
  );
};

export default SinglePostModal;
