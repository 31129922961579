import React, { useState, useEffect } from "react";
import "./Alerts.css";

const Alerts = ({ message, type }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return show && <div className={`notification ${type}`}>{message}</div>;
};

export default Alerts;
