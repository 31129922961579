import React from "react";
import { useSelector } from "react-redux";
import { FacebookIcon, HomeIcon, TwitterIcon } from "../../assets/images/icons";
import "./Footer.css";

function Footer() {
  const user = useSelector((state) => state.user.user);
  const userRole = useSelector((state) => state.user.role);

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3>About</h3>
          <p>
            At Top Notch Influencer, we believe in the power of influencer
            marketing to elevate brands and drive real results. Our platform is
            designed to connect businesses with the most relevant and impactful
            influencers, ensuring that your marketing efforts are not only
            effective but also authentic and engaging.
          </p>
          <p>
            We pride ourselves on our curated selection of top-notch
            influencers, each carefully chosen for their ability to resonate
            with your target audience.
          </p>
        </div>
        <div className="footer-column">
          <h3>Pages</h3>
          <ul>
            <li>
              <a href={user ? "/posts" : "/login"}>Posts</a>
            </li>
            {userRole == "business" && (
              <li>
                <a href={user ? "/influencers" : "/login"}>Influencers</a>
              </li>
            )}
            <li>
              <a href={user ? "/settings" : "/login"}>Settings</a>
            </li>
            <li>
              <a href={user ? "/analytics" : "/login"}>Analytics</a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Information</h3>
          <ul>
            {/* <li>
              <a href="/">Our Team</a>
            </li> */}
            <li>
              <a href="/#contact">Contact Us</a>
            </li>

            <li>
              <a href="/#about">About</a>
            </li>

            <li>
              <a href="/#features">Features</a>
            </li>
            {/* <li>
              <a href="/">About</a>
            </li>
            <li>
              <a href="/">Services</a>
            </li> */}
            {/* <li>
              <a href="/">Blog</a>
            </li> */}
            {/* <li>
              <a href="/">Terms and Services</a>
            </li> */}
          </ul>
        </div>

        <div className="footer-column">
          <h3>Privacy and Policies</h3>
          <ul>
            <li>
              <a href={"/privacy"}>Privacy</a>
            </li>
            <li>
              <a href={"/terms"}>Terms</a>
            </li>
            {/* <li>
              <a href={"/contracts"}>Contracts</a>
            </li> */}
          </ul>
        </div>
        {/* <div className="footer-column">
          <h3>Contact</h3>
          <p>
            <HomeIcon style={{ fontSize: 12 }} /> New York, NY 10012, US
          </p>
     
        </div> */}
      </div>
      <div className="footer-bottom">
        <p>Copyright © 2024</p>
        {/* <div className="social-icons">
          <a href="/">
            <FacebookIcon style={{ fontSize: 12, color: "#fff" }} />
          </a>
          <a href="/">
            <TwitterIcon style={{ fontSize: 12, color: "#fff" }} />
          </a>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
