import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PostHeaders from "../header-avatars/PostHeaders";
import PostMoreModal from "../../../../components/modal/PostMoreModal";
import ShareModal from "../../../../components/modal/ShareModal";
import Poll from "./Poll";
import SinglePostModal from "../../../../components/modal/SinglePostModal";
import axiosInstance from "../../../../axiosInstance";
import { publicUrl } from "../../../../axios";
import { useSelector } from "react-redux";
import LoadingAnimation from "../../../../components/animations/LoadingAnimation";
import ReviewModal from "../../../../components/modal/ReviewModal";
import { ReviewsOutlined } from "@mui/icons-material";
import LoadingComponent from "../../../../components/loading/LoadingComponent";

const MainFeeds = () => {
  const userId = useSelector((state) => state.user.user?.id);
  const user = useSelector((state) => state.user.user);
  const userRole = useSelector((state) => state.user?.role);

  const [modalOpen, setModalOpen] = useState(false);
  const [singleModalOpen, setSingleModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [postsState, setPostsState] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [loading, setLoading] = useState(true);

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  const infID = localStorage.getItem("infID");

  const followerEndpoint = `follower/inf-posts/${infID || user?.user_id}`;
  const influencerEndpoint = "influencer/auth-posts";
  const businessEndpoint = "business/follow-influencer-posts";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [postLink, setPostLink] = useState("");

  const getRefLink = async () => {
    try {
      const res = await axiosInstance.get("/influencer/referral-link");
      // setRefLink(res.data.referral_link);
    } catch (error) {
      //console.log(error);
    }
  };

  // useEffect(() => {
  //   const fetchPosts = async () => {
  //     try {
  //       const response = await axiosInstance.get("/follower/posts");
  //       setPostsState(response.data?.posts);
  //     } catch (error) {
  //       console.error("Error fetching posts data", error);
  //     }
  //   };

  //   fetchPosts();
  // }, []);

  const getPosts = async () => {
    localStorage.setItem("folID", userId);

    setLoading(true);
    try {
      const endpoint =
        userRole === "influencer"
          ? influencerEndpoint
          : userRole === "business"
          ? businessEndpoint
          : followerEndpoint;

      const res = await axiosInstance.get(endpoint);

      // Sort posts by created_at in descending order
      const sortedPosts = res.data?.posts.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      setPostsState(sortedPosts);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPosts();
    // getRefLink();
  }, [userRole]);

  const handleOpenShareModal = (post) => {
    setShareModalOpen(true);
    setSelectedPost(post);
  };

  const handleOpen = (post) => {
    setSelectedPost(post);
    setModalOpen(true);
  };

  const handleSingleOpen = (post) => {
    setSelectedPost(post);
    setSingleModalOpen(true);
  };

  const handleCloseModals = () => {
    setModalOpen(false);
    setShareModalOpen(false);
    setSingleModalOpen(false);
  };

  const handleLike = async (index) => {
    const post = postsState[index];
    const userHasLiked = post.reactions.some(
      (reaction) => reaction.follower_id === userId && reaction.is_liked === 1
    );
    const newLikeStatus = userHasLiked ? 0 : 1;
    const newLikesCount = userHasLiked ? post.likes - 1 : post.likes + 1;

    // Optimistically update the state
    setPostsState((prevPosts) =>
      prevPosts.map((p, i) =>
        i === index
          ? {
              ...p,
              likes: newLikesCount,
              reactions: p.reactions.some(
                (reaction) => reaction.follower_id === userId
              )
                ? p.reactions.map((reaction) =>
                    reaction.follower_id === userId
                      ? { ...reaction, is_liked: newLikeStatus }
                      : reaction
                  )
                : [
                    ...p.reactions,
                    { follower_id: userId, is_liked: newLikeStatus },
                  ],
            }
          : p
      )
    );

    try {
      // Make API request to update like status
      const response = await axiosInstance.post(
        `/follower/posts/${post.id}/reactions`,
        {
          is_liked: newLikeStatus,
        }
      );
      //console.log(response);
      if (response.status !== 201) {
        // Revert the state if the request fails
        setPostsState((prevPosts) =>
          prevPosts.map((p, i) =>
            i === index
              ? {
                  ...p,
                  likes: userHasLiked ? p.likes + 1 : p.likes - 1,
                  reactions: p.reactions.map((reaction) =>
                    reaction.follower_id === userId
                      ? { ...reaction, is_liked: userHasLiked ? 1 : 0 }
                      : reaction
                  ),
                }
              : p
          )
        );
      }
    } catch (error) {
      console.error("Error liking post", error);
      // Revert the state if the request fails
      setPostsState((prevPosts) =>
        prevPosts.map((p, i) =>
          i === index
            ? {
                ...p,
                likes: userHasLiked ? p.likes + 1 : p.likes - 1,
                reactions: p.reactions.map((reaction) =>
                  reaction.follower_id === userId
                    ? { ...reaction, is_liked: userHasLiked ? 1 : 0 }
                    : reaction
                ),
              }
            : p
        )
      );
    }
  };

  const handlePollVote = async (postIndex, optionIndex) => {
    const post = postsState[postIndex];
    const poll = post.poll;
    const option = poll.options[optionIndex];
    const originalVotes = option.votes.length;

    // Optimistically update the state
    setPostsState((prevPosts) =>
      prevPosts.map((post, i) =>
        i === postIndex
          ? {
              ...post,
              poll: {
                ...poll,
                options: poll.options.map((opt, j) =>
                  j === optionIndex
                    ? { ...opt, votes: [...opt.votes, "temp"] }
                    : opt
                ),
              },
            }
          : post
      )
    );

    try {
      const response = await axiosInstance.post(
        `/influencer/posts/${post.id}/vote`,
        {
          option_id: option.id,
        }
      );

      if (response.status !== 201) {
        // Revert the state if the request fails
        setPostsState((prevPosts) =>
          prevPosts.map((post, i) =>
            i === postIndex
              ? {
                  ...post,
                  poll: {
                    ...poll,
                    options: poll.options.map((opt, j) =>
                      j === optionIndex
                        ? { ...opt, votes: Array(originalVotes).fill("vote") }
                        : opt
                    ),
                  },
                }
              : post
          )
        );
      }
    } catch (error) {
      console.error("Error voting on poll", error);
      // Revert the state if the request fails
      setPostsState((prevPosts) =>
        prevPosts.map((post, i) =>
          i === postIndex
            ? {
                ...post,
                poll: {
                  ...poll,
                  options: poll.options.map((opt, j) =>
                    j === optionIndex
                      ? { ...opt, votes: Array(originalVotes).fill("vote") }
                      : opt
                  ),
                },
              }
            : post
        )
      );
    }
  };

  const handleReviewSubmit = (reviewContent) => {
    //console.log("Review Submitted:", reviewContent);
    // Implement actual review submission logic here (e.g., API call)
    setIsReviewModalOpen(false); // Close the modal after submission
  };

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    setPostsState((prevPosts) =>
      prevPosts.map((post) => {
        const userHasLiked = post?.reactions?.some(
          (reaction) =>
            reaction.follower_id === userId && reaction?.is_liked === 1
        );
        return {
          ...post,
          likedByUser: userHasLiked,
        };
      })
    );
    setInitialized(true);
  }, [postsState, userId, initialized]);

  return (
    <>
      {loading && <LoadingComponent />}
      {postsState.length > 0 ? (
        <>
          <Box
            sx={{
              maxWidth: isMobile ? "100%" : 600,
              mx: "auto",
              p: 2,
              overflowY: "scroll",
              scrollbarWidth: "thin", // For Firefox
              "&::-webkit-scrollbar": {
                width: "8px", // Width of the scrollbar
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888", // Color of the scrollbar thumb
                borderRadius: "4px", // Rounded corners for the scrollbar thumb
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555", // Color of the scrollbar thumb on hover
              },
            }}
          >
            {userRole == "business" && <PostHeaders />}

            {postsState?.map((post, index) => (
              <Box
                key={index}
                sx={{
                  mb: 4,
                  borderBottom: 0.2,
                  paddingBottom: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <a
                    href={`/profile/${post?.user?.id}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "#000",
                    }}
                  >
                    <Avatar
                      alt={post.user.name}
                      src={publicUrl + post.user.image}
                      sx={{ width: 40, height: 40, mr: 2 }}
                      onClick={() => !post.poll && handleOpen(post)}
                    />
                    <Typography variant="body1" fontWeight="bold">
                      {post.user.name}
                    </Typography>
                  </a>
                  <IconButton
                    sx={{ ml: "auto" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpen(post);
                    }}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                </Box>

                <Box>
                  <Typography>{post.content}</Typography>
                </Box>
                {post?.media_type === "video" && (
                  <Box
                    component="video"
                    src={publicUrl + post.media_path}
                    controls
                    sx={{
                      width: "100%",
                      borderRadius: 1,
                      height: isMobile ? 200 : 400,
                      objectFit: "cover",
                    }}
                    onClick={() => {
                      const postLink = `https://topnotchinfluencer.com/posts/${post?.id}`;
                      setPostLink(postLink);
                      window.location.href = `/posts/${post?.id}`;
                    }}
                  />
                )}
                {post?.media_type === "image" && (
                  <Box
                    onClick={() => {
                      const postLink = `https://topnotchinfluencer.com/posts/${post?.id}`;
                      setPostLink(postLink);
                      window.location.href = `/posts/${post?.id}`;
                    }}
                    component="img"
                    src={publicUrl + post.media_path}
                    alt="Post"
                    sx={{
                      width: "100%",
                      borderRadius: 1,
                      height: isMobile ? 200 : 400,
                      objectFit: "cover",
                    }}
                  />
                )}
                {post?.poll && (
                  <Poll
                    question={post.poll.question}
                    id={post?.id}
                    options={post.poll.options.map((option, optionIndex) => ({
                      text: option.option,
                      votes: option.votes.length,
                      id: option.id,
                      votesArray: option.votes,
                      onVote: () =>
                        handlePollVote(post.id, option.id, option.votes),
                    }))}
                  />
                )}

                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  {userRole === "follower" && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLike(index);
                      }}
                    >
                      {post.likedByUser ? (
                        <FavoriteIcon sx={{ color: "red" }} />
                      ) : (
                        <FavoriteBorderIcon />
                      )}
                    </IconButton>
                  )}
                  {userRole == "influencer" && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenShareModal(post);
                      }}
                    >
                      <ShareOutlinedIcon />
                    </IconButton>
                  )}

                  {/* <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsReviewModalOpen(!isReviewModalOpen);
                    }}
                  >
                    <ReviewsOutlined />
                  </IconButton> */}
                </Box>

                <Typography variant="body2" fontWeight="bold" sx={{ mt: 1 }}>
                  {
                    post?.reactions?.filter(
                      (reaction) => reaction.is_liked === 1
                    ).length
                  }{" "}
                  likes
                </Typography>

                {/* <Typography variant="body2" sx={{ mt: 1 }}>
              {new Date(post?.created_at).toLocaleString()}
            </Typography> */}
              </Box>
            ))}
          </Box>
        </>
      ) : (
        <div style={{ margin: 10 }}>
          <h3>No posts available</h3>
        </div>
      )}

      <PostMoreModal
        open={modalOpen}
        handleClose={handleCloseModals}
        post={selectedPost}
        userId={selectedPost?.user?.id}
        postId={selectedPost?.id}
      />
      <SinglePostModal
        open={singleModalOpen}
        handleClose={handleCloseModals}
        post={selectedPost}
      />
      <ShareModal
        open={shareModalOpen}
        handleClose={handleCloseModals}
        shareUrl={`https://topnotchinfluencer.com/posts/${selectedPost?.id}`}
      />

      <ReviewModal
        open={isReviewModalOpen}
        onClose={() => setIsReviewModalOpen(false)}
        onSubmit={handleReviewSubmit}
      />
    </>
  );
};

export default MainFeeds;
