import React, { useRef } from "react";
import { InsertDriveFileIcon, TrendingUpIcon } from "../../assets/images/icons";
import { HeroImage } from "../../assets/images/Images";
import "./Hero.css";
import {
  useFadeInAnimation,
  useRotateOnceAnimation,
} from "../../components/animations/useCustomAnimation";
import { useSelector } from "react-redux";

function Hero() {
  const rotateOnceRef = useRef(null);
  const fadeInRef = useRef(null);

  useRotateOnceAnimation(rotateOnceRef, 2, 0);
  useFadeInAnimation(fadeInRef, 1, 0);

  const user = useSelector((state) => state.user.user);

  return (
    <div className="hero" ref={fadeInRef}>
      <div className="hero__text__section">
        <div className="hero__text__header">
          Elevate Your Brand with <span>Top Notch Influencer.</span>
        </div>

        <div className="hero__text__desc">
          <p>
            Are you ready to take your brand or business to the next level? Look
            no further than Customer Engagement, your one-stop destination for
            top-notch influencer marketing services. We specialize in connecting
            you with influencers you can trust to not only grow your business
            but also to promote your brand or services effectively.
          </p>
          <p>
            We design our platform to provide a seamless experience, ensuring
            our clients can launch effective campaigns quickly and efficiently.
          </p>
        </div>

        {!user ? (
          <div className="hero__text__btns">
            <div>
              <button
                className="first"
                onClick={() => (window.location.href = "/login")}
              >
                get started
                <div>
                  <TrendingUpIcon style={{ fontSize: 12 }} />
                </div>
              </button>
            </div>

            <div>
              <button
                className="second"
                onClick={() => (window.location.href = "/about")}
              >
                learn more
                <div>
                  <InsertDriveFileIcon style={{ fontSize: 12 }} />
                </div>
              </button>
            </div>
          </div>
        ) : (
          <div className="hero__text__btns">
            <div>
              <button
                className="second"
                onClick={() => (window.location.href = "/posts")}
              >
                Explore
                <div>
                  <InsertDriveFileIcon style={{ fontSize: 12 }} />
                </div>
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="hero__img" ref={rotateOnceRef}>
        <img src={HeroImage} alt="hero" />
      </div>
    </div>
  );
}

export default Hero;
