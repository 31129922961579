import React from "react";
import { Team, TeamB } from "../../assets/images/Images";
import "./TeamSection.css";

function TeamSection() {
  return (
    <section className="team-section">
      <div className="team-section__header">
        <h1>
          <span className="highlight">Creators</span>,{" "}
          <span className="highlight">designers</span>, and world-class{" "}
          <span className="highlight">problem solvers</span>
        </h1>
        <p>
          To be the company our customers want us to be, it takes an eclectic
          group of passionate operators. Get to know the people{" "}
          <span className="highlight">leading the way</span> at Untitled.
        </p>
      </div>
      <div className="team-section__profiles">
        <ProfileCard name="Mia Ward" role="" imgSrc={Team} />
        <ProfileCard name="Phoenix Baker" role="" imgSrc={TeamB} />
      </div>
    </section>
  );
}

const ProfileCard = ({ name, role, imgSrc }) => (
  <div className="profile-card">
    <img src={imgSrc} alt={name} className="profile-card__image" />
    <div className="profile-card__info">
      <h3 className="profile-card__name">{name}</h3>
      <p className="profile-card__role">{role}</p>
    </div>
  </div>
);

export default TeamSection;
