import React, { useEffect, useState } from "react";
import { Modal, TextField, Button, IconButton } from "@mui/material";
import {
  PhotoCamera,
  Videocam,
  Poll,
  Business,
  Splitscreen,
} from "@mui/icons-material";
import "./FeedPost.css";
import HeaderText from "../../components/headerText/HeaderText";
import axiosInstance from "../../axiosInstance";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import LoadingComponent from "../../components/loading/LoadingComponent";

const FeedPost = () => {
  const [openTypeModal, setOpenTypeModal] = useState(false);
  const [pollModal, setPollModal] = useState(false);
  const [businessModal, setBusinessModal] = useState(false);
  const [proposalModal, setProposalModal] = useState(false);

  const [selectedType, setSelectedType] = useState("");
  const [pollQuestion, setPollQuestion] = useState("");
  const [content, setContent] = useState("");
  const [pollOptions, setPollOptions] = useState(["", ""]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [savedPoll, setSavedPoll] = useState(null);

  const [loading, setLoading] = useState(false);

  const [businessList, setBusinessList] = useState([]);
  const [proposalList, setProposalList] = useState([]);

  const [business, setBusiness] = useState(null);
  const [proposal, setProposal] = useState(null);

  const handleOpenTypeModal = () => setOpenTypeModal(true);
  const handleCloseTypeModal = () => setOpenTypeModal(false);

  const handleOpenBusinessModal = () => setBusinessModal(true);
  const handleCloseBusinessModal = () => setBusinessModal(false);

  const handleOpenProposalModal = () => setProposalModal(true);
  const handleCloseProposalModal = () => setProposalModal(false);

  const navigate = useNavigate();

  const user = useSelector((state) => state?.user?.user);

  const getBusiness = async () => {
    const res = await axiosInstance.get("influencer/business/list");
    setBusinessList(res.data?.businesses);
  };

  const getProposals = async () => {
    const res = await axiosInstance.get(`influencer/hires/user/${user?.id}`);
    setProposalList(res.data?.hires);
  };

  useEffect(() => {
    getBusiness();
    getProposals();
  }, []);

  const handleTypeSelection = (type) => {
    setSelectedType(type);
    setOpenTypeModal(false);
  };

  const handlePollOptionChange = (index, value) => {
    const options = [...pollOptions];
    options[index] = value;
    setPollOptions(options);
  };

  const addPollOption = () => setPollOptions([...pollOptions, ""]);
  const removePollOption = (index) =>
    setPollOptions(pollOptions.filter((_, i) => i !== index));

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    let payload;
    setLoading(true);

    if (selectedType === "poll") {
      payload = {
        content,
        type: "poll",
        question: pollQuestion,
        options: pollOptions,
      };
    } else {
      const formData = new FormData();
      formData.append("content", content);

      if (business || proposal) {
        formData.append("business_id", business?.id);
        formData.append("hire_id", proposal?.id);
      }

      if (selectedFile) {
        const fileType = selectedFile.type.split("/")[0];
        if (fileType === "image") {
          formData.append("media_path", selectedFile);
          formData.append("media_type", "image");
          formData.append("type", "image");
        } else if (fileType === "video") {
          formData.append("media_path", selectedFile);
          formData.append("media_type", "video");
          formData.append("type", "video");
        }
      }
      payload = formData;
    }

    try {
      //console.log("payload", payload);
      const headers =
        selectedType === "poll"
          ? { "Content-Type": "application/json" }
          : { "Content-Type": "multipart/form-data" };

      const res = await axiosInstance.post("/influencer/posts", payload, {
        headers,
      });
      //console.log(res?.data);
      setLoading(false);
      navigate("/posts");
    } catch (error) {
      //console.log("Error:", error);
      setLoading(false);
    }
  };

  const handleSavePoll = () => {
    setSavedPoll({
      question: pollQuestion,
      options: pollOptions,
    });
    setSelectedType("poll");
    setPollModal(false);
    setOpenTypeModal(false);
  };

  const handleBusinessSelect = (item) => {
    //console.log("business", business);
    setBusiness(item);
    handleCloseBusinessModal(true);
  };

  const handleProposalSelect = (item) => {
    //console.log("proposal", proposal);
    setProposal(item);
    handleCloseProposalModal(true);
  };

  useEffect(() => {
    //console.log("Business updated:", business?.name);
  }, [business]);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="feed-post">
          <HeaderText text={"Create a Post"} />
          {selectedType !== "poll" && (
            <div className="feed-post__input">
              <input
                type="text"
                placeholder="What's on your mind?"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          )}
          <div className="feed-post__options">
            <IconButton onClick={handleOpenTypeModal}>
              {selectedType === "image" && <PhotoCamera />}
              {selectedType === "video" && <Videocam />}
              {selectedType === "poll" && <Poll />}
              {!selectedType && <PhotoCamera />}
            </IconButton>
          </div>

          <Modal open={openTypeModal} onClose={handleCloseTypeModal}>
            <div className="type-modal">
              <h2>Select Post Type</h2>
              <div className="type-options">
                <IconButton
                  onClick={() => {
                    handleTypeSelection("image");
                  }}
                >
                  <PhotoCamera />
                  <p style={{ fontSize: 14 }}>Image</p>
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleTypeSelection("video");
                  }}
                >
                  <Videocam />
                  <p style={{ fontSize: 14 }}>Video</p>
                </IconButton>
                <IconButton
                  onClick={() => {
                    setPollModal(true);
                    handleTypeSelection("poll");
                  }}
                >
                  <Poll />
                  <p style={{ fontSize: 14 }}>Poll</p>
                </IconButton>
              </div>
              <Button onClick={handleCloseTypeModal}>Close</Button>
            </div>
          </Modal>
          {selectedType &&
            (selectedType === "image" || selectedType === "video") && (
              <div className="file-input">
                <input
                  type="file"
                  accept={selectedType === "image" ? "image/*" : "video/*"}
                  onChange={handleFileChange}
                />
                {selectedFile && (
                  <div className="file-preview">
                    {selectedType === "image" ? (
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt="Selected"
                      />
                    ) : (
                      <video controls>
                        <source
                          src={URL.createObjectURL(selectedFile)}
                          type={selectedFile.type}
                        />
                      </video>
                    )}
                  </div>
                )}
              </div>
            )}
          {selectedType === "poll" && (
            <Modal open={pollModal} onClose={() => setSelectedType("")}>
              <div className="poll-modal">
                <h2>Create a Poll</h2>
                <TextField
                  label="Poll Question"
                  variant="outlined"
                  fullWidth
                  value={pollQuestion}
                  onChange={(e) => setPollQuestion(e.target.value)}
                />
                {pollOptions.map((option, index) => (
                  <div key={index} className="poll-option">
                    <TextField
                      label={`Option ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      value={option}
                      onChange={(e) =>
                        handlePollOptionChange(index, e.target.value)
                      }
                    />
                    <Button onClick={() => removePollOption(index)}>
                      Remove
                    </Button>
                  </div>
                ))}
                <Button onClick={addPollOption}>Add Option</Button>
                <Button onClick={handleSavePoll}>Save Poll</Button>
              </div>
            </Modal>
          )}

          {selectedType == "poll" && savedPoll && (
            <div className="poll-display">
              <h3>Poll Question: {savedPoll.question}</h3>
              <ul>
                {savedPoll.options.map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
            </div>
          )}
          <IconButton onClick={handleOpenBusinessModal}>
            <Business />{" "}
            <span style={{ fontSize: 14 }}>
              {business ? business?.name : "Select Business"}
            </span>
          </IconButton>
          <br />
          <IconButton onClick={handleOpenProposalModal}>
            <Splitscreen />

            <span style={{ fontSize: 14 }}>
              {proposal ? proposal?.title : "Select Proposal"}
            </span>
          </IconButton>
          <Modal open={businessModal} onClose={handleCloseBusinessModal}>
            <div className="business-modal">
              <h2>Select Business</h2>
              {businessList.map((business, index) => (
                <Button
                  key={index}
                  onClick={() => handleBusinessSelect(business)}
                >
                  {business?.name}
                </Button>
              ))}
            </div>
          </Modal>

          <Modal open={proposalModal} onClose={handleCloseProposalModal}>
            <div className="proposal-modal">
              <h2>Select Proposal</h2>
              {proposalList.map((proposal, index) => (
                <Button
                  key={index}
                  onClick={() => handleProposalSelect(proposal)}
                >
                  {proposal?.title}
                </Button>
              ))}
            </div>
          </Modal>

          <div className="post__submit" onClick={handleSubmit}>
            Submit
          </div>
        </div>
      )}
    </>
  );
};

export default FeedPost;
