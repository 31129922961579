import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  Rating,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useSpring, animated } from "@react-spring/web";
import SidePosts from "../posts/post-comp/side-posts/SidePosts";
import "./Reviews.css";
import axiosInstance from "../../axiosInstance";
import { publicUrl } from "../../axios";
import { useSelector } from "react-redux";
import ShareIcon from "@mui/icons-material/Share"; // Import the Share icon
import ShareReview from "../../components/modal/ShareReview";

const ReviewList = ({ reviews }) => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const userRole = useSelector((state) => state.user.role);

  const handleOpenShareModal = () => setShareModalOpen(true);
  const handleCloseModals = () => {
    setShareModalOpen(false);
  };

  const fadeInSpring = useSpring({
    opacity: 1,
    transform: "translateY(0)",
    from: { opacity: 0, transform: "translateY(20px)" },
    config: { tension: 280, friction: 60 },
  });

  const handleShare = (review) => {
    //console.log("Sharing review:", review);
    // // Example: Use Web Share API if supported
    // if (navigator.share) {
    //   navigator
    //     .share({
    //       title: "Check out this review",
    //       text: review.comment,
    //       url: window.location.href,
    //     })
    //     .catch(console.error);
    // } else {
    //   alert("Share feature is not supported on this device.");
    // }
  };

  return (
    <div className="reviews__container">
      <SidePosts />
      {reviews?.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: isMobile ? "100%" : "50%",
            p: 2,
          }}
        >
          {reviews
            .slice(
              userRole === "influencer"
                ? 0
                : reviews.length - reviews.length - 1
            ) // Show all reviews if influencer, otherwise slice
            .map((review, index) => (
              <animated.div
                style={{
                  ...fadeInSpring,
                  animationDelay: `${index * 100}ms`,
                }}
                key={index}
                onClick={() =>
                  (window.location.href = `/reviews/${review?.id}/?${
                    review?.follower ? "Follower" : "Business"
                  }`)
                }
              >
                <Card
                  elevation={3}
                  sx={{
                    backgroundColor: "#f9f9f9",
                    borderLeft: "4px solid #3e7ca8",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.2s",
                    "&:hover": {
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <Avatar
                      src={
                        (review.follower?.image &&
                          publicUrl + review.follower.image) ||
                        (review.business?.image &&
                          publicUrl + review.business.image) ||
                        (review.user?.image && publicUrl + review.user.image) ||
                        publicUrl + "default-image.png"
                      }
                      alt={review.name}
                      sx={{
                        width: 56,
                        height: 56,
                        mr: 2,
                      }}
                    />
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#3e7ca8",
                          fontWeight: "500",
                        }}
                      >
                        {review?.follower?.name ||
                          review?.business?.name ||
                          review?.user?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ fontStyle: "italic" }}
                      >
                        {review.date}
                      </Typography>
                      <Rating
                        value={review.rating}
                        readOnly
                        sx={{ mt: 1, mb: 1, color: "#f8b400" }}
                      />
                      <Typography variant="body1" sx={{ color: "#555" }}>
                        {review?.comment}
                      </Typography>
                    </Box>
                    {!review?.user && (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#3e7ca8",
                        }}
                      >
                        Type: {review?.follower ? "Follower" : "Business"}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </animated.div>
            ))}
        </Box>
      ) : (
        <Typography sx={{ ml: 2, color: "#888", fontStyle: "italic" }}>
          No review found
        </Typography>
      )}

      {/* <ShareReview open={shareModalOpen} handleClose={handleCloseModals} /> */}
    </div>
  );
};

const Reviews = () => {
  const [allReviews, setAllReviews] = useState([]);
  const userRole = useSelector((state) => state.user.role);

  const getAllReviews = async () => {
    try {
      let reviews = [];

      if (userRole === "influencer") {
        // Fetch reviews from both follower and business endpoints
        const [followerReviews, businessReviews] = await Promise.all([
          axiosInstance.get("/influencer/reviews/get-reviews"),
          axiosInstance.get("/influencer/reviews/get-business-reviews"),
        ]);

        // Sort each review array by created_at (descending)
        const latestFollowerReview = followerReviews.data.length
          ? followerReviews.data.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            )[0]
          : null;
        const latestBusinessReview = businessReviews.data.length
          ? businessReviews.data.sort(
              (a, b) => new Date(a.created_at) - new Date(b.created_at)
            )[0]
          : null;

        // Collect the latest reviews from both endpoints
        reviews = [latestFollowerReview, latestBusinessReview].filter(Boolean); // Filter out null or undefined
      } else if (userRole === "business") {
        const businessReview = await axiosInstance.get(
          "/business/reviews/get-inf-reviews"
        );
        reviews = businessReview.data.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      } else if (userRole === "follower") {
        const followerReview = await axiosInstance.get(
          "/follower/reviews/get-reviews"
        );
        reviews = followerReview.data.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      }

      // Set the latest review(s)
      setAllReviews(reviews);
    } catch (error) {
      console.error("Failed to fetch reviews:", error);
    }
  };

  useEffect(() => {
    getAllReviews();
  }, []);

  return <ReviewList reviews={allReviews} />;
};

export default Reviews;
