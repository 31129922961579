import React, { useRef } from "react";
import { useZoomInAnimation } from "../../components/animations/useCustomAnimation";
import BusinessTabs from "../../components/tabs/BusinessTabs";
import FollowerTabs from "../../components/tabs/FollowerTabs";
import SidePosts from "../posts/post-comp/side-posts/SidePosts";
import BusinessProfileCard from "./BusinessProfileCard";
import FollowerProfileCard from "./FollowerProfileCard";

function FollowerProfile() {
  const zoomInRef = useRef(null);
  useZoomInAnimation(zoomInRef, 1.5, 1);
  return (
    <div style={{ display: "flex" }}>
      <div className="profile__main-sidebar">
        <SidePosts />
      </div>
      <div className="profile__main">
        <FollowerProfileCard />
      </div>
    </div>
  );
}

export default FollowerProfile;
