import React, { useEffect, useState } from "react";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import "./Login.css";
import Alerts from "../../components/alerts/Alerts";
import { Api, setAxiosToken } from "../../axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, register, role } from "../../redux/store/userSlice";
import { toast } from "react-toastify";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const dispatch = useDispatch();
  const [infRegMode, setInfRegMode] = useState(false);
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const [selectedRole, setSelectedRole] = useState("Influencer");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState(null);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [refCode, setRefCode] = useState("");
  const location = useLocation();

  const userToken = useSelector((state) => state.user.token);

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const code = params.get("referral-code");
  //   setRefCode(code);
  // }, [location]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("referral-code");

    if (code) {
      setRefCode(code);
      setInfRegMode(true);
      setIsSignUpMode(true); // If infRegMode is true, it's sign-up mode
      setSelectedRole("Follower");
    }
  }, [location]);

  useEffect(() => {
    if (userToken) {
      localStorage.setItem("token", userToken);
      setAxiosToken();
    }
  }, [userToken]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const influencerEndpoint = "/influencer/login";
    const followerEndpoint = "/follower/login";
    const businessEndpoint = "/business/login";

    let res;

    try {
      const payload = { email: email, password: password };
      if (selectedRole == "Influencer") {
        res = await Api.post(influencerEndpoint, payload);
      }

      if (selectedRole == "Business") {
        res = await Api.post(businessEndpoint, payload);
        //console.log(res.data);
      }

      if (selectedRole == "Follower") {
        res = await Api.post(followerEndpoint, payload);
        //console.log(res.data);
      }

      if (res.data?.token) {
        setLoading(false);
        setAlerts({ message: "User logged in successfully", type: "success" });
        navigate("/posts");
        dispatch(login(res?.data?.user));
        dispatch(role(res?.data?.role));

        localStorage.setItem("token", res.data?.token);

        // //console.log(res.data?.user?.email_verified_at);

        if (res.data?.user?.email_verified_at === null) {
          localStorage.setItem("verified", "false");
        } else {
          localStorage.setItem("verified", "true");
        }

        setAxiosToken();
      }
    } catch (error) {
      //console.log("error", error.message);

      setLoading(false);
      if (error.errors) {
        const errors = error.errors;
        // Map over all errors and display each one using Toastify
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((errorMessage) => {
            toast.error(errorMessage);
          });
        });
      } else {
        toast.error(
          error?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  };

  useEffect(() => {
    const container = document.querySelector(".container");
    if (isSignUpMode) {
      container?.classList.add("sign-up-mode");
    } else {
      container?.classList.remove("sign-up-mode");
    }
  }, [isSignUpMode]);

  const handleRoleChange = (role) => {
    setSelectedRole(role);
    setShowModal(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateEmail(email)) {
      setAlerts({ message: "Please enter a valid email", type: "error" });

      return;
    }

    if (password !== confirmPassword) {
      setAlerts({ message: "Passwords do not match", type: "error" });

      return;
    }

    if (password < 8) {
      setAlerts({
        message: "Passwords length must be up to 8 ",
        type: "error",
      });

      return;
    }

    if (selectedRole === "Follower" && !refCode) {
      setAlerts({ message: "Please enter the referral code", type: "error" });
      return;
    }

    //console.log("selected role", selectedRole);

    const influencerEndpoint = "/influencer/new_register";
    const followerEndpoint = "/follower/new_register";
    const businessEndpoint = "/business/new_register";

    try {
      const influencerPayload = {
        email: email,
        password: password,
        name: name,
      };
      const businessPayload = { email: email, password: password, name: name };
      const followerPayload = {
        email: email,
        password: password,
        name: name,
        referral_code: refCode,
      };

      let res;

      if (selectedRole == "Influencer") {
        res = await Api.post(influencerEndpoint, influencerPayload);
      }

      if (selectedRole == "Business") {
        res = await Api.post(businessEndpoint, businessPayload);
        //console.log(res.data);
      }

      if (selectedRole == "Follower") {
        res = await Api.post(followerEndpoint, followerPayload);
        //console.log(res.data);
      }

      if (res.data?.token) {
        // navigate("/posts");
        dispatch(register(res?.data?.user));
        dispatch(role(res?.data?.role));

        localStorage.setItem("token", res.data?.token);
        localStorage.setItem("verified", "false");

        setAxiosToken();
        window.location.href = "/welcome";
        // setLoading(false);
      } else {
        // alert("nothing");
      }

      setLoading(false);
      setAlerts({
        message:
          res.data?.message || "successfully registered pls check your email",
        type: "success",
      });
      setIsSignUpMode(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
      setIsSignUpMode(false);
      if (error.errors) {
        const errors = error.errors;
        // Map over all errors and display each one using Toastify
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((errorMessage) => {
            toast.error(errorMessage);
          });
        });
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      // setAlerts({ message: error?.message, type: "error" });
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="auth container">
          {alerts && <Alerts message={alerts.message} type={alerts?.type} />}

          <div className="forms-container">
            <div className="signin-signup">
              <form action="#" className="sign-in-form auth__form">
                <h2 className="title">Influencer sign in</h2>
                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-field">
                  <i className="fas fa-lock"></i>
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <span className="eye-icon" onClick={togglePasswordVisibility}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <a
                  href="/forgot-password"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  forgot password?
                </a>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <a
                    href="/follower-login"
                    style={{
                      color: "#3e7ca8",
                      textDecoration: "none",
                      marginRight: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Follower Login
                  </a>

                  <a
                    href="/business-login"
                    style={{
                      color: "#3e7ca8",
                      textDecoration: "none",
                      marginLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Business Login
                  </a>
                </div>
                {/* 
                <button
                  type="button"
                  className="btn solid"
                  onClick={() => setShowModal(true)}
                >
                  Choose Role
                </button> */}

                {/* <h3>Selected Role: {selectedRole}</h3> */}

                <input
                  type="submit"
                  value="Login"
                  className="btn solid"
                  onClick={handleLogin}
                />
              </form>

              <form action="#" className="sign-up-form auth__form">
                <h2 className="title">Sign up</h2>
                {/* <div className="input-field">
                  <i className="fas fa-user"></i>
                  <input type="text" placeholder="Username" />
                </div> */}

                <div className="input-field">
                  <i className="fas fa-envelope"></i>
                  <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="input-field">
                  <i className="fas fa-envelope"></i>
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                {/* <div className="input-field">
                  <input type="number" placeholder="Phone" />
                </div> */}
                <div className="input-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <span className="eye-icon" onClick={togglePasswordVisibility}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                {password !== "" && password.length < 8 && (
                  <p style={{ color: "red" }}>
                    password must not be less than 8
                  </p>
                )}

                <div className="input-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                {confirmPassword !== "" && confirmPassword !== password && (
                  <p style={{ color: "red" }}>please confirm your password</p>
                )}

                {selectedRole && (
                  <>
                    <div className="role-info">
                      <h3>Selected Role: {selectedRole}</h3>
                      {/* {selectedRole === "Influencer" && (
                    <div className="input-field">
                      <input type="text" placeholder="Enter ref code" />
                    </div>
                  )} */}
                      {selectedRole === "Follower" && (
                        <>
                          <p style={{ marginBottom: -2 }}>
                            Enter reference code received from influencer{" "}
                          </p>
                          <div className="input-field">
                            <input
                              type="text"
                              placeholder="Enter ref code"
                              required
                              value={refCode}
                              onChange={(e) => setRefCode(e.target.value)}
                            />
                          </div>
                        </>
                      )}
                      {selectedRole === "Business" && (
                        <>
                          <div className="input-field">
                            <input type="text" placeholder="Business Name" />
                          </div>

                          <div className="input-field">
                            <input type="text" placeholder="Business Address" />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
                {/* {!infRegMode && (
                  <button
                    type="button"
                    className="btn solid"
                    onClick={() => setShowModal(true)}
                  >
                    Choose Role
                  </button>
                )} */}

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {infRegMode ? (
                    <>
                      <a
                        href="/influencer-reg"
                        style={{
                          color: "#3e7ca8",
                          textDecoration: "none",
                          marginRight: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Register as an influencer
                      </a>

                      <a
                        href="/business-reg"
                        style={{
                          color: "#3e7ca8",
                          textDecoration: "none",
                          marginLeft: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Register as a business
                      </a>
                    </>
                  ) : (
                    <>
                      <a
                        href="/follower-reg"
                        style={{
                          color: "#3e7ca8",
                          textDecoration: "none",
                          marginRight: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Register as a follower
                      </a>

                      <a
                        href="/business-reg"
                        style={{
                          color: "#3e7ca8",
                          textDecoration: "none",
                          marginLeft: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Register as a business
                      </a>
                    </>
                  )}
                </div>

                {selectedRole == "Influencer" && (
                  <p style={{ width: "100%" }}>
                    <input
                      type="checkbox"
                      name="contractAgreement"
                      id="contractAgreement"
                      style={{ width: 20 }}
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />{" "}
                    I accept the contracts agreement as stated in{" "}
                    <a href="/contracts">contracts</a>
                  </p>
                )}

                {selectedRole == "Follower" &&
                refCode?.length > 0 &&
                confirmPassword === password ? (
                  <input
                    type="submit"
                    className="btn"
                    value="Sign up"
                    onClick={handleRegister}
                  />
                ) : selectedRole == "Influencer" &&
                  isChecked &&
                  confirmPassword === password ? (
                  <input
                    type="submit"
                    className="btn"
                    value="Sign up"
                    onClick={handleRegister}
                  />
                ) : (
                  selectedRole == "Business" &&
                  confirmPassword === password && (
                    <input
                      type="submit"
                      className="btn"
                      value="Sign up"
                      onClick={handleRegister}
                    />
                  )
                )}
              </form>
            </div>
          </div>

          <div className="panels-container">
            <div className="panel left-panel">
              <div className="content">
                <h3>New here ?</h3>
                <p>
                  Register now to experience the best from us. Our platform
                  offers a wide range of features and services designed to meet
                  your needs.
                </p>

                <button
                  className="btn transparent"
                  onClick={() => setIsSignUpMode(true)}
                >
                  Sign up
                </button>
              </div>
              {/* <img src="img/log.svg" className="auth image" alt="" /> */}
            </div>
            <div className="panel right-panel">
              <div className="content">
                <h3>One of us ?</h3>
                <p>
                  {" "}
                  Welcome back! Log in to access your personalized dashboard,
                  manage your account settings, and stay updated with the latest
                  features.
                </p>

                <button
                  className="btn transparent"
                  onClick={() => setIsSignUpMode(false)}
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>

          {showModal && (
            <div className="modal">
              <div className="modal-content">
                {/* <h2>Select Role</h2> */}
                <button onClick={() => handleRoleChange("Influencer")}>
                  Influencer
                </button>
                <button onClick={() => handleRoleChange("Follower")}>
                  Follower
                </button>
                <button onClick={() => handleRoleChange("Business")}>
                  Business
                </button>
                <button onClick={() => setShowModal(false)}>Close</button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Login;
