import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { ScrollToTopButton } from "../../App";
import DownloadApps from "../../components/apps/Apps";
import Clients from "../../components/clients/Clients";
import Contact from "../../components/contact/Contact";
import Faq from "../../components/faq/Faq";
import Features from "../../components/features/Features";
import Hero from "../../components/hero/Hero";
import Mission from "../../components/mission/Mission";
import Support from "../../components/support/Support";

const OFFSET = 60;

function Home() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const yOffset = -OFFSET;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <div>
      <Hero />
      <Mission />
      <Clients />
      <Features />
      <Faq />
      <Contact />
      <Support />
      <DownloadApps />
      <ScrollToTopButton />
    </div>
  );
}

export default Home;
