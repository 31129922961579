import React from "react";
import HeaderText from "../../components/headerText/HeaderText";

const users = [
  {
    name: "natalie_quinn",
    posts: 400,
    followers: 6000,
    status: "pro",
    socialIcons: ["instagram"],
    img: "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
  },
  {
    name: "coolfashion",
    posts: 1000,
    followers: 5900,
    status: "pro",
    socialIcons: ["instagram"],
    img: "https://img.freepik.com/free-photo/3d-illustration-teenager-with-funny-face-glasses_1142-50955.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
  },
  {
    name: "oceans_are_forever",
    posts: 400,
    followers: 40800,
    status: "pro",
    socialIcons: ["facebook"],
    img: "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
  },
  {
    name: "coolfashion",
    posts: 300,
    followers: 6000,
    status: "trial",
    socialIcons: ["instagram"],
    img: "https://img.freepik.com/free-photo/3d-illustration-teenager-with-funny-face-glasses_1142-50955.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
  },
  {
    name: "Vanderbloom",
    posts: 23000,
    followers: 34000,
    status: "advanced",
    socialIcons: ["instagram"],
    img: "https://img.freepik.com/free-photo/androgynous-avatar-non-binary-queer-person_23-2151100278.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
  },
  {
    name: "Vanderbloom",
    posts: 5500,
    followers: 500,
    status: "master",
    socialIcons: ["instagram"],
    img: "https://img.freepik.com/free-photo/androgynous-avatar-non-binary-queer-person_23-2151100278.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
  },
  {
    name: "oceans_are_forever",
    posts: 8000,
    followers: 20,
    status: "trial",
    socialIcons: ["facebook"],
    img: "https://img.freepik.com/free-photo/portrait-beautiful-girl-yellow-coat-with-backpack_1142-55171.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
  },
  {
    name: "chikah_chikah",
    posts: 2000,
    followers: 6000,
    status: "starter",
    socialIcons: ["instagram"],
    img: "https://img.freepik.com/free-photo/portrait-beautiful-girl-yellow-coat-with-backpack_1142-55171.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
  },
  {
    name: "oceans_are_forever",
    posts: 400,
    followers: 9000,
    status: "pro",
    socialIcons: ["facebook"],
    img: "https://img.freepik.com/free-photo/3d-portrait-businessman_23-2150793879.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
  },
];

const AllHires = () => {
  return (
    <div className="user-list-container">
      <HeaderText text={"Hired Influencers"} />
      <div className="user-grid">
        {users.map((user, index) => (
          <div key={index} className={`user-card ${user.status}`}>
            <div className="profile-image">
              <a href="/profile-influencer">
                <img src={user?.img} alt={user.name} />
              </a>
              {/* <div className="social-icons">
                {user.socialIcons.map((icon, index) => (
                  <span key={index} className={`icon-${icon}`}></span>
                ))}
              </div> */}
            </div>
            <div className="user-info">
              <a href="/profile-influencer">
                <h3>{user.name}</h3>
                <p>{user.posts}+ posts</p>
                <p className="followers">{user.followers} followers</p>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllHires;
