import React from "react";
import { slideInFromBottom } from "../../components/animations/useAnimations";
import "./ProposalAnalyticsCard.css";
import { motion } from "framer-motion";

const ProposalAnalyticsCard = ({ proposal }) => {
  const {
    description,
    budget,
    status,
    views,
    likes,
    companyName,
    image,
    type,
    index,
  } = proposal;

  return (
    <motion.div
      className={`proposal-analytics-card proposal-analytics-card--${status}`}
      variants={slideInFromBottom(index + 1 * 0.5)}
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.5, delay: 2 }}
    >
      {image && (
        <img
          src={image}
          alt={companyName}
          className="proposal-analytics-card__image"
        />
      )}
      <div className="proposal-analytics-card__content">
        <h2 className="proposal-analytics-card__company">{companyName}</h2>
        <p className="proposal-analytics-card__description">{description}</p>
        <p className="proposal-analytics-card__budget">Budget: {budget}</p>
        <p
          className={`proposal-analytics-card__status proposal-analytics-card__status--${status}`}
        >
          Status: {status}
        </p>
        <p className="proposal-analytics-card__views">Views: {views}</p>
        <p className="proposal-analytics-card__likes">Likes: {likes}</p>
        <p className="proposal-analytics-card__type">Type: {type}</p>
      </div>
    </motion.div>
  );
};

export default ProposalAnalyticsCard;
