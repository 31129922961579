import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  useMediaQuery,
  Rating,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { CloseIcon } from "../../assets/images/icons";

const ReviewModal = ({ open, onClose, onSubmit, loading }) => {
  const [reviewContent, setReviewContent] = useState("");
  const [rating, setRating] = useState(0);
  const isMobile = useMediaQuery("(max-width: 900px)");

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "80%" : 400,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6">Write a Review</Typography>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "text.secondary",
            zIndex: 99999,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Rating
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          sx={{ mb: 2 }} // Adds margin bottom to separate from the review text field
        />
        <TextField
          label="Your Review"
          multiline
          rows={4}
          fullWidth
          margin="normal"
          value={reviewContent}
          onChange={(e) => setReviewContent(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={() => {
            onSubmit({ reviewContent, rating });
            setReviewContent(""); // Clear the review content
            setRating(0); // Reset the rating
          }}
          fullWidth
        >
          {loading ? "Submitting..." : "Submit"}
        </Button>
      </Box>
    </Modal>
  );
};

export default ReviewModal;
