import React, { useRef } from "react";
import { useScaleAnimation } from "../animations/useCustomAnimation";
import "./HowWeWork.css";

const HowWeWork = () => {
  const scaleRef = useRef(null);
  //   useScaleAnimation(scaleRef, 3);

  return (
    <div className="how-we-work" ref={scaleRef}>
      <h4>How We Work</h4>
      <p>
        Thank you for trusting us with your business. You are at the right place
        for top-notch influencers.
      </p>
      <p>Search and hire influencer(s) who appeal to your interests.</p>
      <p>
        Select the service(s) you need to promote, whether it’s brand, product,
        or in need of an opinion poll.
      </p>
      <p>
        The organic influencer followers will engage with your promotion by
        rating, writing a review, and sharing your service request on other
        social media platforms for more exposure.
      </p>
      <p>
        You will then rate and review the service of the influencer after the
        task is completed.
      </p>
      <p>
        If you choose not to have followers engage with your service request,
        you are welcome to run an advertising campaign on our platform.
      </p>
    </div>
  );
};

export default HowWeWork;
