import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Avatar,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { publicUrl } from "../../axios";

const MessageList = ({ messages, onSelectMessage }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        width: isMobile ? "400px" : "300px",
        borderRight: "1px solid #ddd",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      {messages?.length > 0 ? (
        <List>
          {messages.map((message, index) => (
            <ListItem
              button
              key={index}
              onClick={() => onSelectMessage(message)}
            >
              <Avatar
                src={publicUrl + message?.business.image}
                alt={message?.business?.name}
                style={{ marginRight: 10 }}
              />
              <ListItemText
                primary={message?.business.name}
                secondary={
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {message?.message}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography style={{ margin: 10 }}>No messages</Typography>
      )}
    </Box>
  );
};

export default MessageList;
