import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { publicUrl } from "../../axios";
import axiosInstance from "../../axiosInstance";
import { useSlideInFromLeftAnimation } from "../../components/animations/useCustomAnimation";
import HeaderText from "../../components/headerText/HeaderText";
import LoadingComponent from "../../components/loading/LoadingComponent";
import DashboardTabs from "../../components/tabs/DashboardTabs";
import MainTab from "../../components/tabs/MainTab";
import SidePosts from "../posts/post-comp/side-posts/SidePosts";

const RejectedProposalCard = ({ proposal }) => {
  const slideInFromLeftRef = useRef(null);
  useSlideInFromLeftAnimation(slideInFromLeftRef, 1, 0.5);
  const { title, description, budget, posts, image } = proposal;
  return (
    <div className="proposal-card" ref={slideInFromLeftRef}>
      <img src={publicUrl + image} alt="" />

      <div className="proposal-details">
        <h3>{title}</h3>

        <p>{description}</p>

        <div className="extra-info">
          <span>Budget: {budget}</span>
          {/* <span>No. of posts: {posts}</span> */}
        </div>
      </div>
    </div>
  );
};

const proposals = [
  {
    title: "Frontend Developer for Website",
    description:
      "We are seeking a skilled frontend developer to assist with the HTML, CSS, and JavaScript for our website...",
    skills: ["HTML", "CSS", "JavaScript", "HTML5", "Web Development"],
    budget: "$50",
    location: "United States",
    proposals: "500+",
    avatarUrl:
      "https://img.freepik.com/free-psd/3d-illustration-human-avatar-profile_23-2150671142.jpg?semt=sph",
    posts: 3000,
  },
  {
    title: "Full Stack Developer for SaaS Platform",
    description:
      "We are a dynamic startup developing a cutting-edge SaaS platform that integrates various services and leverages modern technology...",
    skills: ["Python", "Next.js", "Azure", "JavaScript"],
    budget: "$30",
    location: "United States",
    proposals: "30+",
    avatarUrl:
      "https://img.freepik.com/free-photo/fashion-boy-with-yellow-jacket-blue-pants_71767-96.jpg?uid=R154714321&ga=GA1.1.1196110599.1720052268&semt=sph",
    posts: 200,
  },
  {
    title: "React Native Developer for Mobile App",
    description:
      "Looking for an experienced React Native developer to build and maintain our mobile applications...",
    skills: [
      "React Native",
      "JavaScript",
      "Mobile Development",
      "API Integration",
    ],
    budget: "$40",
    location: "Canada",
    proposals: "100+",
    avatarUrl:
      "https://img.freepik.com/free-photo/portrait-young-smiling-man-using-smartphone-against-yellow-background_171337-10648.jpg?semt=sph",
    posts: 150,
  },
  {
    title: "UI/UX Designer for E-commerce Website",
    description:
      "Seeking a talented UI/UX designer to create a user-friendly and visually appealing interface for our e-commerce website...",
    skills: ["UI/UX Design", "Figma", "Adobe XD", "User Research"],
    budget: "$60",
    location: "United Kingdom",
    proposals: "200+",
    avatarUrl:
      "https://img.freepik.com/free-photo/smiling-woman-with-short-curly-hair-wearing-yellow-shirt_171337-7055.jpg?semt=sph",
    posts: 450,
  },
  {
    title: "Backend Developer for FinTech Application",
    description:
      "We need a skilled backend developer to work on our FinTech application, focusing on security and performance...",
    skills: ["Node.js", "Express", "MongoDB", "AWS"],
    budget: "$70",
    location: "Australia",
    proposals: "80+",
    avatarUrl:
      "https://img.freepik.com/free-photo/close-up-smiley-man-sitting-home-sofa_23-2148889982.jpg?semt=sph",
    posts: 120,
  },
];

const Rejected = () => {
  const [rejected, setRejected] = useState([]);
  const userDetails = useSelector((state) => state.user.user);
  const userRole = useSelector((state) => state.user.role);
  const [loading, setLoading] = useState(true);

  const influencerRejectedProposal = `/influencer/hires/rejected/user/${userDetails?.id}`;
  const businessRejectedProposals = `/business/hires/rejected/business/${userDetails?.id}`;

  const rejectedProposals = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        userRole == "influencer"
          ? influencerRejectedProposal
          : businessRejectedProposals
      );
      setRejected(res.data?.hires);
      setLoading(false);
    } catch (error) {
      //console.log(error);
      // setLoading(false);
    }
  };

  useEffect(() => {
    rejectedProposals();
  }, []);

  return (
    <>
      {/* <MainTab activeTab={"rejected-proposals"} /> */}
      <div className="proposal__heading">rejected proposals</div>

      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="proposal__container">
          <SidePosts />

          <div className="proposal-page">
            {rejected?.length > 0 ? (
              rejected.map((proposal, index) => (
                <RejectedProposalCard key={index} proposal={proposal} />
              ))
            ) : (
              <b>No data found</b>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Rejected;
