import React, { useEffect, useState } from "react";
import { Box, Avatar } from "@mui/material";
import axiosInstance from "../../../../axiosInstance";
import { publicUrl } from "../../../../axios";

// const users = [
//   {
//     username: "lawrence",
//     avatar:
//       "https://img.freepik.com/free-photo/young-african-american-woman-wearing-casual-clothes-inviting-enter-smiling-natural-with-open-hand_839833-13899.jpg?uid=R27806234&ga=GA1.1.1780381790.1721057597&semt=sph",
//   },
//   {
//     username: "mercychinwo",
//     avatar:
//       "https://img.freepik.com/free-photo/pretty-self-confident-female-model-with-afro-hair-keeps-hand-raised-smiles-gently-looks-directly-listens-attentively-interlocutor-wears-stylish-velvet-jacket_273609-46286.jpg?uid=R27806234&ga=GA1.1.1780381790.1721057597&semt=sph",
//   },
//   {
//     username: "ministerguc",
//     avatar:
//       "https://img.freepik.com/free-photo/young-handsome-man-wearing-casual-tshirt-blue-background-happy-face-smiling-with-crossed-arms-looking-camera-positive-person_839833-12963.jpg?uid=R27806234&ga=GA1.1.1780381790.1721057597&semt=sph",
//   },
//   {
//     username: "adaehimo",
//     avatar:
//       "https://img.freepik.com/free-photo/portrait-modern-man_23-2147960990.jpg?uid=R27806234&ga=GA1.1.1780381790.1721057597&semt=sph",
//   },
//   {
//     username: "dunsinoyekan",
//     avatar:
//       "https://img.freepik.com/free-photo/portrait-cool-man-with-sunglasses-dancing_23-2148851011.jpg?uid=R27806234&ga=GA1.1.1780381790.1721057597&semt=sph",
//   },
//   {
//     username: "victoriaorenze",
//     avatar:
//       "https://img.freepik.com/free-photo/thin-dark-skinned-woman_273609-51894.jpg?uid=R27806234&ga=GA1.1.1780381790.1721057597&semt=sph",
//   },
//   {
//     username: "chidinmaekile",
//     avatar:
//       "https://img.freepik.com/free-photo/excited-upbeat-attractive-male-earrings-t-shirt-holding-palms-jaw-gasping-starring-with-popped-eyes-with-disbelief_176420-24908.jpg?uid=R27806234&ga=GA1.1.1780381790.1721057597&semt=sph",
//   },
//   {
//     username: "preyoedede",
//     avatar:
//       "https://img.freepik.com/free-photo/portrait-young-school-girl_23-2151031867.jpg?uid=R27806234&ga=GA1.1.1780381790.1721057597&semt=sph",
//   },
// ];

const PostHeaders = () => {
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    try {
      const res = await axiosInstance.get("/business/followings");
      setUsers(res.data.followings);
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "auto",

        p: 1,
        mb: 2,
        "&::-webkit-scrollbar": { display: "none" },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
      }}
    >
      {users.map((user, index) => (
        <Box
          key={index}
          sx={{ mx: 1, textAlign: "center" }}
          onClick={() => (window.location.href = `/profile/${user?.id}`)}
        >
          <Avatar
            alt={user.username}
            src={publicUrl + user.image}
            sx={{ width: 56, height: 56, mb: 0.5, cursor: "pointer" }}
          />
          <Box component="span" sx={{ fontSize: 12, fontWeight: "bold" }}>
            {user?.name?.slice(0, 5) + "..."}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default PostHeaders;
