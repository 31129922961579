import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  Rating,
  IconButton,
  Button, // Import Button
} from "@mui/material";
import { useSpring, animated } from "@react-spring/web";
import ShareIcon from "@mui/icons-material/Share";
import { useParams } from "react-router";
import axiosInstance from "../../axiosInstance";
import LoadingComponent from "../../components/loading/LoadingComponent";
import ShareReview from "../../components/modal/ShareReview"; // Ensure this is imported
import { publicUrl } from "../../axios";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas"; // Import html2canvas

export function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

const SingleReviewPage = () => {
  const user = useSelector((state) => state.user.user);
  const userRole = useSelector((state) => state.user.role);
  const { id } = useParams();
  const [review, setReview] = useState([]);
  const [loading, setLoading] = useState(true);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  function hasFollowerInURL() {
    const currentURL = window.location.href;
    return currentURL.toLowerCase().includes("follower");
  }

  const containsFollower = hasFollowerInURL();

  const currentUrl = "https://topnotchinfluencer.com/";

  const handleOpenShareModal = () => setShareModalOpen(true);
  const handleCloseModals = () => {
    setShareModalOpen(false);
  };

  const getReview = async () => {
    const getInfluencerReviewList1 = `influencer/reviews/show-review/${id}`;
    const getInfluencerReviewList12 = `influencer/reviews/show-business-review/${id}`;
    const getFollowerReviewList = `follower/reviews/show-review/${id}`;
    const getBusinessReviewList = `business/reviews/show-inf-review/${id}`;

    let res;

    if (userRole == "influencer") {
      res = await axiosInstance.get(getInfluencerReviewList12);
    }

    if (containsFollower) {
      res = await axiosInstance.get(getInfluencerReviewList1);
    }

    if (userRole == "business") {
      res = await axiosInstance.get(getBusinessReviewList);
    }

    if (userRole == "follower") {
      res = await axiosInstance.get(getFollowerReviewList);
    }
    setReview(res?.data);
    setLoading(false);
  };

  useEffect(() => {
    getReview();
  }, []);

  const fadeInSpring = useSpring({
    opacity: 1,
    transform: "translateY(0)",
    from: { opacity: 0, transform: "translateY(20px)" },
    config: { tension: 280, friction: 60 },
  });

  const downloadPDF = () => {
    const content = document.querySelector(".download-content"); // Select the content
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("review.pdf");
    });
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ mb: 2, alignSelf: "flex-end", ml: 10 }}
        onClick={downloadPDF}
      >
        Download as PDF
      </Button>
      <div className="single-review__container">
        {loading ? (
          <LoadingComponent />
        ) : (
          <Box
            className="download-content"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
              p: 2,
            }}
          >
            <animated.div style={fadeInSpring}>
              <Card
                elevation={3}
                sx={{
                  backgroundColor: "#f9f9f9",
                  borderLeft: "4px solid #3e7ca8",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Avatar
                    src={
                      (review.follower?.image &&
                        publicUrl + review.follower.image) ||
                      (review.business?.image &&
                        publicUrl + review.business.image) ||
                      (review.user?.image && publicUrl + review.user.image) ||
                      publicUrl + "default-image.png"
                    }
                    alt={review.name}
                    sx={{
                      width: 56,
                      height: 56,
                      mr: 2,
                    }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#3e7ca8",
                        fontWeight: "500",
                      }}
                    >
                      {review?.follower?.name ||
                        review?.business?.name ||
                        review?.user?.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ fontStyle: "italic" }}
                    >
                      {formatDate(review?.created_at)}
                    </Typography>
                    <Rating
                      value={review?.rating || 0}
                      readOnly
                      sx={{ mt: 1, mb: 1, color: "#f8b400" }}
                    />
                    <Typography variant="body1" sx={{ color: "#555" }}>
                      {review?.comment}
                    </Typography>
                  </Box>
                  {!review?.user && (
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#3e7ca8",
                      }}
                    >
                      Type: {review?.follower ? "Follower" : "Business"}
                    </Typography>
                  )}
                  {user && (
                    <IconButton
                      onClick={handleOpenShareModal}
                      sx={{ position: "absolute", top: 10, right: 10 }}
                    >
                      <ShareIcon />
                    </IconButton>
                  )}
                </CardContent>
              </Card>
            </animated.div>
          </Box>
        )}
        <ShareReview
          open={shareModalOpen}
          handleClose={handleCloseModals}
          review={review}
          shareUrl={currentUrl}
        />
      </div>
    </>
  );
};

export default SingleReviewPage;
