import React, { useState, useRef, useEffect } from "react";
import { useSlideInFromBottomAnimation } from "../../components/animations/useCustomAnimation";
import { motion } from "framer-motion";
import {
  framerAnimation,
  slideInFromRight,
} from "../../components/animations/useAnimations";
import "./Hire.css";
import UserModal from "../../components/modal/UserModal";
import axiosInstance from "../../axiosInstance";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import { useNavigate, useParams, useRoutes } from "react-router";
import Alerts from "../../components/alerts/Alerts";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";

const SpecificHire = () => {
  const { id } = useParams();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [promotionType, setPromotionType] = useState("");
  const [pollOptions, setPollOptions] = useState([
    { title: "", description: "" },
  ]);

  const [user, setUser] = useState({});

  const [title, setTitle] = useState("");
  const [budget, setBudget] = useState(0);
  const [description, setDescription] = useState("");
  const [influencerID, setInfluencerID] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [media, setMedia] = useState(null);
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);
  const influencerEndpoint = `/influencer/user/${id}`;

  const profileDetails = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(influencerEndpoint);
      setUser(res.data?.user_profile);
      setLoading(false);
      console.log("inf profile", user);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const getUserDetails = async () => {
    try {
      const res = await axiosInstance.get("/business/get-profile");
      setUserDetails(res.data.user_profile);
    } catch (error) {}
  };

  useEffect(() => {
    getUserDetails();
    profileDetails();
  }, []);

  const slideInFromBottomRef = useRef(null);
  useSlideInFromBottomAnimation(slideInFromBottomRef, 1, 0);

  const [influencers, setInfluencers] = useState([]);

  const getInfluencers = async () => {
    try {
      const res = await axiosInstance.get("/business/influencers/list");
      setInfluencers(res.data);
    } catch (error) {
      //console.log("error", error);
    }
  };

  useEffect(() => {
    getInfluencers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    try {
      formData.append("title", title);
      formData.append("description", description);
      formData.append("budget", budget);
      formData.append("post_type", promotionType);
      formData.append("user_id", user?.id);

      if (selectedImage) {
        formData.append("image", selectedImage);
      }

      if (selectedVideo) {
        formData.append("video", selectedVideo);
      }

      const res = await axiosInstance.post("/business/hires", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          console.log(`Upload Progress: ${percent}%`);

          // Optionally, update a state variable to show progress in the UI
          setUploadProgress(percent);
        },
      });

      if (res) {
        setAlerts({ message: "Proposal sent successfully", type: "success" });
        navigate("/analytics");
      }

      setLoading(false);
    } catch (error) {
      let message = error.message; // Default to the general error message

      // Extract specific error messages if available
      const errorMessages = error.response?.data?.errors;
      if (errorMessages) {
        if (Array.isArray(errorMessages)) {
          // Handle array of error messages
          message = errorMessages
            .map((err) => `${err.field}: ${err.message}`)
            .join(" | ");
        } else if (typeof errorMessages === "object") {
          // Handle object of error messages
          message = Object.keys(errorMessages)
            .map((key) => `${key}: ${errorMessages[key].join(", ")}`)
            .join(" | ");
        }
      }

      setAlerts({ message, type: "error" });
      setLoading(false);
    }
  };

  const handleInsufficientSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("title", title);
    formData.append("description", description);
    formData.append("budget", budget);
    formData.append("post_type", promotionType);
    formData.append("user_id", influencerID);

    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    if (selectedVideo) {
      formData.append("video", selectedVideo);
    }

    // To log the FormData content
    for (let [key, value] of formData.entries()) {
      //console.log(`${key}: ${value}`);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setMedia(URL.createObjectURL(file));
    }
  };

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedVideo(file);
      setMedia(URL.createObjectURL(file));
    }
  };

  const handleAddOption = () => {
    setPollOptions([...pollOptions, { title: "", description: "" }]);
  };

  const handleRemoveOption = (index) => {
    setPollOptions(pollOptions.filter((_, i) => i !== index));
  };

  const handleSelectInfluencer = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };
  useEffect(() => {
    if (influencerID !== null) {
      //console.log("Influencer ID Updated:", influencerID);
    }
  }, [influencerID]);

  return (
    <>
      {loading ? (
        <LoadingComponent progress={uploadProgress} />
      ) : (
        <div className="create-proposal-page">
          {alerts && <Alerts message={alerts.message} type={alerts?.type} />}

          <motion.div
            className="create-proposal-page__left"
            variants={framerAnimation(0)}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5, delay: 2 }}
          >
            <div className="create-proposal-page__branding-icon">🔵</div>
            <h1 className="create-proposal-page__title">Proposal form</h1>
            <p className="create-proposal-page__description">
              Write and submit a proposal to this influencer
            </p>
            <div className="create-proposal-page__testimonial">
              <div className="create-proposal-page__testimonial-author">
                <img
                  className="create-proposal-page__testimonial-avatar"
                  src="https://img.freepik.com/free-vector/strategic-consulting-concept-illustration_114360-9336.jpg?uid=R154714321&ga=GA1.1.1196110599.1720052268&semt=sph"
                  alt="proposal image"
                />
              </div>
            </div>
          </motion.div>

          <motion.div
            className="create-proposal-page__right"
            variants={slideInFromRight(0.5)}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5, delay: 2 }}
          >
            <h2 className="create-proposal-page__form-title">
              Submit Your Proposal
            </h2>
            <form className="create-proposal-page__form">
              <div className="create-proposal-page__form-group">
                <label
                  className="create-proposal-page__form-label"
                  htmlFor="promotionType"
                >
                  Promotion Type
                </label>
                <select
                  className="create-proposal-page__form-input"
                  id="promotionType"
                  value={promotionType}
                  onChange={(e) => setPromotionType(e.target.value)}
                >
                  <option value="">Select Promotion Type</option>
                  <option value="image">Picture</option>
                  <option value="video">Video</option>
                  <option value="Opinion Poll">Opinion Poll</option>
                </select>
              </div>

              <div className="create-proposal-page__form-group">
                <label
                  className="create-proposal-page__form-label"
                  htmlFor="title"
                >
                  Title
                </label>
                <input
                  className="create-proposal-page__form-input"
                  type="text"
                  id="title"
                  placeholder="Enter proposal title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="create-proposal-page__form-group">
                <label
                  className="create-proposal-page__form-label"
                  htmlFor="amount"
                >
                  Budget($)
                </label>
                <input
                  className="create-proposal-page__form-input"
                  type="number"
                  id="amount"
                  placeholder="Enter amount"
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                />
              </div>

              <div className="create-proposal-page__form-group">
                <label
                  className="create-proposal-page__form-label"
                  htmlFor="description"
                >
                  Description
                </label>
                <textarea
                  className="create-proposal-page__form-input"
                  id="description"
                  placeholder="Enter description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <p className="create-proposal-page__user-dropdown">
                {user?.name}
              </p>

              {promotionType === "image" && (
                <div className="create-proposal-page__form-group">
                  <label
                    className="create-proposal-page__form-label"
                    htmlFor="media"
                  >
                    Select Image
                  </label>
                  <input
                    className="create-proposal-page__form-input"
                    type="file"
                    id="media"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  {media && (
                    <img
                      src={media}
                      alt="Selected"
                      className="create-proposal-page__media-preview"
                    />
                  )}
                </div>
              )}

              {promotionType === "video" && (
                <div className="create-proposal-page__form-group">
                  <label
                    className="create-proposal-page__form-label"
                    htmlFor="media"
                  >
                    Select Video ( maximum of 20 mb. )
                  </label>
                  <input
                    className="create-proposal-page__form-input"
                    type="file"
                    id="media"
                    accept="video/*"
                    onChange={handleVideoUpload}
                  />
                  {media && (
                    <video
                      src={media}
                      controls
                      className="create-proposal-page__media-preview"
                    />
                  )}
                </div>
              )}

              {promotionType === "poll" && (
                <div className="create-proposal-page__form-group">
                  <label
                    className="create-proposal-page__form-label"
                    htmlFor="pollTitle"
                  >
                    Poll Title
                  </label>
                  <input
                    className="create-proposal-page__form-input"
                    type="text"
                    id="pollTitle"
                    placeholder="Enter poll title"
                  />
                  {pollOptions.map((option, index) => (
                    <div
                      key={index}
                      className="create-proposal-page__form-group"
                    >
                      <label
                        className="create-proposal-page__form-label"
                        htmlFor={`option${index}`}
                      >
                        Option {index + 1}
                      </label>
                      <input
                        className="create-proposal-page__form-input"
                        type="text"
                        id={`option${index}`}
                        placeholder="Enter option title"
                        value={option.title}
                        onChange={(e) =>
                          setPollOptions((prevOptions) => {
                            const newOptions = [...prevOptions];
                            newOptions[index].title = e.target.value;
                            return newOptions;
                          })
                        }
                      />
                      <input
                        className="create-proposal-page__form-input"
                        type="text"
                        id={`optionDescription${index}`}
                        placeholder="Enter option description"
                        value={option.description}
                        onChange={(e) =>
                          setPollOptions((prevOptions) => {
                            const newOptions = [...prevOptions];
                            newOptions[index].description = e.target.value;
                            return newOptions;
                          })
                        }
                      />
                      <button
                        type="button"
                        className="create-proposal-page__remove-option"
                        onClick={() => handleRemoveOption(index)}
                      >
                        Remove Option
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="create-proposal-page__add-option"
                    onClick={handleAddOption}
                  >
                    Add Option
                  </button>
                </div>
              )}

              {budget !== 0 && (
                <>
                  <button
                    className="create-proposal-page__submit-button"
                    type="submit"
                    onClick={
                      budget !== "" &&
                      budget > 0 &&
                      parseFloat(userDetails?.wallet_amount) >=
                        parseFloat(budget)
                        ? handleSubmit
                        : handleInsufficientSubmit
                    }
                  >
                    {budget !== "" &&
                    budget > 0 &&
                    parseFloat(userDetails?.wallet_amount) >= parseFloat(budget)
                      ? "Submit Proposal"
                      : "Insufficient funds"}
                  </button>

                  {budget !== "" &&
                    budget > 0 &&
                    parseFloat(userDetails?.wallet_amount) <=
                      parseFloat(budget) && (
                      <a
                        style={{
                          textDecoration: "none",
                          textAlign: "center",
                          color: "#3e7ca8",
                          fontWeight: 400,
                        }}
                        href="/add-funds"
                      >
                        Add funds to digital wallet
                      </a>
                    )}
                </>
              )}
            </form>
          </motion.div>
        </div>
      )}
      {isModalOpen && (
        <UserModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          users={influencers}
          onSelectUser={(user) => {
            setSelectedUser(user);
            setInfluencerID(user?.id);
            setModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default SpecificHire;
