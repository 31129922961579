import React, { useRef } from "react";
import {
  KeyboardArrowRightIcon,
  ManageSearchIcon,
  PixIcon,
  ShareIcon,
  StackedBarChartIcon,
  TimelineIcon,
} from "../../assets/images/icons";
import { useSlideInFromLeftAnimation } from "../animations/useCustomAnimation";
import "./Features.css";

function Features() {
  const slideInFromLeftRef = useRef(null);
  useSlideInFromLeftAnimation(slideInFromLeftRef, 1, 0.5);

  return (
    <div id="features">
      <section class="features-section" data-aos="fade-down">
        <h1 class="half-border-header">Top Notch Influencer Features</h1>
        <p class="features__sub">
          Unlock the power of influencer marketing to drive real results for
          your business.
        </p>
        <div class="features-grid">
          <div class="feature">
            <div class="icon">
              <ShareIcon style={{ color: "#3e7ca8" }} />
            </div>
            <h3>Connecting Brands with Trusted Influencers</h3>
            <p>
              Leverage our extensive network of top-tier influencers to amplify
              your brand's reach and drive real results.
            </p>
            {/* <a href="/">
              Read More
              <KeyboardArrowRightIcon />
            </a> */}
          </div>
          <div class="feature">
            <div class="icon">
              <ManageSearchIcon style={{ color: "#3e7ca8" }} />
            </div>
            <h3>Influencer Identification</h3>
            <p>
              Our team of experts will carefully select the right influencers to
              align with your brand and target audience.
            </p>
            {/* <a href="/">
              Read More
              <KeyboardArrowRightIcon />
            </a> */}
          </div>
          <div class="feature">
            <div class="icon">
              <TimelineIcon style={{ color: "#3e7ca8" }} />
            </div>
            <h3>Campaign Management</h3>
            <p>
              We handle all aspects of your influencer marketing campaigns, from
              content creation to performance tracking.
            </p>
            {/* <a href="/">
              Read More
              <KeyboardArrowRightIcon />
            </a> */}
          </div>
          <div class="feature">
            <div class="icon">
              <StackedBarChartIcon style={{ color: "#3e7ca8" }} />
            </div>
            <h3>Proven Results</h3>
            <p>
              Our data-driven approach ensures that your influencer marketing
              campaigns deliver measurable results and a strong ROI.
            </p>
            {/* <a href="/">
              Read More
              <KeyboardArrowRightIcon />
            </a> */}
          </div>
          <div class="feature">
            <div class="icon">
              <PixIcon style={{ color: "#3e7ca8" }} />
            </div>
            <h3>High-Quality Content Creation</h3>
            <p>
              We specialize in crafting engaging and high-quality content that
              resonates with your audience, driving traffic and conversions.
            </p>
            {/* <a href="/">
              Read More
              <KeyboardArrowRightIcon />
            </a> */}
          </div>
          <div class="feature">
            <div class="icon">
              <ShareIcon style={{ color: "#3e7ca8" }} />
            </div>
            <h3>Advanced Analytics</h3>
            <p>
              Leverage our advanced analytics to gain insights into your
              audience, campaign performance, and market trends, enabling
              data-driven decision making.
            </p>
            {/* <a href="/">
              Read More
              <KeyboardArrowRightIcon />
            </a> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Features;
