import React from "react";
import CongratulationPopup from "../../components/modal/CongratulationPopup";
import "./NewPostFeeds.css";
import PostHeaders from "./post-comp/header-avatars/PostHeaders";
import MainFeeds from "./post-comp/main-posts/MainFeeds";
import RightSidePosts from "./post-comp/side-posts/RightSidePosts";
import SidePosts from "./post-comp/side-posts/SidePosts";

function NewPostFeeds() {
  return (
    <div className="new__post__feeds__container">
      <SidePosts />
      <CongratulationPopup />
      <MainFeeds />
      <RightSidePosts />
    </div>
  );
}

export default NewPostFeeds;
