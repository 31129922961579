import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import IconButton from "@mui/material/IconButton";

const ShareModal = ({ open, handleClose, reviewText, shareUrl }) => {
  // const shareUrl = encodeURIComponent("https://yourwebsite.com"); // Replace with the actual URL
  const text = encodeURIComponent(reviewText);

  const socialMediaPlatforms = [
    {
      name: "Facebook",
      icon: <FacebookIcon />,
      url: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${text}`,
    },
    {
      name: "Twitter",
      icon: <TwitterIcon />,
      url: `https://twitter.com/intent/tweet?url=${shareUrl}&text=${text}`,
    },
    {
      name: "Instagram",
      icon: <InstagramIcon />,
      url: `https://www.instagram.com/`, // Instagram sharing is limited
    },
    {
      name: "LinkedIn",
      icon: <LinkedInIcon />,
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`,
    },
  ];

  const handleShare = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>
        Share this review
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {socialMediaPlatforms.map((platform) => (
            <Button
              key={platform.name}
              startIcon={platform.icon}
              sx={{
                textTransform: "none",
                justifyContent: "flex-start",
                width: "100%",
                mb: 1,
              }}
              onClick={() => handleShare(platform.url)}
            >
              {platform.name}
            </Button>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareModal;
