import React from "react";
import { L1, L2, L3, L4, L5 } from "../../assets/images/Images";
import "./Clients.css";

const Clients = () => {
  const clientLogos = [L1, L2, L3, L4, L5];

  return (
    <div id="client">
      <section className="clients-section" data-aos="fade-up">
        <h2>Clients are our most important assets.</h2>
        <p>
          We have the best clients in the market who thrive us to perform
          better.
        </p>
        <div className="client-logos">
          {clientLogos.map((logo, index) => (
            <div key={index} className="client-logo">
              <img src={logo} />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Clients;
