import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import axiosInstance from "../../axiosInstance";

const CongratulationPopup = () => {
  const [open, setOpen] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);

  useEffect(() => {
    // Fetch follower count from the API
    const fetchFollowerCount = async () => {
      try {
        const response = await axiosInstance.get("/influencer/follower/count");
        const count = response.data.follower_count;
        setFollowerCount(count);

        // Check if the follower count has reached or exceeded 1,000
        const lastMilestone =
          parseInt(localStorage.getItem("lastMilestone"), 10) || 0;
        const newMilestone = Math.floor(count / 1000) * 1000;

        if (count >= 1000 && newMilestone > lastMilestone) {
          setOpen(true);
          localStorage.setItem("lastMilestone", newMilestone);
        }
      } catch (error) {
        console.error("Failed to fetch follower count:", error);
      }
    };

    fetchFollowerCount();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={(props) => (
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.5, opacity: 0 }}
          transition={{ duration: 0.5 }}
          style={{
            backgroundColor: "#3e7ca8",
            borderRadius: "12px",
            color: "white",
          }}
          {...props}
        />
      )}
      aria-labelledby="congratulation-dialog"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="congratulation-dialog" style={{ textAlign: "center" }}>
        🎉 Congratulations! 🎉
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom style={{ textAlign: "center" }}>
          You’ve reached {followerCount} followers!
        </Typography>
        <Typography
          variant="body2"
          style={{ textAlign: "center", color: "white" }}
        >
          Keep up the great work and continue to grow your audience.
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CongratulationPopup;
