import { FollowTheSigns, ReviewsOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { EmailIcon } from "../../assets/images/icons";
import { Avatar } from "../../assets/images/Images";
import { publicUrl } from "../../axios";
import axiosInstance from "../../axiosInstance";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import {
  useRotate360Animation,
  useScaleAnimation,
} from "../../components/animations/useCustomAnimation";
import FollowersList from "../../components/followers_list/FollowersList";
import LoadingComponent from "../../components/loading/LoadingComponent";
import ReviewModal from "../../components/modal/ReviewModal";
import { logout } from "../../redux/store/userSlice";
import SidePosts from "../posts/post-comp/side-posts/SidePosts";
import "./Profile.css";

const isOwner = true;

const OtherProfile = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [isFollowing, setIsFollowing] = useState(false);

  const influencerEndpoint = `/influencer/user/${id}`;
  const isBusiness = useSelector((state) => state.user?.role === "business");
  const userRole = useSelector((state) => state.user?.role);

  const businessReview = "/business/reviews/create";
  const followerReview = "/follower/reviews/create";

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  // This function checks if the user is in the followings list
  const checkIfFollowing = async () => {
    try {
      const response = await axiosInstance.get("/business/followings");
      const followings = response.data?.followings;

      if (followings) {
        const isFollowing = followings.some(
          (following) => following.id === parseInt(id)
        );
        setIsFollowing(isFollowing);
      }
    } catch (error) {
      //console.error("Error checking follow status:", error);
      // toast.error("Error checking follow status");
    }
  };

  const handleReviewSubmit = async (reviewContent) => {
    setLoading(true);

    try {
      const payload = {
        user_id: id,
        rating: reviewContent.rating,
        comment: reviewContent.reviewContent,
      };

      //console.log(payload);

      const res = await axiosInstance.post(
        userRole == "business" ? businessReview : followerReview,

        payload
      );

      //console.log(res.data?.review);

      if (res.status == 201) {
        toast.success(res.data.message);
        setIsReviewModalOpen(false);
      }
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
      toast.error(error.response?.data?.message);
      setIsReviewModalOpen(false);
    }
  };

  const handleFollowUser = async () => {
    setLoading(true);

    try {
      if (isFollowing) {
        const res = await axiosInstance.post(`/business/unfollow/${id}`);
        toast.success(res.data?.message);
        setLoading(false);
        setIsFollowing(!isFollowing);
      } else {
        const res = await axiosInstance.post(`/business/follow/${id}`);
        toast.success(res.data?.message);
        setLoading(false);
        setIsFollowing(!isFollowing);
      }
    } catch (error) {
      //console.log(error);
      toast.error("Error! could not follow influencer");
      setLoading(false);
    }
  };

  const profileDetails = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(influencerEndpoint);
      setUser(res.data?.user_profile);
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    profileDetails();
    checkIfFollowing();
  }, []);

  //console.log("is following", isFollowing);

  const rotate360Ref = useRef(null);
  const scaleRef = useRef(null);
  useScaleAnimation(scaleRef, 3);
  useRotate360Animation(rotate360Ref, 2, 0);

  const maskDetails = (detail, visibleLength = 2) => {
    if (detail) {
      return (
        detail.slice(0, visibleLength) +
        "*".repeat(detail.length - visibleLength)
      );
    }
    return "";
  };

  // if (loading) {
  //   return <LoadingComponent />;
  // }

  return (
    <div style={{ display: "flex" }}>
      <SidePosts />

      {loading && <LoadingComponent />}
      <div className="profile-card profile">
        <div className="left-section">
          <div className="profile-picture">
            <img
              ref={rotate360Ref}
              src={user?.image ? publicUrl + user?.image : Avatar}
              alt="Profile"
            />
          </div>
          <div className="work-info">
            <h3>Info</h3>
            <p>
              {/* <strong>Wallet</strong> */}
              {/* <span className="work-tag">{user?.wallet_amount}</span> */}
            </p>
            <p>
              <br /> Address: {maskDetails(user?.address) || "N/A"} <br />{" "}
              Phone: {maskDetails(user?.phone, 3) || "N/A"}
              <br />
              {isBusiness && (
                <>
                  <a
                    href={`/chats/${id}`}
                    style={{ color: "#3e7ca8", textDecoration: "none" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <b>Send Message</b>
                      <EmailIcon fontSize="14px" />
                    </div>
                  </a>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      color: "green",
                      marginTop: 3,
                    }}
                    onClick={handleFollowUser}
                  >
                    <b>
                      {isFollowing
                        ? "Unfollow influencer"
                        : "Follow influencer"}
                    </b>
                    <FollowTheSigns fontSize="14px" />
                  </div>
                </>
              )}
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="profile-details">
            <h2>{user?.name}</h2>
            <p className="job-title">Influencer</p>
            <p className="location">{maskDetails(user?.address) || "N/A"}</p>
            <div className="rating">
              <span className="ff">{user?.follower_count}</span>
              <span className="stars">
                {user?.follower_count > 1 ? "followers" : "follower"}
              </span>
            </div>
            <div className="actions">
              <button
                className="primary-btn"
                onClick={() => (window.location.href = "/posts")}
              >
                <a
                  href="/posts"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  See Posts
                </a>
              </button>
              <button
                className="primary-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsReviewModalOpen(!isReviewModalOpen);
                }}
              >
                Drop a review
              </button>

              {isBusiness && user?.follower_count > 5000 && (
                <button className="secondary-btn">
                  <a
                    href={`/hire/${user?.id}`}
                    style={{ textDecoration: "none", color: "#3e7ca8" }}
                  >
                    Hire influencer
                  </a>
                </button>
              )}
            </div>
          </div>
          <div className="profile-info">
            <div className="section">
              <h3>Contact Information</h3>
              <p>
                <strong>Phone:</strong> {maskDetails(user?.phone, 3) || "N/A"}
              </p>
              <p>
                <strong>Address:</strong>
                {maskDetails(user?.address) || "N/A"}
              </p>
              <p>
                <strong>Email:</strong>
                {maskDetails(user?.email, 3) || "N/A"}
              </p>
              <p>
                <strong>Site:</strong> {user?.website || "N/A"}
              </p>
            </div>
            <div className="section">
              <h3>Basic Information</h3>
              <p>
                <strong>Birthday:</strong>{" "}
                {maskDetails(user?.birthday) || "N/A"}
              </p>
              <p>
                <strong>Gender:</strong> {user?.gender || "N/A"}
              </p>
            </div>
          </div>
        </div>
        <ReviewModal
          open={isReviewModalOpen}
          onClose={() => setIsReviewModalOpen(false)}
          onSubmit={handleReviewSubmit}
          loading={loading}
        />

        <div className="followers__sections">{/* <FollowersList /> */}</div>
      </div>
    </div>
  );
};

export default OtherProfile;
