import React, { useRef } from "react";
import { useSelector } from "react-redux";
import Alerts from "../../components/alerts/Alerts";
import ScrollingText from "../../components/animations/ScrollingText";
import { useZoomInAnimation } from "../../components/animations/useCustomAnimation";
import MainTab from "../../components/tabs/MainTab";
import SidePosts from "../posts/post-comp/side-posts/SidePosts";
import ProfileCard from "./ProfileCard";

function Profile() {
  const zoomInRef = useRef(null);
  useZoomInAnimation(zoomInRef, 1.5, 1);

  const user = useSelector((state) => state.user.user);

  //console.log("user", user);

  return (
    <div style={{ display: "flex" }}>
      <div className="profile__main-sidebar">
        <SidePosts />
      </div>
      <div className="profile__main">
        {/* <MainTab activeTab={"profile"} /> */}

        <ProfileCard />
      </div>
    </div>
  );
}

export default Profile;
