import React, { useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { CheckCircleIcon } from "../../assets/images/icons";
import {
  Ambassador,
  Customize,
  M1,
  M2,
  M3,
  M4,
} from "../../assets/images/Images";
import {
  useRotate360Animation,
  useSlideInFromLeftAnimation,
} from "../animations/useCustomAnimation";
import "./Mission.css";

function Mission() {
  const rotate360Ref = useRef(null);
  const slideInFromLeftRef = useRef(null);

  useRotate360Animation(rotate360Ref, 2, 5);
  useSlideInFromLeftAnimation(slideInFromLeftRef, 1, 0.5);

  return (
    <ScrollAnimation animateIn="fadeIn">
      <section className="mission" data-aos="fade-up" ref={slideInFromLeftRef}>
        <h1 className="mission__header" id="about">
          Our Mission
        </h1>
        <p className="mission__description">
          Our mission is to empower influencers and brands to create impactful
          connections and collaborations, enhancing visibility and engagement
          through our intuitive platform.
        </p>

        <div className="mission__cards">
          <MissionCard
            imgSrc={M1}
            altText="Influencer Reach"
            title="Influencer Reach"
            description="Expand your brand's visibility and engagement by connecting with top influencers across various platforms."
            ref={rotate360Ref}
          />
          <MissionCard
            imgSrc={M2}
            altText="Seamless Collaboration"
            title="Seamless Collaboration"
            description="Utilize our tools to streamline communication and project management with influencers for effective campaigns."
            ref={rotate360Ref}
          />
          <MissionCard
            imgSrc={M3}
            altText="Customizable Campaigns"
            title="Customizable Campaigns"
            description="Tailor your marketing campaigns with our flexible and user-friendly interface to meet specific brand needs."
            ref={rotate360Ref}
          />
          <MissionCard
            imgSrc={M4}
            altText="Continuous Support"
            title="Continuous Support"
            description="Benefit from our dedicated support team ready to assist you at any stage of your influencer marketing journey."
            ref={rotate360Ref}
          />
        </div>
      </section>

      <section className="mission-section about" data-aos="fade-up" id="about">
        <h1 className="mission__header">About Top Notch Influencer</h1>
        <p className="mission__description">
          Our platform is designed to seamlessly connect brands with
          influencers, enhancing digital marketing strategies through effective
          and targeted influencer collaborations. Ready to use with minimal
          setup, it's tailored for immediate impact in your marketing efforts.
        </p>

        <div className="about__content">
          <div className="about__text">
            <h3 className="about__header">Platform Features</h3>
            <ul className="about__features">
              <FeatureItem text="Streamlined influencer search and discovery." />
              <FeatureItem text="Advanced analytics and performance tracking." />
              <FeatureItem text="Automated campaign management tools." />
              <FeatureItem text="Secure and reliable payment processing." />
              <FeatureItem text="Customizable reporting and insights." />
              <FeatureItem text="Dedicated support and consulting services." />
            </ul>
          </div>
          <div className="about__image">
            <img src={Ambassador} alt="Ambassador Program" />
          </div>
        </div>
      </section>

      <section className="mission-section customization" data-aos="fade-down">
        <h1 className="mission__header">Customization</h1>
        <p className="mission__description">
          Our platform offers extensive customization options to tailor your
          influencer marketing strategies effectively. Explore some of the
          features below:
        </p>

        <div className="customization__content">
          <div className="customization__text">
            <ul className="customization__features">
              <FeatureItem text="Flexible campaign templates and layouts." />
              <FeatureItem text="Personalized influencer outreach tools." />
              <FeatureItem text="Brand-specific analytics dashboards." />
              <FeatureItem text="Integration with existing marketing tools." />
              <FeatureItem text="Multi-channel campaign support." />
              <FeatureItem text="Custom branding and design options." />
            </ul>
          </div>
          <div className="customization__image" ref={rotate360Ref}>
            <img src={Customize} alt="Customization Options" />
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
}

const MissionCard = React.forwardRef(
  ({ imgSrc, altText, title, description }, ref) => (
    <div className="mission__card" ref={ref}>
      <img src={imgSrc} alt={altText} className="mission__card__image" />
      <div className="mission__card__content">
        <h3 className="mission__card__title">{title}</h3>
        <p className="mission__card__description">{description}</p>
      </div>
    </div>
  )
);

const FeatureItem = ({ text }) => (
  <li className="feature-item">
    <div className="feature-item__icon">
      <CheckCircleIcon style={{ color: "#3e7ca8" }} />
    </div>
    <div className="feature-item__text">{text}</div>
  </li>
);

export default Mission;
