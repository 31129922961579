import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Modal,
  Typography,
  TextField,
  Avatar,
  ListItemAvatar,
  Button,
  MenuItem,
  Select,
  IconButton,
  InputLabel,
  FormControl,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import ExploreIcon from "@mui/icons-material/Explore";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../SidePosts.css";
import axiosInstance from "../../../../axiosInstance";
import { publicUrl } from "../../../../axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingAnimation from "../../../../components/animations/LoadingAnimation";
import {
  RestaurantMenuOutlined,
  AddShoppingCartOutlined,
  CurrencyExchangeOutlined,
  DraftsOutlined,
  LogoutOutlined,
  ReviewsOutlined,
} from "@mui/icons-material";
import { logout } from "../../../../redux/store/userSlice";
import { useNavigate } from "react-router";
import ReviewModal from "../../../../components/modal/ReviewModal";
import LoadingComponent from "../../../../components/loading/LoadingComponent";
import "../SidePosts.css";
import { CloseIcon } from "../../../../assets/images/icons";
import Transactions from "../../../withdraw/Transactions";
import { persistStore } from "redux-persist";
import { store } from "../../../../redux/store/index"; // Import your store
const mockData = [
  {
    name: "Benny Walker",
    username: "iambennywalker",
    avatar: "https://via.placeholder.com/40",
  },
  {
    name: "Christian",
    username: "benzema2baba",
    avatar: "https://via.placeholder.com/40",
  },
  {
    name: "BellaNaija",
    username: "bellanaija",
    avatar: "https://via.placeholder.com/40",
  },
  {
    name: "Bukola Bekes",
    username: "bekesbukola",
    avatar: "https://via.placeholder.com/40",
  },
  {
    name: "Melvin Benjamin",
    username: "kingmelvin_mb",
    avatar: "https://via.placeholder.com/40",
  },
  {
    name: "Beyoncé",
    username: "beyonce",
    avatar: "https://via.placeholder.com/40",
  },
  {
    name: "Benny Hinn",
    username: "pastorbennyhinn",
    avatar: "https://via.placeholder.com/40",
  },
];

export const maskDetails = (detail, visibleLength = 2) => {
  if (detail) {
    return (
      detail.slice(0, visibleLength) + "*".repeat(detail.length - visibleLength)
    );
  }
  return "";
};

const SidePosts = () => {
  const [companyList, setCompanyList] = useState([]);
  const [postList, setPostList] = useState([]);
  const [pollList, setPollList] = useState([]);

  const [openSearch, setOpenSearch] = useState(false);
  const [openBusinessSearch, setOpenBusinessSearch] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openPoll, setOpenPoll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [businessSearchTerm, setBusinessSearchTerm] = useState("");
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // State to control the CPC modal
  const [openCpc, setOpenCpc] = useState(false);
  const [cpcPrice, setCpcPrice] = useState("");

  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedPost, setSelectedPost] = useState({});
  const [pollMessage, setPollMessage] = useState("");
  const [pollOptions, setPollOptions] = useState([]);
  const [showPostFields, setShowPostFields] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [openMore, setOpenMore] = useState(false); // State for the "More" modal
  const isMobile = useMediaQuery("(max-width: 900px)");
  const userRole = useSelector((state) => state.user.role);
  const user = useSelector((state) => state.user.user);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [loading, setLoading] = useState(false);

  const [influencers, setInfluencers] = useState([]);
  const [business, setBusiness] = useState([]);
  const [bID, setBID] = useState("");

  const menuItems = [
    { text: "Post", icon: <HomeIcon />, action: "posts" },
    userRole == "business" && {
      text: "Sponsored posts",
      icon: <HomeIcon />,
      action: "sponsored posts",
    },

    userRole !== "follower" && {
      text: "Search",
      icon: <SearchIcon />,
      action: "search",
    },

    { text: "Analytics", icon: <ExploreIcon />, action: "analytics" },

    userRole !== "follower" && {
      text: "Messages",
      icon: <MessageIcon />,
      action: "messages",
    },

    // {
    //   text: "Notifications",
    //   icon: <NotificationsIcon />,
    //   action: "notifications",
    // },

    userRole === "influencer" && {
      text: "Create Post",
      icon: <AddBoxIcon />,
      action: "create",
    },
    userRole === "influencer" && {
      text: "Create Poll",
      icon: <AddBoxIcon />,
      action: "createPoll",
    },

    userRole !== "business" && {
      text: "Withdraw",
      icon: <CurrencyExchangeOutlined />,
      action: "withdraw",
    },

    userRole !== "business" && {
      text: "Withraw History",
      icon: <CurrencyExchangeOutlined />,
      action: "withdrawHistory",
    },

    {
      text: "Transactions",
      icon: <CurrencyExchangeOutlined />,
      action: "transactions",
    },

    userRole !== "follower" && {
      text: "Proposals",
      icon: <DraftsOutlined />,
      action: "proposals",
    },

    userRole !== "follower" && {
      text: "Rejected Proposals",
      icon: <DraftsOutlined />,
      action: "rejected proposals",
    },

    userRole !== "follower" && {
      text: "Accepted Proposals",
      icon: <DraftsOutlined />,
      action: "accepted proposals",
    },

    {
      text: "Reviews",
      icon: <ReviewsOutlined />,
      action: "reviews",
    },

    userRole === "business" && {
      text: "Add Funds",
      icon: <CurrencyExchangeOutlined />,
      action: "add funds",
    },

    // userRole === "influencer" && {
    //   text: "Set CPC",
    //   icon: <CurrencyExchangeOutlined />,
    //   action: "cpc",
    // },

    userRole === "business" && {
      text: "Create Proposal",
      icon: <DraftsOutlined />,
      action: "create proposal",
    },

    { text: "Profile", icon: <AccountCircleIcon />, action: "profile" },
    { text: "Settings", icon: <MoreHorizIcon />, action: "settings" },
    { text: "Shop", icon: <AddShoppingCartOutlined />, action: "shop" },

    { text: "Dine", icon: <RestaurantMenuOutlined />, action: "dine" },
    { text: "Logout", icon: <LogoutOutlined />, action: "logout" },
  ].filter(Boolean);

  const persistor = persistStore(store);

  const handleLogout = () => {
    setLoading(true);
    dispatch(logout()); // Call the logout action

    setTimeout(() => {
      setLoading(false);
      localStorage.setItem("verified", "false");
      // Clear local storage
      localStorage.clear();

      // Optionally, purge persisted state
      persistor.purge();

      window.location.href = "/login";
    }, 2000);
  };

  const getInfluencers = async () => {
    const res = await axiosInstance.get("/business/influencers/list");
    setInfluencers(res.data);
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    setBID(event.target.value.business_id);
  };

  const handlePostChange = (event) => {
    setSelectedPost(event.target.value);
  };

  const getCompanyList = async () => {
    const res = await axiosInstance.get(
      `/influencer/hires/accepted/user/${user?.id}`
    );
    //console.log(res?.data?.businesses);
    // setCompanyList(res.data.hires);
    setBusiness(res.data.hires);
  };

  const uniqueCompanies = companyList.filter(
    (company, index, self) =>
      index ===
      self.findIndex((c) => c.business?.name === company.business?.name)
  );

  const getPostList = async () => {
    const res = await axiosInstance.get(
      `/influencer/hires/accepted/user/${user?.id}`
    );
    //console.log(res.data.hires);
    const opinionPollPosts = res.data.hires.filter(
      (hire) => hire.post_type === "Opinion Poll"
    );

    const nonOpinionPollPosts = res.data.hires.filter(
      (hire) => hire.post_type !== "Opinion Poll"
    );

    if (opinionPollPosts) {
      setCompanyList(opinionPollPosts);
    }

    if (nonOpinionPollPosts) {
      setCompanyList(nonOpinionPollPosts);
    }

    const jointPosts = [...opinionPollPosts, ...nonOpinionPollPosts];
    // console.log("joint", jointPosts);

    setPostList(nonOpinionPollPosts);
    setPollList(opinionPollPosts);
  };

  // console.log(postList);

  useEffect(() => {
    getCompanyList();
    getPostList();
    if (userRole == "business") {
      getInfluencers();
    }
  }, []);

  const handleMenuItemClick = (action, text) => {
    setActiveTab(text);
    console.log(action, text);
    switch (action) {
      case "search":
        userRole === "business"
          ? handleOpenSearch()
          : handleOpenBusinessSearch();
        break;
      case "create":
        handleOpenCreate();
        break;
      case "createPoll":
        handleOpenPoll();
        break;
      case "logout":
        handleLogout();
        break;
      case "profile":
        window.location.href = "/profile";
        break;
      case "reviews":
        window.location.href = "/reviews";
        break;
      case "withdrawHistory":
        window.location.href = "/withdraw-history";
        break;
      case "analytics":
        window.location.href = "/analytics";
        break;
      case "dine":
        window.open("https://naturemenu.net/", "_blank");
        break;
      case "transactions":
        window.location.href = "/transactions";
        break;
      case "withdraw":
        window.location.href = "/withdraw";
        break;
      case "shop":
        window.open("https://www.naturecheckout.com/", "_blank");
        break;
      case "proposals":
        window.location.href = "/proposals";
        break;
      case "accepted proposals":
        window.location.href = "/accepted-proposals";
        break;
      case "rejected proposals":
        window.location.href = "/rejected-proposals";
        break;
      case "settings":
        window.location.href = "/settings";
        break;
      case "messages":
        window.location.href = "/all-messages";
        break;
      case "posts":
        window.location.href = "/posts";
        break;

      case "sponsored posts":
        window.location.href = "/sponsored-posts";
        break;

      case "create proposal":
        window.location.href = "/hire";
        break;
      case "add funds":
        window.location.href = "/add-funds";
        break;
      default:
        // handle default action
        break;
    }
  };

  const handleOpenSearch = () => {
    setActiveTab("Search");
    setOpenSearch(true);
  };
  const handleCloseSearch = () => setOpenSearch(false);

  const handleOpenBusinessSearch = () => {
    setActiveTab("Search");
    setOpenBusinessSearch(true);
  };
  const handleCloseBusinessSearch = () => setOpenBusinessSearch(false);

  const handleOpenCreate = () => {
    setActiveTab("Create");
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
    setFile(null);
    setTitle("");
    setDescription("");
    setSelectedCompany("");
    setSelectedPost("");
    setShowPostFields(false);
  };

  const handleOpenPoll = () => {
    setActiveTab("Create Poll");
    setOpenPoll(true);
  };

  const handleClosePoll = () => {
    setOpenPoll(false);
    setPollMessage("");
    setPollOptions([]);
  };

  const handleOpenMore = () => {
    setOpenMore(true);
  };

  const handleCloseMore = () => {
    setOpenMore(false);
  };

  const handleSearch = (event) => setSearchTerm(event.target.value);
  const handleBusinessSearch = (event) =>
    setBusinessSearchTerm(event.target.value);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setShowPostFields(true);
    }
  };

  const handleAddPollOption = () => {
    setPollOptions([...pollOptions, ""]);
  };

  const handlePollOptionChange = (index, event) => {
    const options = [...pollOptions];
    options[index] = event.target.value;
    setPollOptions(options);
  };

  const handleRemovePollOption = (index) => {
    const options = [...pollOptions];
    options.splice(index, 1);
    setPollOptions(options);
  };

  const handleSubmitPost = async () => {
    setLoading(true);

    const postType = file?.type?.includes("video") ? "video" : "image";

    try {
      const formData = new FormData();
      formData.append("content", description);

      if (selectedCompany || selectedPost) {
        formData.append("business_id", selectedCompany?.business_id);
        formData.append("hire_id", selectedPost?.id);
      }

      if (postType === "image") {
        formData.append("media_path", file);
        formData.append("media_type", "image");
        formData.append("type", "image");
      } else if (postType === "video") {
        formData.append("media_path", file);
        formData.append("media_type", "video");
        formData.append("type", "video");
      }

      for (let pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      const headers = { "Content-Type": "multipart/form-data" };

      const res = await axiosInstance.post("/influencer/posts", formData, {
        headers,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          console.log(`Upload Progress: ${percent}%`);

          // Optionally, update a state variable to show progress in the UI
          setUploadProgress(percent);
        },
      });

      if (res.data) {
        toast.success("Post created successfully");
        setLoading(false);
        handleCloseCreate();
        setIsReviewModalOpen(true);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "Unable to publish post");
    }
  };

  const handleSubmitPoll = async () => {
    setLoading(true);
    try {
      const payload = {
        // content: pollMessage,
        hire_id: selectedPost?.id,
        type: "poll",

        business_id: selectedCompany?.business_id,

        question: pollMessage,
        options: pollOptions,
      };
      //console.log(payload);

      const res = await axiosInstance.post("/influencer/posts", payload);
      if (res.data) {
        toast.success("poll created successfully");
        handleClosePoll();
        // window.location.reload();
        setLoading(false);

        setIsReviewModalOpen(true);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "error publishing post");
    }
  };

  const filteredData = influencers.filter(
    (user) =>
      user?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      user?.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      user?.address?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const filteredBusinessData = uniqueCompanies?.filter(
    (user) =>
      user?.business?.name
        ?.toLowerCase()
        .includes(businessSearchTerm?.toLowerCase()) ||
      user?.business?.email
        ?.toLowerCase()
        .includes(businessSearchTerm?.toLowerCase())
  );

  const handleOpenCpc = () => {
    setOpenCpc(true);
  };

  const handleCloseCpc = () => {
    setOpenCpc(false);
  };

  const handleReviewSubmit = async (reviewContent) => {
    setIsReviewModalOpen(false);
    setLoading(true);

    try {
      const payload = {
        business_id: bID,
        rating: reviewContent.rating,
        comment: reviewContent.reviewContent,
      };

      //console.log(payload);

      const res = await axiosInstance.post(
        "/influencer/reviews/create",
        payload
      );

      //console.log(res.data?.review);

      if (res.status == 201) {
        toast.success(res.data.message);
        window.location.href = "/posts";
      }
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
      toast.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    const activeItem = menuItems.find((item) =>
      currentPath.includes(item.action)
    );
    if (activeItem) {
      setActiveTab(activeItem.text);
    }
  }, [menuItems]);

  // console.log(uniqueCompanies);

  return (
    <>
      {loading && <LoadingComponent progress={uploadProgress} />}
      {!isMobile ? (
        <Box sx={{ width: 240, p: 2, borderRight: 0.1 }}>
          <List>
            {menuItems.map((item, index) => (
              <ListItem
                button
                key={index}
                onClick={() => {
                  handleMenuItemClick(item.action, item.text);
                }}
                className="sidebar-active"
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Box>
      ) : (
        <BottomNavigation
          value={activeTab}
          onChange={(event, newValue) => {
            if (newValue === "more") {
              handleOpenMore();
            } else {
              setActiveTab(newValue);
              handleMenuItemClick(
                menuItems.find((item) => item.text === newValue)?.action || ""
              );
            }
          }}
          sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            zIndex: 99999,
            backgroundColor: "#e0e0e0",
          }}
        >
          {menuItems.slice(0, 3).map((item, index) => (
            <BottomNavigationAction
              key={index}
              label={item.text}
              icon={item.icon}
              value={item.text}
            />
          ))}
          <BottomNavigationAction
            label="More"
            icon={<MoreHorizIcon />}
            value="more"
          />
        </BottomNavigation>
      )}

      <Modal open={openSearch} onClose={handleCloseSearch}>
        <Box
          sx={{
            position: "absolute",
            top: "55%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "85%" : 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
            height: isMobile ? 400 : 500,
            overflow: "scroll",
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{ mb: 2, outline: "none", fontSize: 16 }}
          >
            Search Influencers with over 5000 followers
          </Typography>

          <IconButton
            onClick={handleCloseSearch}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "text.secondary",
              zIndex: 99999,
            }}
          >
            <CloseIcon />
          </IconButton>
          <TextField
            fullWidth
            label="Search Users"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
            sx={{ mb: 2 }}
          />
          <List>
            {filteredData.map((user, index) => (
              <a
                href={`/profile/${user?.id}`}
                style={{ color: "#000", textDecoration: "none" }}
              >
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar src={publicUrl + user.image} alt={user.name} />
                  </ListItemAvatar>
                  <div>
                    <ListItemText
                      primary={user.name}
                      secondary={maskDetails(`${user.email}`)}
                    />
                    <p style={{ fontSize: 12 }}>{`${user?.address}`}</p>
                  </div>
                </ListItem>
              </a>
            ))}
          </List>
        </Box>
      </Modal>
      <Modal open={openBusinessSearch} onClose={handleCloseBusinessSearch}>
        <Box
          sx={{
            position: "absolute",
            top: "55%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "85%" : 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
            height: isMobile ? 400 : 500,
            overflow: "scroll",
            outline: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{ mb: 1, outline: "none" }}
            >
              Search Business
            </Typography>

            <IconButton
              onClick={handleCloseBusinessSearch}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "text.secondary",
                zIndex: 99999,
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <TextField
            fullWidth
            label="Search Business"
            variant="outlined"
            value={businessSearchTerm}
            onChange={handleBusinessSearch}
            sx={{ mb: 2 }}
          />
          <List>
            {filteredBusinessData?.map((user, index) => (
              <a
                href={`/profile-business/${user?.id}`}
                style={{ color: "#000", textDecoration: "none" }}
              >
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar
                      src={publicUrl + user?.business?.image}
                      alt={user?.business?.name}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={user?.business?.name}
                    secondary={maskDetails(`${user?.business?.email}`)}
                  />
                </ListItem>
              </a>
            ))}
          </List>
        </Box>
      </Modal>

      <Modal open={openCreate} onClose={handleCloseCreate}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "85%" : 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
            height: 400,
            overflowY: "scroll",
            textAlign: "center",
            // display: "flex",
            // flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            outline: "none",
            overflowX: "hidden",
          }}
        >
          {postList?.length > 0 ? (
            <>
              <Typography
                variant="h6"
                component="h2"
                sx={{ mb: 2, outline: "none" }}
              >
                Create a New Post
              </Typography>
              <input
                accept="image/*,video/*"
                style={{ display: "none" }}
                id="upload-file"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="upload-file">
                <Button variant="contained" color="primary" component="span">
                  Upload Image/Video
                </Button>
              </label>
              <Typography>
                The media must be a file of type: jpeg, png, jpg, gif, mp4, mov,
                avi, flv.
              </Typography>
              {file && (
                <Box sx={{ mt: 2, textAlign: "center" }}>
                  {file.type.startsWith("image/") ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt="Preview"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <video controls style={{ width: "100%" }}>
                      <source
                        src={URL.createObjectURL(file)}
                        type={file.type}
                      />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </Box>
              )}

              {showPostFields && (
                <>
                  {/* <TextField
                    fullWidth
                    label="Title"
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{ mt: 2 }}
                  /> */}
                  <TextField
                    fullWidth
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    sx={{ mt: 2 }}
                  />
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Company</InputLabel>
                    <Select
                      value={selectedCompany}
                      onChange={handleCompanyChange}
                      label="Company"
                      renderValue={(selected) => selected?.business?.name} // Display the name in the Select input
                    >
                      {uniqueCompanies.map((company, index) => (
                        <MenuItem key={index} value={company}>
                          {company?.business?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel> Related post </InputLabel>
                    <Select
                      value={selectedPost}
                      onChange={handlePostChange}
                      label="Post"
                      renderValue={(selected) => selected?.title}
                    >
                      {postList.map((post, index) => (
                        <MenuItem key={index} value={post}>
                          {post?.title} <br />
                          {post?.description.toString().slice(0, 15) + "..."}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitPost}
                    sx={{ mt: 2 }}
                  >
                    Submit
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <Typography style={{ fontWeight: "bold" }}>
                No proposal found
              </Typography>
            </>
          )}
        </Box>
      </Modal>

      <Modal open={openPoll} onClose={handleClosePoll}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "85%" : 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
            height: 350,
            overflowY: "scroll",
            outline: "none",
            overflowX: "hidden",

            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {pollList?.length > 0 ? (
            <>
              <Typography
                variant="h6"
                component="h2"
                sx={{ mb: 2, outline: "none" }}
              >
                Create a New Poll
              </Typography>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Company</InputLabel>
                <Select
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                  label="Company"
                  renderValue={(selected) => selected?.business?.name} // Display the name in the Select input
                >
                  {companyList.map((company, index) => (
                    <MenuItem key={index} value={company}>
                      {company?.business?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel> Related proposal </InputLabel>
                <Select
                  value={selectedPost}
                  onChange={handlePostChange}
                  label="Post"
                  renderValue={(selected) => selected?.title}
                >
                  {pollList.map((post, index) => (
                    <MenuItem key={index} value={post}>
                      {post?.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Poll Question"
                variant="outlined"
                value={pollMessage}
                onChange={(e) => setPollMessage(e.target.value)}
                sx={{ mt: 2 }}
              />
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Poll Options</Typography>
                {pollOptions.map((option, index) => (
                  <Box
                    key={index}
                    sx={{ display: "flex", alignItems: "center", mt: 1 }}
                  >
                    <TextField
                      fullWidth
                      value={option}
                      onChange={(e) => handlePollOptionChange(index, e)}
                      variant="outlined"
                      label={`Option ${index + 1}`}
                    />
                    <IconButton
                      onClick={() => handleRemovePollOption(index)}
                      color="error"
                      sx={{ ml: 1 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  onClick={handleAddPollOption}
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  Add Option
                </Button>
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitPoll}
                sx={{ mt: 2 }}
              >
                Submit
              </Button>
            </>
          ) : (
            <Typography style={{ fontWeight: "bold" }}>
              No proposal found
            </Typography>
          )}
        </Box>
      </Modal>

      <Modal open={openMore} onClose={handleCloseMore}>
        <Box
          sx={{
            position: "relative", // Make sure Box is positioned relative for absolute children
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "80%" : 400,
            height: isMobile ? 400 : 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
            overflow: "scroll",
            outline: "none",
          }}
        >
          {/* Close Button */}
          <IconButton
            onClick={handleCloseMore}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "text.secondary",
              zIndex: 99999,
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Modal Content */}
          <List>
            {menuItems.slice(3).map((item, index) => (
              <ListItem
                button
                key={index}
                onClick={() => {
                  handleMenuItemClick(item.action);
                  handleCloseMore();
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>

      {/* cpc modal */}

      <Modal open={openCpc} onClose={handleCloseCpc}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "80%" : 400,
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6">Set CPC Price</Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1">CPC Price: ${cpcPrice}</Typography>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={cpcPrice}
              onChange={(e) => setCpcPrice(e.target.value)}
              style={{ width: "100%" }}
            />
          </Box>
          <Button
            variant="contained"
            onClick={() => {
              //console.log("CPC Price Submitted:", cpcPrice);
            }}
            fullWidth
          >
            Submit
          </Button>
        </Box>
      </Modal>

      {/* review modal */}

      <ReviewModal
        open={isReviewModalOpen}
        onClose={() => setIsReviewModalOpen(false)}
        onSubmit={handleReviewSubmit}
      />
    </>
  );
};

export default SidePosts;
