import {
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { publicUrl } from "../../../../axios";
import axiosInstance from "../../../../axiosInstance";
import Poll from "./Poll";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import SidePosts from "../side-posts/SidePosts";
import "./SingleFeed.css";
import LoadingAnimation from "../../../../components/animations/LoadingAnimation";
import LoadingComponent from "../../../../components/loading/LoadingComponent";

function SingleFeed() {
  const [post, setPost] = useState(null);
  const { id } = useParams();
  const user = useSelector((state) => state.user.user);
  const userId = user?.id;
  const userRole = useSelector((state) => state.user?.role);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const followerEndpoint = `/follower/posts/${id}`;
  const influencerEndpoint = `/influencer/posts/${id}`;
  const businessEndpoint = `/business/posts/${id}`;

  useEffect(() => {
    const getPostDetails = async () => {
      try {
        const endpoint =
          userRole === "influencer"
            ? influencerEndpoint
            : userRole === "business"
            ? businessEndpoint
            : followerEndpoint;
        const res = await axiosInstance.get(endpoint);

        setPost(res.data.singlePost);
      } catch (error) {
        // console.error("Error fetching post details", error);
      }
    };
    getPostDetails();
  }, [id]);

  const handleLike = async () => {
    const userHasLiked = post.reactions.some(
      (reaction) => reaction.follower_id === userId && reaction.is_liked === 1
    );
    const newLikeStatus = userHasLiked ? 0 : 1;

    // Optimistically update the UI
    setPost((prevPost) => ({
      ...prevPost,
      likes: userHasLiked ? prevPost.likes - 1 : prevPost.likes + 1,
      reactions: userHasLiked
        ? prevPost.reactions.map((reaction) =>
            reaction.follower_id === userId
              ? { ...reaction, is_liked: newLikeStatus }
              : reaction
          )
        : [
            ...prevPost.reactions,
            { follower_id: userId, is_liked: newLikeStatus },
          ],
    }));

    try {
      await axiosInstance.post(`/follower/posts/${post.id}/reactions`, {
        is_liked: newLikeStatus,
      });
    } catch (error) {
      console.error("Error liking post", error);
      // Revert the UI update if the request fails
      setPost((prevPost) => ({
        ...prevPost,
        likes: userHasLiked ? prevPost.likes + 1 : prevPost.likes - 1,
        reactions: prevPost.reactions.map((reaction) =>
          reaction.follower_id === userId
            ? { ...reaction, is_liked: userHasLiked ? 1 : 0 }
            : reaction
        ),
      }));
    }
  };

  const handlePollVote = async (optionIndex) => {
    const poll = post.poll;
    const option = poll.options[optionIndex];
    const originalVotes = option.votes.length;

    // Optimistically update the UI
    setPost((prevPost) => ({
      ...prevPost,
      poll: {
        ...prevPost.poll,
        options: prevPost.poll.options.map((opt, i) =>
          i === optionIndex ? { ...opt, votes: [...opt.votes, "temp"] } : opt
        ),
      },
    }));

    try {
      await axiosInstance.post(`/influencer/posts/${post.id}/vote`, {
        option_id: option.id,
      });
    } catch (error) {
      console.error("Error voting on poll", error);
      // Revert the UI update if the request fails
      setPost((prevPost) => ({
        ...prevPost,
        poll: {
          ...prevPost.poll,
          options: prevPost.poll.options.map((opt, i) =>
            i === optionIndex
              ? { ...opt, votes: Array(originalVotes).fill("vote") }
              : opt
          ),
        },
      }));
    }
  };

  if (!post) return <LoadingComponent />;

  return (
    <div style={{ display: "flex" }}>
      <SidePosts />

      <div className="single__feed_content">
        <Box
          sx={{
            maxWidth: isMobile ? "100%" : 600,
            mx: "auto",
            p: 2,
            overflowY: "scroll",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
          }}
        >
          <Box sx={{ mb: 4, borderBottom: 0.2, paddingBottom: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <a
                href={`/profile/${post?.user?.id}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                <Avatar
                  alt={post.user.name}
                  src={publicUrl + post.user.image}
                  sx={{ width: 40, height: 40, mr: 2 }}
                />
                <Typography variant="body1" fontWeight="bold">
                  {post.user.name}
                </Typography>
              </a>
            </Box>
            <Typography>{post.content}</Typography>
            {post?.media_type === "video" && (
              <Box
                component="video"
                src={publicUrl + post.media_path}
                controls
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: isMobile ? 200 : 400,
                  objectFit: "cover",
                }}
              />
            )}
            {post?.media_type === "image" && (
              <Box
                component="img"
                src={publicUrl + post.media_path}
                alt="Post"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: isMobile ? 200 : 400,
                  objectFit: "cover",
                }}
              />
            )}
            {post?.poll && (
              <Poll
                question={post.poll.question}
                id={post?.id}
                options={post.poll.options.map((option, index) => {
                  // console.log("here", option);
                  return {
                    text: option.option,
                    votes: option.votes.length,
                    id: option.id,
                    onVote: () => handlePollVote(index),
                    vote: option.votes,
                  };
                })}
              />
            )}

            {userRole !== "influencer" && (
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <IconButton onClick={handleLike}>
                  {post.reactions.some(
                    (reaction) =>
                      reaction.follower_id === userId && reaction.is_liked === 1
                  ) ? (
                    <FavoriteIcon sx={{ color: "red" }} />
                  ) : (
                    <FavoriteBorderIcon />
                  )}
                </IconButton>
                {userRole === "influencer" && (
                  <IconButton
                    onClick={() => console.log("Share post logic here")}
                  >
                    <ShareOutlinedIcon />
                  </IconButton>
                )}
              </Box>
            )}
            <Typography variant="body2" fontWeight="bold" sx={{ mt: 1 }}>
              {
                post.reactions.filter((reaction) => reaction.is_liked === 1)
                  .length
              }{" "}
              likes
            </Typography>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default SingleFeed;
