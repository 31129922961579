import React, { useEffect } from "react";
import { motion } from "framer-motion";

const container = (delay) => ({
  hidden: { x: -100, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.5, delay: delay },
  },
});

const Test = () => {
  return (
    <motion.div
      variants={container(0)}
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.5, delay: 2 }}
    >
      <h1>Hello, World!</h1>
    </motion.div>
  );
};

export default Test;
