import React, { useEffect, useState } from "react";
import { slideInFromBottom } from "../../components/animations/useAnimations";
import HeaderText from "../../components/headerText/HeaderText";
import "./Users.css";
import { motion } from "framer-motion";
import axiosInstance from "../../axiosInstance";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import { publicUrl } from "../../axios";
import LoadingComponent from "../../components/loading/LoadingComponent";

// const users = [
//   {
//     name: "natalie_quinn",
//     posts: 400,
//     followers: 6000,
//     status: "pro",
//     socialIcons: ["instagram"],
//     img: "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
//   },
//   {
//     name: "coolfashion",
//     posts: 1000,
//     followers: 500,
//     status: "pro",
//     socialIcons: ["instagram"],
//     img: "https://img.freepik.com/free-photo/3d-illustration-teenager-with-funny-face-glasses_1142-50955.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
//   },
//   {
//     name: "oceans_are_forever",
//     posts: 400,
//     followers: 4000,
//     status: "pro",
//     socialIcons: ["facebook"],
//     img: "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
//   },
//   {
//     name: "coolfashion",
//     posts: 300,
//     followers: 6000,
//     status: "trial",
//     socialIcons: ["instagram"],
//     img: "https://img.freepik.com/free-photo/3d-illustration-teenager-with-funny-face-glasses_1142-50955.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
//   },
//   {
//     name: "Vanderbloom",
//     posts: 2000,
//     followers: 4000,
//     status: "advanced",
//     socialIcons: ["instagram"],
//     img: "https://img.freepik.com/free-photo/androgynous-avatar-non-binary-queer-person_23-2151100278.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
//   },
//   {
//     name: "Vanderbloom",
//     posts: 300,
//     followers: 500,
//     status: "master",
//     socialIcons: ["instagram"],
//     img: "https://img.freepik.com/free-photo/androgynous-avatar-non-binary-queer-person_23-2151100278.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
//   },
//   {
//     name: "oceans_are_forever",
//     posts: 400,
//     followers: 20,
//     status: "trial",
//     socialIcons: ["facebook"],
//     img: "https://img.freepik.com/free-photo/portrait-beautiful-girl-yellow-coat-with-backpack_1142-55171.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
//   },
//   {
//     name: "chikah_chikah",
//     posts: 2000,
//     followers: 1000,
//     status: "starter",
//     socialIcons: ["instagram"],
//     img: "https://img.freepik.com/free-photo/portrait-beautiful-girl-yellow-coat-with-backpack_1142-55171.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
//   },
//   {
//     name: "oceans_are_forever",
//     posts: 400,
//     followers: 4000,
//     status: "pro",
//     socialIcons: ["facebook"],
//     img: "https://img.freepik.com/free-photo/3d-portrait-businessman_23-2150793879.jpg?uid=R27806234&ga=GA1.1.858013952.1717149753&semt=sph",
//   },
// ];

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const getUsers = async () => {
    try {
      const res = await axiosInstance.get("/business/influencers/list");

      //console.log(res.data);
      setUsers(res.data);
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);
  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="user-list-container">
          <HeaderText text={"Influencers"} />
          <div className="user-grid">
            {users.map((user, index) => (
              <motion.div
                key={index}
                className={`user-card ${user.status}`}
                variants={slideInFromBottom(index + 1 * 2)}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.5, delay: 2 }}
              >
                <div className="profile-image">
                  <a href="/profile-influencer">
                    <img src={publicUrl + user?.image} alt={user.name} />
                  </a>
                  {/* <div className="social-icons">
              {user.socialIcons.map((icon, index) => (
                <span key={index} className={`icon-${icon}`}></span>
              ))}
            </div> */}
                </div>
                <div className="user-info">
                  <a href="/profile-influencer">
                    <h3>{user?.name}</h3>
                    {/* <p>{user?.posts}+ posts</p> */}
                    <p className="followers">
                      {user?.follower_count} followers
                    </p>
                  </a>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Users;
