import React, { useEffect, useState } from "react";
import { slideInFromBottom } from "../../components/animations/useAnimations";
import HeaderText from "../../components/headerText/HeaderText";
import "./Users.css";
import { motion } from "framer-motion";
import axiosInstance from "../../axiosInstance";
import LoadingAnimation from "../../components/animations/LoadingAnimation";
import { publicUrl } from "../../axios";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { Avatar } from "../../assets/images/Images";
import LoadingComponent from "../../components/loading/LoadingComponent";

const Followers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const user = useSelector((state) => state.user?.user);

  const getUsers = async () => {
    try {
      const res = await axiosInstance.get(`/influencer/user/${id || user?.id}`);
      //console.log(res.data);
      setUsers(res.data?.user_profile?.followers || []);
      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [id, user?.id]);

  //console.log(users);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="user-list-container">
          <HeaderText text={"followers"} />
          <div className="user-grid">
            {users.length === 0 ? (
              <div>No followers found</div>
            ) : (
              users.map((user, index) => {
                //console.log(user?.image);
                return (
                  <motion.div
                    key={index}
                    className={`user-card ${user.status}`}
                    variants={slideInFromBottom(index + 1 * 2)}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.5, delay: 2 }}
                  >
                    <div className="profile-image">
                      <a href={`/profile-follower/${user?.id}`}>
                        <img
                          src={user?.image ? publicUrl + user?.image : Avatar}
                          alt={user.name}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = Avatar;
                          }}
                        />
                      </a>
                    </div>

                    <div className="user-info">
                      <h3>{user?.name}</h3>
                    </div>
                  </motion.div>
                );
              })
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Followers;
