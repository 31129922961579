import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import axiosInstance from "../../axiosInstance";
import Alerts from "../../components/alerts/Alerts";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { useZoomInAnimation } from "../../components/animations/useCustomAnimation";
import SidePosts from "../posts/post-comp/side-posts/SidePosts";
import "./Withdraw.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import WithdrawalList from "./WithdrawHistory";

const Withdraw = () => {
  const [bankName, setBankName] = useState("");
  const [accountTitle, setAccountTitle] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const [alerts, setAlerts] = useState(null);
  const [errors, setErrors] = useState({});
  const userRole = useSelector((state) => state.user.role);
  const influencerEndpoint = "/influencer/wallet/withdraw";
  const followerEndpoint = "/follower/wallet/withdraw";

  const navigate = useNavigate();

  const getProfile = async () => {
    try {
      const res = await axiosInstance.get(`/${userRole}/get-profile`);
      setProfile(res.data?.user_profile || {});
      setLoading(false);
    } catch (error) {
      //console.log("Error fetching profile:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const zoomInRef = useRef(null);
  useZoomInAnimation(zoomInRef, 1.5, 1);

  const validateInputs = () => {
    const newErrors = {};
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      newErrors.amount = "Amount must be a positive number.";
    }

    if (!bankName) {
      newErrors.bankName = "Bank Name is required.";
    }
    if (!accountTitle) {
      newErrors.accountTitle = "Account Title is required.";
    }

    if (!accountNumber) {
      newErrors.accountNumber = "Account Number is required.";
    }
    if (!routingNumber) {
      newErrors.routingNumber = "Routing Number is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const withdraw = async () => {
    if (!validateInputs()) {
      return; // Prevent submission if validation fails
    }

    try {
      setLoading(true);
      const payload = {
        bank_name: bankName,
        account_title: accountTitle,
        account_number: accountNumber,
        routing_number: routingNumber,
        amount: parseFloat(amount),
      };

      const res = await axiosInstance.post(
        userRole == "influencer" ? influencerEndpoint : followerEndpoint,
        payload
      );
      toast.success("Withdrawal request successful");
      setLoading(false);
      setTimeout(() => {
        navigate("/profile");
      }, 2000);
    } catch (error) {
      //console.log("Error processing withdrawal:", error);
      let message = error.message;

      const errorMessages = error.response?.data?.errors;
      if (errorMessages) {
        if (Array.isArray(errorMessages)) {
          message = errorMessages
            .map((err) => `${err.field}: ${err.message}`)
            .join(" | ");
        } else if (typeof errorMessages === "object") {
          message = Object.keys(errorMessages)
            .map((key) => `${key}: ${errorMessages[key].join(", ")}`)
            .join(" | ");
        }
      }

      setAlerts({ message, type: "error" });
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="withdraw__container">
          <SidePosts />

          <div ref={zoomInRef} className="withdraw">
            {alerts && <Alerts message={alerts.message} type={alerts.type} />}

            <div className="withdrawal-page">
              <div className="withdrawal-form">
                <h2>Withdrawal</h2>
                <div className="balance-section">
                  <h3>Balance: ${profile?.wallet_amount}</h3>
                </div>
                <div className="withdrawal__flex">
                  <div className="form-group withdraw">
                    <label htmlFor="amount">Enter Amount</label>
                    <input
                      id="amount"
                      type="number"
                      className="form-control"
                      placeholder="0.00"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    {errors.amount && (
                      <div className="error-message">{errors.amount}</div>
                    )}
                    {amount < 50 && userRole == "follower" && (
                      <div
                        className="error-message"
                        style={{ fontWeight: 400 }}
                      >
                        minimum of $50
                      </div>
                    )}

                    {amount < 100 && userRole == "influencer" && (
                      <div
                        className="error-message"
                        style={{ fontWeight: 400 }}
                      >
                        minimum of $100
                      </div>
                    )}
                  </div>
                  <div className="form-group withdraw">
                    <label htmlFor="accountTitle">Account Name</label>
                    <input
                      id="accountTitle"
                      type="text"
                      className="form-control"
                      value={accountTitle}
                      onChange={(e) => setAccountTitle(e.target.value)}
                    />
                    {errors.accountTitle && (
                      <div className="error-message">{errors.accountTitle}</div>
                    )}
                  </div>

                  <div className="form-group withdraw">
                    <label htmlFor="accountTitle">Account Number</label>
                    <input
                      id="accountTitle"
                      type="text"
                      className="form-control"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                    {errors.accountNumber && (
                      <div className="error-message">
                        {errors.accountNumber}
                      </div>
                    )}
                  </div>

                  <div className="form-group withdraw">
                    <label htmlFor="routingNumber">Routing Number</label>
                    <input
                      id="routingNumber"
                      type="text"
                      className="form-control"
                      value={routingNumber}
                      onChange={(e) => setRoutingNumber(e.target.value)}
                    />
                    {errors.routingNumber && (
                      <div className="error-message">
                        {errors.routingNumber}
                      </div>
                    )}
                  </div>
                  <div className="form-group withdraw">
                    <label htmlFor="bankName">Bank Name</label>
                    <input
                      id="bankName"
                      type="text"
                      className="form-control"
                      placeholder="Enter your bank name"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    {errors.bankName && (
                      <div className="error-message">{errors.bankName}</div>
                    )}
                  </div>
                </div>
                {userRole == "follower" && amount > 0 && (
                  <button
                    className={`withdraw-btn ${
                      amount < parseFloat(50) ||
                      amount > parseFloat(profile?.wallet_amount)
                        ? "disabled"
                        : ""
                    }`}
                    onClick={
                      amount >= parseFloat(50) &&
                      amount <= parseFloat(profile?.wallet_amount)
                        ? withdraw
                        : null
                    }
                    // disabled={
                    //   amount <= parseFloat(50) ||
                    //   amount > parseFloat(profile?.wallet_amount)
                    // }
                  >
                    {amount >= parseFloat(50) &&
                    amount <= parseFloat(profile?.wallet_amount)
                      ? "Withdraw"
                      : "Insufficient Funds"}
                  </button>
                )}

                {userRole == "influencer" && amount > 0 && (
                  <button
                    className={`withdraw-btn ${
                      amount < parseFloat(100) ||
                      amount > parseFloat(profile?.wallet_amount)
                        ? "disabled"
                        : ""
                    }`}
                    onClick={
                      amount >= parseFloat(100) &&
                      amount <= parseFloat(profile?.wallet_amount)
                        ? withdraw
                        : null
                    }
                    // disabled={
                    //   amount <= parseFloat(100) ||
                    //   amount > parseFloat(profile?.wallet_amount)
                    // }
                  >
                    {amount >= parseFloat(100) &&
                    amount <= parseFloat(profile?.wallet_amount)
                      ? "Withdraw"
                      : "Insufficient Funds"}
                  </button>
                )}
              </div>
            </div>
            {/* <WithdrawalList /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Withdraw;
