import React from "react";
import "./ScrollingText.css";

const ScrollingText = () => {
  return (
    <div className="scrolling-text-container">
      <div className="scrolling-text">
        Build your followers by copying reference code and share on social
        media, email, or other medium to invite them. Minimum of 5000 followers
        is required for profile to be visible to interested businesses to hire
        your services
      </div>
    </div>
  );
};

export default ScrollingText;
