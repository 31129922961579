import React from "react";
import ProposalAnalyticsCard from "./ProposalAnalyticsCard";

function ProposalAnalytics() {
  const proposals = [
    {
      id: 1,
      description: "Proposal 1 description",
      budget: "$1000",
      status: "pending",
      views: 150,
      likes: 30,
      companyName: "Company A",
      image:
        "https://images.unsplash.com/photo-1720607633896-b990326064f7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw4fHx8ZW58MHx8fHx8",
      type: "poll",
    },
    {
      id: 2,
      description: "Proposal 2 description",
      budget: "$2000",
      status: "completed",
      views: 250,
      likes: 50,
      companyName: "Company B",
      type: "video",
    },
    {
      id: 3,
      description: "Proposal 3 description",
      budget: "$3000",
      status: "pending",
      views: 350,
      likes: 70,
      companyName: "Company C",
      type: "image",
    },
    {
      id: 1,
      description: "Proposal 1 description",
      budget: "$1000",
      status: "pending",
      views: 150,
      likes: 30,
      companyName: "Company A",
      image:
        "https://images.unsplash.com/photo-1720607633896-b990326064f7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw4fHx8ZW58MHx8fHx8",
      type: "poll",
    },
  ];

  return (
    <div className="app__proposal-list">
      {proposals.map((proposal, index) => (
        <ProposalAnalyticsCard
          key={proposal.id}
          proposal={proposal}
          index={index}
        />
      ))}
    </div>
  );
}

export default ProposalAnalytics;
