import React from "react";

const DownloadApps = () => {
  const apps = [
    {
      name: "Nature Menu",
      link: "https://play.google.com/store/apps/details?id=com.apnadevstesting.naturemenu&hl=en",
      image:
        "https://play-lh.googleusercontent.com/oAcFmXzsduaegxvS2hRw8TytErzUt-EcAMJaKpcfR4loaA1LIsODdWTcCQWSucTWloA=w480-h960-rw",
    },
    {
      name: "Nature Checkout",
      link: "https://play.google.com/store/apps/details?id=com.doba_nckt.app&hl=en",
      image:
        "https://play-lh.googleusercontent.com/OF_Rwk43zWybnrZ7lnTOJYAru0ePliGVExPbU6FHBN26p_awfiflWVzCfmwIlJR0chE=w480-h960-rw",
    },
  ];

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Download Our Apps</h2>
      <div style={styles.appList}>
        {apps.map((app, index) => (
          <div key={index} style={styles.appItem}>
            <a
              href={app.link}
              style={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={app.image} alt={app.name} style={styles.appImage} />
              <span style={styles.appName}>{app.name}</span>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    margin: "0 auto",
    padding: "1.5rem 1rem",
    backgroundColor: "#fff",
    borderRadius: "8px",
    textAlign: "center",
  },
  title: {
    fontSize: "1.2em",
    marginBottom: "15px",
    color: "#333",
  },
  appList: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "15px",
  },
  appItem: {
    flex: "1 1 100%",
    maxWidth: "200px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    transition: "transform 0.2s",
  },
  appImage: {
    width: "100%",
    height: "150px",
    objectFit: "contain",
  },
  appName: {
    display: "block",
    padding: "8px",
    fontSize: "1em",
    color: "#3e7ca8",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  "@media (min-width: 768px)": {
    container: {
      padding: "2rem 3rem",
    },
    appItem: {
      flex: "1 1 calc(33.33% - 15px)",
      maxWidth: "180px",
    },
  },
  "@media (min-width: 1024px)": {
    container: {
      padding: "3rem 6rem",
    },
    appItem: {
      flex: "1 1 calc(25% - 15px)",
      maxWidth: "160px",
    },
  },
};

export default DownloadApps;
