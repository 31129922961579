import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div id="contact">
      <section className="contact-section" data-aos="fade-up">
        <h1 className="half-border-header">Contact us</h1>
        <p>You can get in touch any time.</p>
        <form action="#">
          <div className="form-group">
            <input
              type="text"
              id="first-name"
              name="first-name"
              placeholder="First Name"
            />
            <input
              type="text"
              id="last-name"
              name="last-name"
              placeholder="Last Name"
            />
          </div>
          <div className="form-group">
            <input type="email" id="email" name="email" placeholder="Email" />
            <input type="tel" id="phone" name="phone" placeholder="Phone" />
          </div>
          <textarea
            id="comment"
            name="comment"
            placeholder="Your Comment"
          ></textarea>
          <button
            type="submit"
            style={{
              backgroundColor: "#3e7ca8",
              width: "50%",
              alignSelf: "center",
            }}
          >
            Submit
          </button>
        </form>
      </section>
    </div>
  );
}

export default Contact;
