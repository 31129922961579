import React, { useState, useEffect } from "react";
import axios from "axios";

function ProfileWarn() {
  const [open, setOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkProfileCompletion = async () => {
      try {
        const response = await axios.get("/user/profile/status");
        const isProfileComplete = response.data.isProfileComplete;

        // If profile is incomplete, show the warning
        if (!isProfileComplete) {
          setOpen(true);
        }
      } catch (error) {
        console.error("Failed to check profile status:", error);
      }
    };

    checkProfileCompletion();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCompleteProfile = () => {
    setOpen(false);
    window.location.href = "/settings"; // Navigate to the settings page
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    open && (
      <div style={styles.overlay}>
        <div
          style={{
            ...styles.dialog,
            width: isMobile ? "90%" : "60%",
            height: isMobile ? "70%" : "50%",
          }}
        >
          <div style={styles.dialogHeader}>
            <div
              style={{
                ...styles.dialogTitle,
                fontSize: isMobile ? "16px" : "1.5em",
              }}
            >
              ⚠️ Complete Your Profile
            </div>
            <button style={styles.closeIcon} onClick={handleClose}>
              &times;
            </button>
          </div>
          <div style={styles.dialogContent}>
            <h6
              style={{
                fontSize: isMobile ? "16px" : "1.5em",
              }}
            >
              Your profile is incomplete!
            </h6>
            <p>Please complete your profile and get rewarded.</p>
          </div>
          <div style={styles.dialogActions}>
            <button
              onClick={handleCompleteProfile}
              style={styles.completeButton}
            >
              Complete Profile
            </button>
          </div>
        </div>
      </div>
    )
  );
}

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  dialog: {
    backgroundColor: "#3e7ca8",
    borderRadius: "12px",
    padding: "20px",
    color: "white",
    textAlign: "center",
    position: "relative",
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  dialogTitle: {
    fontSize: "1.5em",
  },
  closeIcon: {
    background: "none",
    border: "none",
    color: "white",
    fontSize: "1.5em",
    cursor: "pointer",
  },
  dialogContent: {
    marginBottom: "20px",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
  },
  completeButton: {
    backgroundColor: "#ff4081",
    border: "none",
    color: "white",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default ProfileWarn;
