import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../axiosInstance";
import { useSelector } from "react-redux";

const EmailVerify = () => {
  const { userId, token } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const userRole = useSelector((state) => state?.user?.role);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const expires = params.get("expires");
    const signature = params.get("signature");

    //console.log("I'm here");

    const verifyEmail = async () => {
      try {
        const response = await axiosInstance.get(
          `/${userRole}/email/verify/${userId}/${token}`,
          {
            params: {
              expires,
              signature,
            },
          }
        );

        // Store the verification status
        localStorage.setItem("verified", "true");

        // Show success toast message
        toast.success("Email verification successful!");

        // Wait for 3 seconds before redirecting to the login page
        setTimeout(() => {
          window.location.href = "/profile";
        }, 3000);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          try {
            const fallbackResponse = await axiosInstance.get(
              `/email/verify/${userId}/${token}`,
              {
                params: {
                  expires,
                  signature,
                },
              }
            );

            toast.success("Email verification successful!");

            // Store the verification status
            localStorage.setItem("verified", "true");

            // Wait for 3 seconds before redirecting to the login page
            setTimeout(() => {
              window.location.href = "/profile";
            }, 3000);
          } catch (fallbackError) {
            toast.error(
              fallbackError?.response?.data?.message ||
                "An error occurred during fallback verification."
            );
            setLoading(false);
          }
        } else {
          toast.error(
            error?.response?.data?.message ||
              "An error occurred during email verification."
          );
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [userId, token, location.search, userRole]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      {loading && <CircularProgress />}
      <ToastContainer />
    </Box>
  );
};

export default EmailVerify;
