import React from "react";
import "./Terms.css";

const terms = [
  {
    title: "PARTIES",
    content: `This Infuencer agreement (“Agreement”) is made between Nature Checkout Inc, representing Top Notch Influencer and You (Influencer) ————. 
    
    In consideration of the mutual terms, the Client hereby employs the Influencer as an independent service provider under the following terms and conditions:`,
  },

  {
    title: "TERM",
    content: `The term of this Agreement shall commence on ____________________, 20____ and will cancel on the basis of mutual consent of both parties.`,
  },

  {
    title: `SERVICES PROVIDED`,
    content: `The influencer agrees to provide the Client the following services:
    
   •Influencer will  promote a client's product or service through sponsored posts, reviews, or endorsements on their social media platforms or blogs.
   
   •Influencer will create engaging content, such as photos, videos, blog posts, or live streams, to showcase a client's products or convey their brand message.
   
   •They will engage with their followers, responding to comments, questions, and feedback related to the client's products or services.
   
   •Influencer will help increase brand awareness by exposing their followers to the client's products, services, or messaging.
   
   •Influencer will attend or promote client-sponsored events, trade shows, or launches, generating buzz and coverage.
   
   •Influencers can establish long-term partnerships with client, becoming brand ambassadors and consistently promoting products or services.
   
   •Influencer will offer genuine testimonials and reviews of the client's products or services, boosting credibility.`,
  },

  {
    title: `PAY`,
    content: `The Client agrees to pay the Influencer the following for the services mentioned in Section 3 of this Agreement:
    
    •Influencer will be paid fixed dollar amount per customer click on all package selected by advertiser. Details will be displayed on influencer dashboard.
    
    •Payment will be made to the influencer digital wallet, and they can transfer funds to their bank account once a month. 
    
    •All other fees will be paid to the influencer according to the company’s policy.`,
  },

  {
    title: `EXPENSES`,
    content: `The Influencer shall be responsible for the payment of all their own expenses during the term of this Agreement.`,
  },

  {
    title: `CONFIDENTIALITY`,
    content: `The Influencer agrees that anything that is seen or known about the client during their time, under this Agreement, shall be kept confidential after the Agreement terminates. If the influencer uses information that could be considered a trade secret or proprietary information of the Client, the Client shall be entitled to monetary and legal compensation including, but not limited to, the right to claim damages to the fullest extent of the law and attorney’s fees.`,
  },

  {
    title: `FAILURE TO PROVIDE SERVICES.`,
    contnet: `If the Influencer becomes unable to perform the services under this Agreement by reason of illness, disability or death, compensation shall cease upon the happening of the event. Such event must be verified by a licensed physician within the State.`,
  },

  {
    title: `ASSIGNMENT`,
    content: `Neither party may assign this Agreement without the express written consent of the other party.`,
  },

  {
    title: `SEVERABILITY`,
    content: `If any term, covenant, condition, or provision of this Agreement is held by a court of competent jurisdiction to be invalid, void, or unenforceable, the remainder of the provisions shall remain in full force and effect and shall in no way be affected, impaired, or invalidated.`,
  },

  {
    title: `ENTIRE AGREEMENT.`,
    content: `This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all other prior agreements and understandings, both written and oral, between the parties with respect to the subject matter hereof.`,
  },

  {
    title: `GOVERNING LAW.`,
    content: `This Agreement shall be construed in accordance with and governed by the laws of the State of California. `,
  },
];

const Contracts = () => {
  return (
    <div className="terms">
      <div className="terms__paper">
        <h1 className="terms__title">INFLUENCER AGREEMENT</h1>

        {terms.map((term, index) => (
          <div key={index} className="terms__section">
            <h2 className="terms__section-title">
              {index + 1}. {term.title}
            </h2>
            <p className="terms__section-content">{term.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Contracts;
