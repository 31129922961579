import React from "react";
import "./Contact.css";

function ContactUs() {
  return (
    <section className="contact-us">
      <div className="contact-us__header">
        <h1>Contact Us</h1>
        <p>
          We'd love to hear from you! Fill out the form below to get in touch
          with us.
        </p>
      </div>
      <div className="contact-us__content">
        <div className="contact-us__info">
          <h2>Get in Touch</h2>
          <p>Feel free to reach out to us via the contact form.</p>
          <ul>
            {/* <li>
              <strong>Help Desk:</strong> https://helpdesk.naturecheckout.com/
            </li> */}
            {/* <li>
              <strong>Phone:</strong> (123) 456-7890
            </li>
            <li>
              <strong>Address:</strong> 123 Main Street, Anytown, USA
            </li> */}
          </ul>
        </div>
        <div className="contact-us__form">
          <form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows="5"
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-button">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
