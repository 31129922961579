import React from "react";
import {
  MarkChatUnreadIcon,
  SupportAgentIcon,
} from "../../assets/images/icons";
import "./Support.css";
function Support() {
  return (
    <div>
      <section class="support-section" data-aos="fade-up">
        <div class="support-container">
          <div class="support-item">
            <div class="support-icon">
              <SupportAgentIcon style={{ fontSize: 50 }} />
            </div>
            <h3>Get Support</h3>
            <p>
              Need Help? Don't worry. Please visit our support website. Our
              dedicated team will help you.
            </p>
            <p class="support-email">
              Support:{" "}
              <a href="https://helpdesk.naturecheckout.com/">
                visit our support
              </a>
            </p>
          </div>
          <div class="support-item">
            <div class="support-icon">
              <MarkChatUnreadIcon style={{ fontSize: 50 }} />
            </div>
            <h3>Pre-Sale Questions</h3>
            <p>
              Please feel free to ask any questions before making the purchase.
            </p>
            <p class="support-email">
              Ask:{" "}
              <a href="https://helpdesk.naturecheckout.com/">
                support@helpdesk
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Support;
