import React from "react";
import { slideInFromRight } from "../animations/useAnimations";
import { motion } from "framer-motion";

function HeaderText({ text }) {
  const headerStyle = {
    padding: "10px",
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "bold",
    borderRadius: "5px",
    margin: "10px 0",
    color: "#000",
    textTransform: "uppercase",
  };

  return (
    <motion.div
      style={headerStyle}
      variants={slideInFromRight(0.5)}
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.5, delay: 2 }}
    >
      {text}
    </motion.div>
  );
}

export default HeaderText;
